import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { eUDMReqType, eActionEvent, eReportType, eRider, eReport, configs } from '../../configs';
import { trackUserActions, openPDFNewTab, isValidDOB, isValidName } from '../../utils';
import MWIButton from '../../../core/components/mwi-button';
import UDMGenerator from '../../../core/udm-mapper/udmGenerator';
import * as AppActions from '../../../core/actions';
import * as ScenarioActions from '../../../custom-mode/scenario-tab-navs/actions';
import * as ReportActions from '../../../report/actions';
import ReactModal from 'react-modal';
import _ from 'lodash';
import ViewReportModal from './view-report-modal';
import { getDdmIllustration, requestValidation, saveDdmIllustration } from '../../udm-mapper/sideEffect';
import { injectIntl } from 'react-intl';
import './view-report.css';
import MWIDropdown from '../mwi-dropdown';
import { dropDownOptions } from '../../dropdown-config';

export class ViewReportButton extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    styleClass: PropTypes.string,
    // in the case of standalone report it has the type of standalone report
    reportType: PropTypes.string,
    isDirectDownload: PropTypes.bool,
    reportLink: PropTypes.string,

    app: PropTypes.object,
    intl: PropTypes.object,
    udmResponse: PropTypes.object,
    report: PropTypes.object,
    clients: PropTypes.object,
    riders: PropTypes.object,
    termRiders: PropTypes.object,
    withdrawal: PropTypes.object,
    coverageTabNavs: PropTypes.object,
    vitalityStatus: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    disabled: PropTypes.bool,
    warningPopUp: PropTypes.bool,
    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    reportActions: PropTypes.object,
    srOnlyLabel: PropTypes.string,
    policyData: PropTypes.object,
    activeScenarioTabId: PropTypes.string,
    deposits: PropTypes.object,
    onChange: PropTypes.func,
    history: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    displayReportDropdown: PropTypes.bool,
    isSpreadsheetView: PropTypes.bool,
    startNewApplication: PropTypes.bool,
  };

  static defaultProps = {
    isDirectDownload: false,
    disabled: false,
    srOnlyLabel: undefined,
  };

  constructor(props) {
    super(props);

    this.viewReport = this.viewReport.bind(this);
    this.onClick = this.onClick.bind(this);
    this.closeReportModal = this.closeReportModal.bind(this);
    this.openReport = this.openReport.bind(this);
    this.openReportDesign = this.openReportDesign.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      showReportModal: false,
      showDropDownState: this.props.displayReportDropdown,
    };
  }

  // Class properties
  ddmGuid = '';

  dispatchAction(field, value) {
    this.props.appActions.dispatchMWIOnChange({
      target: 'report',
      // scenarioTabId: this.props.activeScenarioTabId,
      // coverageTabId: this.props.activeCoverageTabId,
      field,
      value,
    });
  }

  onClick() {
    const reportType = this.props.reportType;
    const timeOutDuration = 100;
    let allowStartNewApplication = true;

    if (this.props.isDirectDownload && this.isTDWSelected()) {
      this.dispatchAction(eReport.rp, true);
    }

    this.dispatchAction('reportType', reportType || eReportType.base);

    if (this.props.startNewApplication) {
      allowStartNewApplication = this.validateDOBandName();
    }

    if (allowStartNewApplication) {
      setTimeout(() => this.viewReport(reportType), timeOutDuration);
    }
  }

  validateDOBandName() {
    const validDOB = isValidDOB(this.props.clients) && isValidDOB(this.props.riders.CPR);
    const validName = isValidName(this.props.clients);

    if (!validDOB || !validName) {
      const errMessage = !validDOB ? 'report.dialog.error.dob' : 'report.dialog.error.name';
      this.props.appActions.dispatchMWIOnChange({
        target: 'app',
        field: 'errorMessage',
        value: this.props.intl.formatMessage({ id: errMessage }),
      });

      //work around to hide error panel since it is not system error.
      this.props.appActions.dispatchMWIOnChange({
        target: 'app',
        field: 'errorHappened',
        value: false,
      });
      this.props.appActions.dispatchMWIOnChange({
        target: 'app',
        field: 'warningPopUp',
        value: 'true',
      });
      this.setState({ showReportModal: false });
    }
    return validDOB && validName;
  }

  openReportDesign() {
    this.props.history.push(configs.routes.designReport);
  }

  openReport() {
    if (this.props.startNewApplication) {
      // work around to open start new application
      // the proper solution is here CDTSLMM-116
      this.openStartNewApplication();
    } else {
      this.openPDFflow();
    }
    this.closeReportModal();
  }

  openStartNewApplication() {
    const ddaParam = '1';
    if (!this.ddmGuid) {
      this.genericError();
      return;
    }
    const url = `${configs.routes.ddaImportIllustration}/?dda=${ddaParam}&caseId=${this.ddmGuid}`;
    window.open(url, '_blank');
  }

  openPDFflow() {
    const lowerBound = 1000,
      upperBound = 9999;
    openPDFNewTab(this.state.report_data, `MLI${this.randomNumberFromRange(lowerBound, upperBound)}.pdf`);
  }

  genericError() {
    this.props.appActions.dispatchMWIOnChange({
      target: 'app',
      field: 'errorMessage',
      value: 'common.system.error',
    });
    this.setState({ showReportModal: false });
    this.setState({ report_data: null });
  }

  getReportTypeURL(reportType) {
    switch (reportType) {
      case eReportType.uw: {
        return configs.endPoints.underwritingReport;
      }
      case eReportType.comm: {
        return configs.endPoints.commissionsReport;
      }
      case eReportType.concept: {
        return configs.endPoints.conceptReport;
      }
      default: {
        return configs.endPoints.baseReport;
      }
    }
  }
  getReportResponse(response) {
    const data = response.illustration && response.illustration.report && response.illustration.report.data;
    const warningCheck =
      response &&
      response.validations &&
      response.validations.validation &&
      response.validations.validation.filter((e) => e.id === 'MaxWithdrawal').length > 0;
    if (!response.isError && data) {
      // show report modal
      if (!warningCheck) {
        this.setState({ showReportModal: true, report_data: data });
      } else {
        this.props.sceActions.loadedUDMResponse({
          response,
          scenarioTabId: this.props.activeScenarioTabId,
        });
        this.setState({ showReportModal: false, report_data: data });
        this.props.appActions.dispatchMWIOnChange({ target: 'app', field: 'isRequesting', value: false });
      }
    } else {
      // an error occurred, there's no data
      this.setState({ showReportModal: false });
      this.props.appActions.dispatchMWIOnChange({ target: 'app', field: 'isRequesting', value: false });
      this.props.sceActions.loadedUDMResponse({
        response,
        scenarioTabId: this.props.activeScenarioTabId,
      });
    }
  }

  viewReport(trackLabel = 'View Report') {
    //intiliztion of ddmGuid
    this.ddmGuid = '';
    const udmGenerator = new UDMGenerator();
    const reqType = this.props.reportType === eReportType.cc ? eUDMReqType.coverageCompare : eUDMReqType.report;
    const udm = udmGenerator.setProps({ ...this.props, reqType }).getUDM();
    const reqUrl = this.getReportTypeURL(this.props.reportType);
    this.props.appActions.dispatchMWIOnChange({ target: 'app', field: 'isRequesting', value: true });
    requestValidation(udm, reqUrl)
      .then((response) => {
        this.props.appActions.dispatchMWIOnChange({ target: 'app', field: 'isRequesting', value: false });
        this.getReportResponse(response);
        return response.illustration.illustrationId;
      })
      .then((ddmIllustrationId) => {
        if (!this.props.app.inforcePolicy) {
          return getDdmIllustration(ddmIllustrationId);
        }
        return false;
      }) // Retrieve RDM from calc-redis
      .then((ddmIllustration) => this.prepareDdmIlustrationSave(ddmIllustration))
      .then((ddmObj) => {
        this.ddmGuid = ddmObj?.items[0]?.guid;
        return this.ddmGuid;
      })
      .catch((errResponse) => {
        // catch an error
        this.props.appActions.dispatchMWIOnChange({ target: 'app', field: 'isRequesting', value: false });
        this.props.appActions.dispatchMWIOnChange({
          target: 'app',
          field: 'errorMessage',
          value: errResponse.requestErrorMessage,
        });
      });

    if (this.props.startNewApplication) {
      // skip, for SQ
    } else {
      trackUserActions(eActionEvent.download, 'PDF', trackLabel);
    }
  }

  prepareDdmIlustrationSave = (ddmIllustration) => {
    if (ddmIllustration) {
      const ddmIllus = ddmIllustration.value ? ddmIllustration.value : ddmIllustration.Value;
      const body = { model: ddmIllus };
      if (!this.props.app.inforcePolicy) {
        return saveDdmIllustration(body); // Save RDM to persistence
      }
    }
    return false;
  };

  randomNumberFromRange = (min, max) => {
    const byteArray = new Uint16Array(1);
    window.crypto.getRandomValues(byteArray);

    const range = max - min + 1;
    const max_range = max;
    if (byteArray[0] >= Math.floor(max_range / range) * range) {
      return this.randomNumberFromRange(min, max);
    }
    return min + (byteArray[0] % range);
  };

  isTDWSelected() {
    const tdw = this.props.riders[eRider.tdw];
    return tdw.isSelected && !!_.find(this.props.clients.allClients, (clientId) => tdw[clientId].isSelected);
  }
  closeReportModal() {
    this.setState({ showReportModal: false });
    this.setState({ report_data: null });
    // pass in true to force a full calc
    if (!this.props.isSpreadsheetView) {
      this.props.sceActions.performManualRefresh(true);
      this.props.sceActions.requestUDMResponse();
    }
  }

  onChange(value) {
    if (value === this.props.intl.formatMessage({ id: 'common.illustration' })) {
      this.onClick();
    } else {
      if (value === this.props.intl.formatMessage({ id: 'common.customize' })) {
        this.openReportDesign();
      }
    }
  }

  render() {
    const { intl, app, warningPopUp } = this.props;
    const { viewCustomizeIllustrationsOptions } = dropDownOptions(intl);
    const displayDropDownButton = this.state.showDropDownState ? (
      <MWIDropdown
        label=""
        name="viewReportDropDown"
        placeholder={intl.formatMessage({ id: 'common.reports' })}
        options={viewCustomizeIllustrationsOptions}
        onChange={(e) => this.onChange(e.target.value)}
        value={intl.formatMessage({ id: 'common.reports' })}
        intl={intl}
        grid={3}
        role="display-dropdown"
      />
    ) : (
      <MWIButton
        label={this.props.label}
        onClick={this.onClick}
        styleClass={this.props.styleClass}
        disabled={this.props.disabled}
        srOnlyLabel={this.props.srOnlyLabel}
        id="mwi-btn-viewReportDropDown"
      />
    );

    return (
      <div className="view-report-btn">
        <React.Fragment>
          {displayDropDownButton}
          {warningPopUp === false && this.state.report_data && this.setState({ showReportModal: true })}
          <ReactModal
            className={`view-report-modal caseSave-wrapper ${app.locale} `}
            contentLabel={`${intl.formatMessage({ id: 'common.viewreport' })}`}
            isOpen={this.state.showReportModal}
            shouldCloseOnOverlayClick={false}
            shouldFocusAfterRender
            shouldReturnFocusAfterClose
            onRequestClose={this.closeReportModal}
            role="dialog"
          >
            <ViewReportModal
              closeModal={this.closeReportModal}
              openReport={this.openReport}
              title={this.props.startNewApplication ? 'report.dialog.startNewAppTitle' : 'common.viewreport'}
              msg={this.props.startNewApplication ? 'report.dialog.startNewAppMsg' : 'report.viewreportmessage'}
            />
          </ReactModal>
        </React.Fragment>
      </div>
    );
  }
}

// Don't remove udmResponse even though it isn't used explicitly.
// It is required to get updated UDM so that it passes illustrationId to document service
export const mapStateToProps = ({
  app,
  udmResponse,
  scenarioTabNavs,
  coverageTabNavs,
  conceptTabNavs,
  deposits,
  clients,
  riders,
  termRiders,
  termOtherRiders,
  spousalRiders,
  vitalityStatus,
  withdrawals,
  report,
  advisorProfile,
}) => {
  const curScenario = scenarioTabNavs.activeTabId;

  return {
    app,
    udmResponse,
    coverageTabNavs,
    advisorProfile,
    warningPopUp: app.warningPopUp,
    coverages: coverageTabNavs[curScenario],
    concepts: conceptTabNavs[curScenario],
    deposits: deposits[curScenario],
    report: report[scenarioTabNavs.activeTabId],
    policyData: scenarioTabNavs.topBars[curScenario],
    clients: clients[curScenario],
    riders: riders[curScenario],
    termRiders: termRiders[curScenario],
    termOtherRiders: termOtherRiders[curScenario],
    spousalRiders: spousalRiders[curScenario],
    vitalityStatus: vitalityStatus[curScenario],
    withdrawal: withdrawals[curScenario],
    activeScenarioTabId: curScenario,
    isSpreadsheetView: scenarioTabNavs.isSpreadsheetView,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
  reportActions: bindActionCreators(ReportActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ViewReportButton));
