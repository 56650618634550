import {
  apiHost,
  conceptsConfig,
  eActionEvent,
  eBusinessType,
  eCoverageOption,
  eCostOfInsuranceType,
  eCoverageType,
  eDefault,
  eDurationType,
  eField,
  eLocale,
  eAKSRegion,
  ePrefix,
  eProduct,
  eInforceErrorTypes,
  routesHeader,
  eVitalityStatus,
  vitalityStatusSimulatorEnUrl,
  vitalityStatusSimulatorFrUrl,
  ePremiumDuration,
  ePerformaxDividendOption,
  ePremiumFrequency,
  eInforceErrorStatuses,
  eStatuses,
} from './configs';
import { NUMBERS, SPREADSHEET } from './constants';
import saveAs from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import { Workbook } from 'exceljs';

export const makeActionCreator =
  (type, data = true) =>
  (payload) =>
    data ? { type, payload } : { type };

export const coverageIndexHelper = (index) => `${ePrefix.coverage}.${index}`;
export const scenarioIndexHelper = (index) => `${ePrefix.scenario}.${index}`;
export const clientIndexHelper = (index) => `${ePrefix.client}.${index}`;

export const getFormattedValue = (locale, value, decimal, percentage = false) => {
  const minimumDecimalPlaces = 2;
  decimal = decimal === undefined ? minimumDecimalPlaces : decimal;
  const divisorForPercentageFormat = 100;
  return new Intl.NumberFormat(locale, {
    style: percentage ? 'percent' : 'currency',
    currency: 'CAD',
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  })
    .format(percentage ? value / divisorForPercentageFormat : value)
    .replace('CA', '');
};

export const getAdvancedAge = (dob, ed) => {
  const effectiveDate = moment(ed, 'DD/MM/YYYY');
  const birthDate = moment(dob, 'DD/MM/YYYY');

  const ageFromDob = Math.abs(birthDate.diff(effectiveDate, 'years'));
  const monthsInYear = 12;
  const additionalMonthsInYear = 6;
  const ageIncrement =
    moment(birthDate).add(ageFromDob * monthsInYear + additionalMonthsInYear, 'M') < effectiveDate ? 1 : 0;

  return ageFromDob + ageIncrement;
};

export const getAge = (client1, client2, coverageType) => {
  if (coverageType === eCoverageType.jointLastPayLastDeath) {
    return Math.min(client1, client2);
  }
  return client1;
};

export const getLoanToValueMax = (durationType, deathAtAge, age) => {
  if (durationType === eDurationType.year) {
    return deathAtAge - age;
  }
  return deathAtAge - 1;
};

const formatSpreadsheetData = (spreadSheetData, sheet) => {
  spreadSheetData.colMetaDatas.forEach((colMetaData, index) => {
    for (let rowNumber = NUMBERS.TWO; rowNumber <= sheet.rowCount; rowNumber++) {
      // highlight every 5th year (not 5th row)
      if (rowNumber % NUMBERS.FIVE === 0 && rowNumber < sheet.rowCount) {
        // add +1 to rowNumber because excluding the header, we highlight every 5th year,
        // so that would be in the row 6, 11, 16, etc.
        const cellToFormat = sheet.getCell(rowNumber + NUMBERS.ONE, index + NUMBERS.ONE); // add +1 because array index starts at 0 whereas column index at 1
        cellToFormat.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: SPREADSHEET.COLOR.FIFTH_ROW_HIGHLIGHT },
        };
      }

      if (!colMetaData.formatString) {
        continue; // no need to format
      }

      const currentCell = sheet.getCell(rowNumber, index + NUMBERS.ONE); // add +1 because array index starts at 0 whereas column index at 1
      if (colMetaData.percentage) {
        // set cell value if percentage
        currentCell.value = currentCell.value / NUMBERS.ONE_HUNDRED;
      }

      currentCell.numFmt = colMetaData.formatString;
    }
  });
};

export const initExcelJsWorkbook = (workbook) => {
  if (workbook === undefined || workbook === null || !workbook) {
    workbook = new Workbook();
  }
  return workbook;
};

export const exportExcel = (spreadSheetData, workbook) => {
  workbook = initExcelJsWorkbook(workbook);

  const sheet = workbook.addWorksheet(spreadSheetData.language === eLocale.en ? 'Sheet1' : 'Feuil1');

  const columnHeaders = [];
  spreadSheetData.headers.forEach((header) => {
    let maxWidth = SPREADSHEET.COL_WIDTH.THIRTEEN;
    // the extra font size adjustment is only for the first 2 columns
    if (header === 'Year' || header === 'Age' || header === 'An' || header === 'Âge') {
      maxWidth = SPREADSHEET.COL_WIDTH.SIX;
    }

    columnHeaders.push({
      header: header,
      width: maxWidth,
    });
  });

  // create header row and apply styles
  sheet.columns = columnHeaders;
  const headerRow = sheet.getRow(NUMBERS.ONE);
  headerRow.font = {
    name: 'Calibri',
    size: SPREADSHEET.FONT_SIZE.HEADER,
    bold: true,
    color: { argb: SPREADSHEET.COLOR.HEADER_COLOR },
  };
  headerRow.alignment = { horizontal: 'left' };
  headerRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: SPREADSHEET.COLOR.HEADER_FG_COLOR } };

  // populate sheet data
  spreadSheetData.records.forEach((row) => sheet.addRow(row));

  // format data (currency, percentage, etc.)
  formatSpreadsheetData(spreadSheetData, sheet);

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, spreadSheetData.fileName);
  });

  trackUserActions(eActionEvent.download, 'CVS', 'export to spreadsheet', spreadSheetData.fileName);
};

export const getVitalityStatusSimulatorUrl = (locale) =>
  locale === eLocale.fr ? vitalityStatusSimulatorFrUrl : vitalityStatusSimulatorEnUrl;

export const openPDF = (pdf, fileName = 'report.pdf') => {
  const appPdfType = 'application/pdf';
  const downloadURLTimeout = 100;
  if (window.navigator.msSaveOrOpenBlob) {
    const _pdf = atob(pdf);
    const charCodeArr = [];

    for (let i = 0; i < _pdf.length; i++) {
      charCodeArr.push(_pdf.charCodeAt(i));
    }

    const blob = new Blob([new Uint8Array(charCodeArr)], { type: appPdfType });
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else if (isIOS()) {
    // Logic for Ios devices
    const _pdf = atob(pdf);
    const charCodeArr = [];

    for (let i = 0; i < _pdf.length; i++) {
      charCodeArr.push(_pdf.charCodeAt(i));
    }

    const blob = new Blob([new Uint8Array(charCodeArr)], { type: appPdfType });
    saveAs(blob);
  } else {
    const downloadLink = document.createElement('a');
    const downloadURL = `data:application/pdf;base64,${pdf}`;

    downloadLink.style.display = 'none';
    downloadLink.href = downloadURL;
    downloadLink.setAttribute('download', fileName);
    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof downloadLink.download === 'undefined') {
      downloadLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(downloadLink);
    // don't know why it should be inside setTimeout
    setTimeout(() => downloadLink.click());
    document.body.removeChild(downloadLink);

    // For Firefox, need to delay
    setTimeout(() => URL.revokeObjectURL(downloadURL), downloadURLTimeout);
  }

  trackUserActions(eActionEvent.download, 'PDF', 'view report', fileName);
};

export const openPDFNewTab = (pdf, fileName = 'report.pdf') => {
  // first we save the pdf file then open in a new tab
  const _pdf = atob(pdf);
  const charCodeArr = [];

  for (let i = 0; i < _pdf.length; i++) {
    charCodeArr.push(_pdf.charCodeAt(i));
  }

  const blob = new Blob([new Uint8Array(charCodeArr)], { type: 'application/pdf' });
  const downloadURL = URL.createObjectURL(blob);
  saveAs(blob, fileName);
  // now we open pdf in new tab openPdf would be deprecated after report service is moved to PAR report service
  if (isChrome()) {
    // open in Iframe for Google Chrome
    const pdfWindow = window.open('');
    pdfWindow.document.write(`<iframe width='100%' name='${fileName}' height='100%' src='${downloadURL}'></iframe>`);
  } else {
    if (!isIE() && !isEdge() && !isIOS()) {
      //for every other browser other than ios and IE or edge open in new tab
      window.open(downloadURL);
    }
  }

  trackUserActions(eActionEvent.download, 'PDF', 'view report', fileName);
};

export const trackPageView = (pageId, lang, productInfo = {}, user = {}) => {
  if (isDevFeatureFlagSet()) {
    return;
  }
  window.CWC_MLCA.dataLayer = { actions: [] };

  const pageInfo = {
    pageId,
    type: 'vpv',
    language: lang,
  };

  window.CWC_MLCA.dataLayer.user = user;
  window.CWC_MLCA.dataLayer.pageInfo = pageInfo;
  window.CWC_MLCA.dataLayer.productInfo = productInfo;
};

export const trackUserActions = (category, action, label = '', value = '', productInfo = null) => {
  if (isDevFeatureFlagSet()) {
    return;
  }
  window.CWC_MLCA.dataLayer.actions = [];
  window.CWC_MLCA.dataLayer.actions.push({
    category,
    action,
    label,
    value,
  });
  productInfo && (window.CWC_MLCA.dataLayer.productInfo = productInfo);
};

export const trackInforceErrors = (type, errorName, productInfo = null) => {
  if (isDevFeatureFlagSet()) {
    return;
  }
  window.CWC_MLCA.dataLayer.actions = [];
  window.CWC_MLCA.dataLayer.actions.push({
    event: 'Error',
    Error: {
      Type: type,
      ErrorName: errorName,
      time: Date.now(),
    },
  });
  productInfo && (window.CWC_MLCA.dataLayer.productInfo = productInfo);
};

export const trackActiveTab = (scenarioid, selectedTab, productInfo) => {
  if (isDevFeatureFlagSet()) {
    return;
  }
  window.CWC_MLCA.dataLayer.actions = [];

  const tabInfo = {
    scenarioTab: scenarioid,
    productSubTab: selectedTab,
  };
  productInfo && (window.CWC_MLCA.dataLayer.productInfo = productInfo);
  window.CWC_MLCA.dataLayer.tabInfo = tabInfo;
};

export const trackPrinting = () => {
  if (isDevFeatureFlagSet()) {
    return;
  }
  const { pageInfo, productInfo, user } = window.CWC_MLCA.dataLayer;
  window.CWC_MLCA.dataLayer = { event: 'print', actions: [] };

  window.CWC_MLCA.dataLayer.actions.push({
    name: 'print page',
    category: 'print',
    label: 'term renewal calculator',
    detail: 'family term renewal calculator: term-10',
  });

  window.CWC_MLCA.dataLayer.user = user;
  window.CWC_MLCA.dataLayer.pageInfo = pageInfo;
  window.CWC_MLCA.dataLayer.productInfo = productInfo;
};

export const screenHieght = +window.screen.availHeight;

export const getFullName = (client) => {
  let name;
  const firstName = client.firstName && client.firstName.trim();
  const lastName = client.lastName && client.lastName.trim();
  if (firstName && lastName) {
    name = `${firstName} ${lastName}`;
  } else if (firstName) {
    name = `${firstName}`;
  } else {
    if (lastName) {
      name = `${lastName}`;
    }
  }
  return name;
};

const assignCustomizer = (objValue, srcValue) => (_.isUndefined(objValue) ? srcValue : objValue);

export const assignDef = _.partialRight(_.assignInWith, assignCustomizer);

export const getToYear = (coverageOption, clientAge) => {
  let toYear;

  const maximumYearT65 = 65;
  const maximumYearTLife = 100;
  const baseToYear = 20;
  if (coverageOption === eCoverageOption.t65) {
    toYear = maximumYearT65 - +clientAge;
  } else if (coverageOption === eCoverageOption.tlife) {
    toYear = maximumYearTLife - +clientAge;
  } else {
    toYear = baseToYear;
  }

  return toYear;
};

export const getTermRiderCoverageOption = (coverage) => {
  switch (coverage.costOfInsurance.type) {
    case eCostOfInsuranceType.r10:
      return eCoverageOption.t10;
    case eCostOfInsuranceType.r05:
      return eCoverageOption.t05;
    case eCostOfInsuranceType.level65:
      return eCoverageOption.t65;
    default:
      return '';
  }
};

export const getTermRiderDuration = (age) => {
  const maxTermRiderDuration = 80;
  return maxTermRiderDuration - age;
};

export const getPerformaxTermRiderDuration = (age, coverageOption) => {
  const maxTermRiderDuration = 75;
  const maxTermRiderDurationT65 = 65;

  if (coverageOption === eCoverageOption.t65) {
    return maxTermRiderDurationT65 - age;
  }

  return maxTermRiderDuration - age;
};

export const isDevFeatureFlagSet = () => apiHost === eAKSRegion.dev || apiHost === '';
export const isFeatureFlagSet = () => apiHost !== eAKSRegion.dev && apiHost !== eAKSRegion.sit;
export const isJuvenile = (age) => {
  const juvenileAge = 15;
  return age >= 0 && age <= juvenileAge;
};
export const isJoint = (product, coverageType) => {
  if (product === eProduct.PAR || product === eProduct.MUL) {
    return coverageType === eCoverageType.jointLastPayLastDeath;
  } else if ([eProduct.PG, eProduct.Performax].includes(product)) {
    return (
      coverageType === eCoverageType.jointLastPayLastDeath ||
      coverageType === eCoverageType.jointLastPayFirstDeath ||
      coverageType === eCoverageType.jointFirst
    );
  } else if ([eProduct.BT, eProduct.FT].includes(product)) {
    return (
      coverageType === eCoverageType.jointLastPayLastDeath ||
      coverageType === eCoverageType.jointLastPayFirstDeath ||
      coverageType === eCoverageType.jointFirst ||
      coverageType === eCoverageType.jointLast
    );
  } else {
    return coverageType === eCoverageType.jointFirst || coverageType === eCoverageType.jointLast;
  }
};

export const screenValidator = (product) => {
  return {
    clientAvailable: true,
    coverageAvailable: true,
    effDateAvailable: true,
    healthstyleAvailable: product !== eProduct.PAR && product !== eProduct.MUL,
    paymentAvailable: true,
    ratingsAvailable: true,
    ridersAvailable: true,
    termRidersAvailable: product === eProduct.PAR || product !== eProduct.MUL,
    solveAvailable: true,
    summaryAvailable: true,
  };
};

export const headerLinkValidator = (page, linkId) => _.includes(routesHeader[page], linkId);

export const conceptValidator = (conceptSelection, component) =>
  _.includes(conceptsConfig[conceptSelection], component);

export const isIOS = () => !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

export const isChrome = () => !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

export const isEdge = () => /Edge/.test(navigator.userAgent);

export const isIE = () => document.documentMode;

export const handleFocus = (event) => event.target.select();

export const isRated = (permRatingAmount, permRatingPercent, tempRatingAmount) => {
  const totalPerRatingPercentage = 100;
  return permRatingAmount > 0 || permRatingPercent > totalPerRatingPercentage || tempRatingAmount > 0;
};

export const isInforce = (product) => product === eProduct.FTRC;

export const getBusinessType = (product) => (isInforce(product) ? eBusinessType.IF : eBusinessType.NB);

export const calculateMWINumberFieldValue = (value, min, max, getmax = false) => {
  if (!value) {
    return getmax ? +max : +min;
  } else if (+value > +max) {
    return +max;
  } else if (+value < +min) {
    return +min;
  } else {
    return +value;
  }
};

export const formatNumberValue = (value, locale) => {
  // return value if its a number
  if (!isNaN(value)) {
    return value;
  }
  // if it is a string remove prefix or suffix and return Number
  try {
    value = value.match(/[{0-9}.,]/g).join('');
  } catch (error) {
    return undefined;
  }
  const replaceChar = locale === eLocale.en ? '' : '.';
  value = value.replace(/,/g, replaceChar);
  return Number(value);
};

export const deathAtAgeMin = (isRatedLifeExpectancy, lifeExpectancy) => {
  const lifeExpectancyReduction = 5;
  if (isRatedLifeExpectancy) {
    return lifeExpectancy - lifeExpectancyReduction;
  }
  return lifeExpectancy;
};

export const additionalYearsMin = (deathAtAge, upperLimit) => {
  if (eDefault.lifeExpectancyAssumption.additionalYears <= upperLimit - deathAtAge) {
    return eDefault.lifeExpectancyAssumption.additionalYears;
  } else {
    return upperLimit - deathAtAge;
  }
};

export const deathAtAgeMax = (coverageType, equivalentAge, minimumAge) => {
  const maximumAge = 100;
  if (coverageType === eCoverageType.jointLastPayLastDeath || coverageType === eCoverageType.jointLast) {
    return maximumAge - equivalentAge + minimumAge;
  } else {
    return maximumAge;
  }
};

export const additionalYearsMax = (deathAtAge, upperLimit) => upperLimit - deathAtAge;

export const ageToYear = (age, clientAge) => Math.max(0, age - clientAge);
export const getDuration = (fromYear, toYear) => {
  if (isNaN(fromYear) || isNaN(toYear)) {
    return 0;
  } else {
    return toYear - fromYear + 1;
  }
};

export const getClientOptions = (clients, includeAllClients, client, coverage) => {
  const clientOptions = [];
  let clientIndex = 0;
  _.forEach(clients.allClients, (clientId) => {
    const aclient = clients[clientId];
    clientIndex++;
    if (aclient && (includeAllClients || clientId === client.id || _.indexOf(aclient.coverageTabId, coverage.id) < 0)) {
      clientOptions.push({
        label: clients[clientId].fullName || `${ePrefix.client} ${clientIndex}`,
        value: clientId,
      });
    }
  });
  return clientOptions;
};

export const getBaseCoverageClientOptions = (clients, includeAllClients, client, coverage) => {
  const clientBaseCoverageOptions = [];
  let clientIndex = 0;
  _.forEach(clients.allClients, (clientId) => {
    const aclient = clients[clientId];
    clientIndex++;
    const allClients = includeAllClients || clientId === client.id || _.indexOf(aclient.coverageTabId, coverage.id) < 0;
    if (aclient && aclient.firstName && allClients) {
      clientBaseCoverageOptions.push({
        label: clients[clientId].fullName || `${ePrefix.client} ${clientIndex}`,
        value: clientId,
      });
    }
  });
  return clientBaseCoverageOptions;
};

export const getMinClientAge = (party) => {
  if (party && party.length > 0) {
    const parties = _.cloneDeep(party);
    // filter out parties that are not clients i.e the partyId prefix is not Client
    // convert to sting her just incase the partId is sent as  a number it does cause a performance
    // drawback but it prevents the problem when we get a number
    const clients = parties.filter((p) => {
      if (p.partyId) {
        return p.partyId.toString().indexOf(ePrefix.client) === 0;
      }
      return false;
    });
    if (clients.length > 0) {
      return Math.min.apply(
        Math,
        clients.map(function (o) {
          return o.age;
        })
      );
    }
  }
  return eDefault.clientAge;
};

export const getKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value);

export const getDefaultCoverageAmount = (product) => {
  switch (product) {
    case eProduct.PAR:
      return eDefault.parCoverageAmount;
    case eProduct.MUL:
      return eDefault.mulCoverageAmount;
    case eProduct.FT:
    case eProduct.BT:
    case eProduct.FTRC:
    case eProduct.FTV:
      return eDefault.termCoverageAmount;
    default:
      return eDefault.coverageAmount;
  }
};

export const getProduct = (product) => {
  switch (product) {
    case eProduct.MUL: {
      return 'product.mul';
    }
    case eProduct.PAR: {
      return 'product.par';
    }
    case eProduct.FTRC: {
      return 'product.ftrc';
    }
    case eProduct.PG:
      return 'product.pg';

    default:
      return getProduct2(product);
  }
};

export const getProduct2 = (product) => {
  switch (product) {
    case eProduct.Performax:
      return 'product.performax';
    case eProduct.SB:
      return 'product.securitybuilder';
    case eProduct.FT: {
      return 'product.ft';
    }
    case eProduct.GI: {
      return 'product.gi';
    }
    case eProduct.BT: {
      return 'product.bt';
    }
    case eProduct.FTV: {
      return 'product.ftv';
    }
    case eProduct.LC:
    case eProduct.LC_DDA: {
      // In the future products from Apps should and Dash need to align to one spelling (Lifecheque or LifeCheque)
      return 'product.lc';
    }
    default:
      return 'No Product';
  }
};

export const getAppStatus = (status) => {
  switch (status) {
    case 'STARTED': {
      return 'status.started';
    }
    case 'CLEARED': {
      return 'status.cleared';
    }
    case 'SUBMITTED': {
      return 'status.submitted';
    }
    case 'LOCKED': {
      return 'status.locked';
    }
    case 'AWAIT_CLIENT_ESIGN': {
      return 'status.await-client-e-sign';
    }
    case 'AWAIT_ADVISOR_ESIGN': {
      return 'status.await-advisor-e-sign';
    }
    case 'AWAIT_SUBMIT': {
      return 'status.await-submit';
    }
    default:
      return getAppStatus2(status);
  }
};

const getAppStatus2 = (status) => {
  switch (status) {
    case 'DECLINED_BY_CLIENT': {
      return 'status.declined-by-client';
    }
    case 'EXPIRED': {
      return 'status.expired';
    }
    case 'ESIGN_LINK_EXPIRED': {
      return 'status.esign-link-expired';
    }
    default:
      return 'undefined';
  }
};

export const commonSort = (a, b) => {
  const labelA = a.label?.toLowerCase();
  const labelB = b.label?.toLowerCase();
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
};

export const getProdCoverageOption = (product, coverage) => {
  if (product === eProduct.PAR) {
    return coverage.premiumDuration;
  } else if (product === eProduct.MUL) {
    return coverage.costOfInsurance;
  } else {
    return coverage.coverageOption;
  }
};

export const isAlphaNumericSpaceChar = (str) => {
  const regexAlphaNumericSpaceChars = /^[\w\s]+$/;
  return str.match(regexAlphaNumericSpaceChars);
};

export const isNumeric = (value) => !isNaN(parseFloat(value)) && isFinite(value);

export const setVitalityYes = (coverage) => {
  if (coverage.isVitalityPlusSelected) {
    return 'Yes';
  } else {
    return '';
  }
};

export const setReturnPremiums = (product, coverage) => {
  if (product === eProduct.LC) {
    return coverage.lcReturnPremiums;
  } else {
    return { ropx: false, ropd: false, rops: false };
  }
};

export const setActiveVitalityStatus = (value, activeVitalityStatus) => {
  if (value) {
    return activeVitalityStatus;
  } else {
    return '';
  }
};

export const isMulOrPar = (product) => product === eProduct.MUL || product === eProduct.PAR;

export const isInforceVitalityApplicable = (product, isinforce) => isinforce && product === eProduct.PAR;

export const setChangeToBronzeYes = (product, isChangeToBronzeSelected, isVitalitySelected, activeVitalityStatus) => {
  if (
    isVitalitySelected &&
    isMulOrPar(product) &&
    isChangeToBronzeSelected &&
    activeVitalityStatus !== eVitalityStatus.bronze
  ) {
    return 'Yes';
  } else {
    return '';
  }
};

export const setIncludeDepositOptionToYes = (activeScenario, coverage) => {
  if (
    activeScenario &&
    activeScenario.includeMaxLevelDepositOption &&
    coverage.premiumDuration !== ePremiumDuration.pay10
  ) {
    return 'Yes';
  } else {
    return '';
  }
};

export const setCoverageSolveToYes = (coverage) => {
  if (coverage.isCoverageSolve) {
    return 'Yes';
  } else {
    return '';
  }
};

const getDataLayerCoverageOption = function (product, coverage) {
  let dataLayerCoverageOption = '';
  switch (product) {
    case eProduct.LC:
      dataLayerCoverageOption = coverage.lcCoverageOption;
      break;
    case eProduct.PAR:
      dataLayerCoverageOption = coverage.premiumDuration;
      break;
    case eProduct.MUL:
      dataLayerCoverageOption = coverage.costOfInsurance;
      break;
    default:
      dataLayerCoverageOption = coverage.coverageOption;
      break;
  }

  return dataLayerCoverageOption;
};

export const getDataLayerProductInfoFromState = (coverage, vitalityStatus, product, inforce, activeScenario) => {
  let activeVitalityStatus = '';

  if (vitalityStatus && vitalityStatus[vitalityStatus.activeTabId]) {
    if (vitalityStatus[vitalityStatus.activeTabId].length > 1) {
      activeVitalityStatus = 'Custom';
    } else {
      activeVitalityStatus = vitalityStatus[vitalityStatus.activeTabId][0].vitalityStatus;
    }
  }

  const producttype = inforce ? eBusinessType.IF : eBusinessType.NB;
  const coverageSolve = setCoverageSolveToYes(coverage);
  const vitality = setVitalityYes(coverage);
  const returnPremiums = setReturnPremiums(product, coverage);
  const vitalityStatusValue = setActiveVitalityStatus(coverage.isVitalityPlusSelected, activeVitalityStatus);
  const changeToBronze = setChangeToBronzeYes(
    product,
    coverage.isChangeToBronze,
    coverage.isVitalityPlusSelected,
    activeVitalityStatus
  );
  const includeDepositOption = setIncludeDepositOptionToYes(activeScenario, coverage);

  const dataLayer = {
    changeToBronze: coverage.premiumDuration === ePremiumDuration.Pay100 ? '' : changeToBronze,
    vitalityStatus: coverage.premiumDuration === ePremiumDuration.Pay100 ? '' : vitalityStatusValue,
    coverageType: coverage.coverageType,
    coverageOption: getDataLayerCoverageOption(product, coverage),
    lcReturnPremiums: returnPremiums,
    coverageSolve,
    includeDepositOption,
    vitality,
    product,
  };

  dataLayer.producttype = producttype;

  return dataLayer;
};

export const getClientAgeFromUdm = (illustration, state, scenarioTabId) => {
  let clientAge = 0;
  let client = false;
  if (illustration && illustration.policy) {
    if (illustration.policy.product === eProduct.MUL && illustration.policy.businessType === eBusinessType.IF) {
      const coverages = illustration.policy.coverage;
      const baseCoverage = coverages.find(
        (cvg) => cvg.indicatorCode === eField.indicatorCode.base && cvg.status === eBusinessType.IF
      );
      if (baseCoverage) {
        client = baseCoverage.life.find((life) => life.partyId === state[scenarioTabId][eField.basedonClient]);
      }
    } else {
      client = illustration.party.find((party) => party.partyId === state[scenarioTabId][eField.basedonClient]);
    }
  }
  if (client) {
    clientAge = client.age;
  }
  return clientAge;
};

export const handleInforcePolicyRetrievalError = (action) => {
  const errorState = {};
  const response = action.payload;
  errorState.isError = true;

  if (response.status === 'Unavailable') {
    errorState.errorStatus = 'afterhours';
    errorState.errors = eInforceErrorTypes.unavailable;
    return errorState;
  }

  if (response.status === 'NoData') {
    errorState.errorStatus = 'other';
    errorState.errors = eInforceErrorTypes.notIllustratable;
    return errorState;
  }

  if (response.isError || !response.status || response.status === 'Error') {
    errorState.errorStatus = 'unauthorized';
    errorState.errors = eInforceErrorTypes.unauthorized;
    return errorState;
  }

  if (response.status === 'Success' && response.policyData.status === 'Unsupportable') {
    return unsupportableErrorState(response, errorState);
  }

  errorState.errorStatus = 'unavailable';
  errorState.errors = eInforceErrorTypes.unavailable;
  return errorState;
};

const unsupportableErrorState = (response, errorState) => {
  switch (response.policyData.statusReason) {
    case 'NotIllustratable': {
      errorState.errorStatus = 'other';
      errorState.errors = eInforceErrorTypes.notIllustratable;
      return errorState;
    }
    case 'PaidUpPolicyNotSupported': {
      errorState.errorStatus = 'paidUpPolicy';
      errorState.errors = eInforceErrorTypes.paidUpPolicy;
      return errorState;
    }
    case 'WaiverNotSupported': {
      errorState.errorStatus = 'waiver';
      errorState.errors = eInforceErrorTypes.waiverNotSupported;
      return errorState;
    }
    case 'VitalityNotSupported': {
      errorState.errorStatus = 'vitalityplus';
      errorState.errors = [eProduct.FT, eProduct.BT].includes(response.entitlements.product)
        ? eInforceErrorTypes.vitalityNotSupportedTermInforce
        : eInforceErrorTypes.vitalityNotSupported;
      return errorState;
    }
    case 'SpecialQuoteNotSupported': {
      errorState.errorStatus = 'specialquote';
      errorState.errors =
        response.entitlements.product === eProduct.PAR
          ? eInforceErrorTypes.specialQuoteNotSupportedPAR
          : eInforceErrorTypes.specialQuoteNotSupported;
      return errorState;
    }
    case 'RedFlag': {
      errorState.errorStatus = 'redflag';
      errorState.errors = eInforceErrorTypes.redFlag;
      return errorState;
    }
    case 'RedFlagUnknown': {
      errorState.errorStatus = 'redflagunknown';
      errorState.errors = eInforceErrorTypes.unavailable;
      return errorState;
    }
    case 'CprCoveragesExceedMaximumSupportedNumber': {
      errorState.errorStatus = 'CprCoveragesExceedMaximumSupportedNumber';
      errorState.errors = eInforceErrorTypes.cprCoveragesExceedMaximumSupportedNumber;
      return errorState;
    }
    default: {
      return unsupportableErrorState1(response, errorState);
    }
  }
};

const unsupportableErrorState1 = (response, errorState) => {
  switch (response.policyData.statusReason) {
    case 'LeadTimePeriodNotSupported': {
      errorState.errorStatus = 'leadTimeValidation';
      errorState.errors = eInforceErrorTypes.leadTimeValidation;
      return errorState;
    }
    case 'TermOptionNotSupported': {
      errorState.errorStatus = 'termOptionNotSupported';
      errorState.errors = eInforceErrorTypes.termOptionNotSupported;
      return errorState;
    }
    case 'BaseCoveragesExceedMaximumSupportedNumber': {
      errorState.errorStatus = 'baseCoveragesExceedMaximumSupportedNumber';
      errorState.errors = eInforceErrorTypes.baseCoveragesExceedMaximumSupportedNumber;
      return errorState;
    }
    case 'TermRiderCoveragesExceedMaximumSupportedNumber': {
      errorState.errorStatus = 'termRiderCoveragesExceedMaximumSupportedNumber';
      errorState.errors = eInforceErrorTypes.termRiderCoveragesExceedMaximumSupportedNumber;
      return errorState;
    }
    case 'ProductMismatch': {
      errorState.errorStatus = 'other';
      errorState.errors = eInforceErrorTypes.productMismatch;
      return errorState;
    }
    case 'ProductNotSupported': {
      errorState.errorStatus = 'notIllustratable';
      errorState.errors = eInforceErrorTypes.productNotSupported;
      return errorState;
    }
    case 'SpecialRequestFormProduct': {
      errorState.errorStatus = eInforceErrorStatuses.specialRequestFormProduct;
      errorState.errors = eInforceErrorTypes.specialRequestFormProduct;
      return errorState;
    }
    case 'TermOptionPlusCoverageWithoutTermOptionCoverageNotSupported': {
      errorState.errorStatus = 'TermOptionPlusCoverageWithoutTermOptionCoverageNotSupported';
      errorState.errors = eInforceErrorTypes.termOptionPlusCoverageWithoutTermOptionCoverageNotSupported;
      return errorState;
    }
    case 'NoPaymentsError': {
      errorState.errorStatus = 'NoPaymentsError';
      errorState.errors = eInforceErrorTypes.noPaymentsError;
      return errorState;
    }
    default: {
      errorState.errorStatus = 'unauthorized';
      errorState.errors = eInforceErrorTypes.unauthorized;
      return errorState;
    }
  }
};

export const getYearsToPolicyEnd = (age) => {
  const maxPolicyYear = 100;
  return maxPolicyYear - age;
};

/**
 * Calculates the current policy year based on effective date
 * @param {Date} policyEffectiveDate - Policy effective date (YYYY-MM-DD)
 * @returns {Number} Current policy year
 * @example
 * const policyYear = getPolicyYear('2024/01/01');
 */
export const getPolicyYear = (policyEffectiveDate) => {
  const policyEffectiveYear = moment(policyEffectiveDate, 'YYYY-MM-DD').year();
  const policyEffectiveMonth = moment(policyEffectiveDate, 'YYYY-MM-DD').month();
  return policyEffectiveMonth >= moment().month() + 1
    ? moment().year() - policyEffectiveYear
    : moment().year() - policyEffectiveYear + 1;
};

export const inforceDbProductTypeEnums = {
  Architect: 0,
  Champion: 2,
  DividendTerm: 3,
  DlUl: 4,
  FutureProtection: 5,
  Innovision: 6,
  Intrepid: 7,
  LifeAccumulator: 8,
  LifeSaver: 9,
  MliDlPar: 10,
  NalTraditional: 11,
  NewWorldSeries: 13,
  MaritimeLifeProducts: 14,
  PerformaxGold: 15,
  Performax: 16,
  PerformancePlan: 17,
  SecuredProtection: 22,
  SecurityBuilder: 16, // Shares the same form as Performax
  SecurityUl: 23,
  SecurityUl2: 20,
  Solutions: 21,
  Ul100: 24,
  Mul: 25,
  Par: 26,
  ParVP: 27,
};

export const eliteStatusEnums = {
  None: 0,
  Elite: 1,
  Gold: 2,
  Platinum: 3,
};

export const productTypeToInforceDbEnum = (productType) => {
  const architect = 'Architect';
  let inforceDbProductType = -1;

  if (productType) {
    inforceDbProductType = inforceDbProductTypeEnums[productType] ? inforceDbProductTypeEnums[productType] : -1;
    inforceDbProductType = productType === architect ? 0 : inforceDbProductType;
  }

  return inforceDbProductType;
};

export const advisorEliteStatusToInforceDbEnum = (advisorEliteStatus) => {
  let level = advisorEliteStatus.Program_Level__c;
  if (level) {
    level = level.toLowerCase();
    if (level.indexOf('gold') !== -1) {
      return eliteStatusEnums.Gold;
    }
    if (level.indexOf('platinum') !== -1) {
      return eliteStatusEnums.Platinum;
    }
  }

  return eliteStatusEnums.None;
};

export const prepareInforceRequestFormPayload = (data) => {
  const modifiedInforceRequestFormPayload = { ...data.inforceInfo[0] };
  const inforceInfo = data.inforceInfo[0];
  modifiedInforceRequestFormPayload.language = data.locale === 'en' ? 0 : 1;
  modifiedInforceRequestFormPayload.requesterName = data.userName;
  modifiedInforceRequestFormPayload.requesterEmail = data.email;
  modifiedInforceRequestFormPayload.requesterId = data.ciamId;
  modifiedInforceRequestFormPayload.policyNumber = data.openedCaseName;
  modifiedInforceRequestFormPayload.clientName = inforceInfo.ownerName;
  modifiedInforceRequestFormPayload.servicingBranch = inforceInfo.serviceBranchCode;
  modifiedInforceRequestFormPayload.advisorName = inforceInfo.serviceAgentName;
  modifiedInforceRequestFormPayload.advisorNumber = inforceInfo.serviceAgentCode;
  modifiedInforceRequestFormPayload.productType = productTypeToInforceDbEnum(inforceInfo.product);
  if (inforceInfo.advisorEliteStatus) {
    modifiedInforceRequestFormPayload.advisorEliteStatus = advisorEliteStatusToInforceDbEnum(
      inforceInfo.advisorEliteStatus
    );
  }
  if (data.redFlag) {
    modifiedInforceRequestFormPayload.redFlagged = data.redFlag;
  }
  return modifiedInforceRequestFormPayload;
};

export const checkShouldRenderDividendOption = (product, premiumFrequency, dividendOption) => {
  let dividendOptionRender;
  if (
    [eProduct.Performax, eProduct.SB].includes(product) &&
    dividendOption === ePerformaxDividendOption.PremiumReduction &&
    premiumFrequency === ePremiumFrequency.annually
  ) {
    dividendOptionRender = true;
  } else if (
    [eProduct.Performax, eProduct.SB].includes(product) &&
    dividendOption === ePerformaxDividendOption.PremiumReduction &&
    premiumFrequency !== ePremiumFrequency.annually
  ) {
    dividendOptionRender = false;
  } else {
    dividendOptionRender = true;
  }
  return dividendOptionRender;
};

export const filterDividendOptions = (dividendOptions, dividendOption) =>
  _.filter(dividendOptions, (scale) => {
    let result;
    if (dividendOption === ePerformaxDividendOption.termOption) {
      result = ![ePerformaxDividendOption.PremiumReduction, ePerformaxDividendOption.Accumulation].includes(
        scale.value
      );
    } else if (dividendOption === ePerformaxDividendOption.pui) {
      result = ![
        ePerformaxDividendOption.PremiumReduction,
        ePerformaxDividendOption.Accumulation,
        ePerformaxDividendOption.termOption,
      ].includes(scale.value);
    } else if (
      dividendOption === ePerformaxDividendOption.cash ||
      dividendOption === ePerformaxDividendOption.Accumulation
    ) {
      result = ![
        ePerformaxDividendOption.PremiumReduction,
        ePerformaxDividendOption.AccumulationTransferPUIToAccumulation,
        ePerformaxDividendOption.termOption,
        ePerformaxDividendOption.AccumulationMaintainExistingPUI,
        ePerformaxDividendOption.pui,
      ].includes(scale.value);
    } else if (dividendOption === ePerformaxDividendOption.PremiumReduction) {
      result = ![
        ePerformaxDividendOption.AccumulationTransferPUIToAccumulation,
        ePerformaxDividendOption.termOption,
        ePerformaxDividendOption.AccumulationMaintainExistingPUI,
        ePerformaxDividendOption.pui,
        ePerformaxDividendOption.cash,
        ePerformaxDividendOption.Accumulation,
      ].includes(scale.value);
    } else {
      result = false;
    }
    return result;
  });

export const filterDividendScales = (divScales, scaleToFilterOut) =>
  _.filter(divScales, (scale) => scale.value !== scaleToFilterOut);

export const isValidDOB = (data) => {
  const listProperty = Object.keys(data).find((key) => Array.isArray(data[key]));
  const list = data[listProperty];
  return list.every((id) => {
    const entity = data[id];
    return entity && entity.dob !== '';
  });
};

export const isValidName = (data) => {
  const listProperty = Object.keys(data).find((key) => Array.isArray(data[key]));
  const list = data[listProperty];
  return list.every((id) => {
    const entity = data[id];
    return entity && entity.firstName !== '' && entity.lastName !== '';
  });
};

export const isUDMResponseValidationExist = (response, validationKey) => {
  return (
    typeof response?.validations?.validation?.find((obj) => obj.id.toLowerCase() === validationKey.toLowerCase()) ===
    'object'
  );
};

export const isDeceased = (clients) => {
  if (clients) {
    const clientsArr = Object.values(clients);
    return clientsArr.some((client) => client.deceased);
  }
  return false;
};

export const generatePolicyChangeRequestId = (policyChangeRequest) => {
  return `PolicyChangeId.${(policyChangeRequest?.length ?? 0) + 1}`;
};

export const getReportPreparedForId = () => {
  return `${ePrefix.preparedFor}.1`;
};

export const getValidStatusForInforce = (product) => {
  return [eStatuses.inforce, ...([eProduct.FT, eProduct.BT].includes(product) ? [eStatuses.waiver] : [])];
};
