import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  eCalcField,
  eDepositOptionDurationType,
  eTermRenewalClient,
  eField,
  eProduct,
  eUserType,
  ePremiumDuration,
  ePremiumOffsetMinYear,
  ePremiumOffset,
} from '../../core/configs';
import { getFormattedValue } from '../../core/utils';
import * as SummaryUtils from './utils';
import { ProductConfig } from '../product-config';
import { dropDownOptions } from '../../core/dropdown-config';
import * as AppActions from '../../core/actions';
import * as ScenarioActions from '../scenario-tab-navs/actions';
import moment from 'moment';
import 'moment/locale/fr';
import './summary.css';
import SummaryCoverageSection from './summary-coverage-section';
import SummaryHeader from './summary-header';
import SummaryPremiumSection from './summary-premium-section';
import SummaryPaymentSection from './summary_payment_section';
import SummaryRiderSection from './summary_rider_section';
import SummaryAdditionalCostSection from './summary_additionalcost_section';

export class Summary extends PureComponent {
  static propTypes = {
    isOutOfDate: PropTypes.bool,
    isForceUpdate: PropTypes.bool,
    udmEnabled: PropTypes.bool,
    activeScenarioTabId: PropTypes.string,
    showManualRefresh: PropTypes.bool,
    productValidationError: PropTypes.bool,
    app: PropTypes.object,
    udmResponse: PropTypes.object,
    udmPolicy: PropTypes.object,
    udmCoverages: PropTypes.array,
    udmIllustrationCoverages: PropTypes.array,
    riders: PropTypes.object,
    termRiders: PropTypes.object,
    termOtherRiders: PropTypes.object,
    spousalRiders: PropTypes.object,
    allCoverages: PropTypes.object,
    effectiveDate: PropTypes.string,
    product: PropTypes.string,
    premiumFrequency: PropTypes.string,
    premiumDuration: PropTypes.string,
    premiumOffset: PropTypes.string,
    premiumOffsetYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    premiumOffsetAltYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    depositOption: PropTypes.object,
    dividendScale: PropTypes.object,
    withdrawals: PropTypes.object,
    clients: PropTypes.object,
    ridersByClient: PropTypes.array,
    intl: PropTypes.object,
    isCoverageSummaryFolded: PropTypes.bool,
    isRiderSummaryFolded: PropTypes.bool,
    isIllustrationRatesFolded: PropTypes.bool,
    isAdditionalCostsFolded: PropTypes.bool,
    selectedVitalityStatus: PropTypes.string,
    isVitalityPlusSelected: PropTypes.bool,
    isCustomVitalityStatus: PropTypes.bool,
    inforce: PropTypes.bool,
    userType: PropTypes.string,

    appActions: PropTypes.object,
    sceActions: PropTypes.object,
    additionOptionType: PropTypes.string,
    depositOptionDurationType: PropTypes.string,
    depositOptionDuration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    depositOptionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    performanceCreditRate: PropTypes.number,
    altPerformanceCreditRate: PropTypes.number,
    additionalDeposit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    outOfPocketDuration: PropTypes.number,
    isCoverageSolve: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.errorRef = React.createRef();
    this.state = {
      isRiderSelected: false,
      appReady: false,
    };
    this.configs = dropDownOptions(this.props.intl);
    this.dispatchAction = this.dispatchAction.bind(this);
    this.dispatchScenarioAction = this.dispatchScenarioAction.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
  }

  componentDidMount() {
    if (!this.state.appReady) {
      this.setState({ appReady: true });
    }
    const isRiderSelected = SummaryUtils.isRiderSelected(this.props.riders, this.props.clients);
    this.setState({ isRiderSelected });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.riders !== this.props.riders) {
      let isRiderSelected = SummaryUtils.isRiderSelected(this.props.riders, this.props.clients);
      if (!isRiderSelected) {
        isRiderSelected = this.props.termRiders.allRiders.length > 0;
      }
      this.setState({ isRiderSelected });
    }
  }

  get initialPremium() {
    if ([eProduct.Performax, eProduct.SB].includes(this.props.product)) {
      const initialPremiumAmount = this.props.udmPolicy.coverage
        ? this.props.udmPolicy.coverage[0][eCalcField.premium]
        : this.props.udmPolicy[eCalcField.initialPremium];
      return getFormattedValue(this.props.app.locale, initialPremiumAmount || '0');
    } else {
      return getFormattedValue(this.props.app.locale, this.props.udmPolicy[eCalcField.initialPremium] || '0');
    }
  }

  get totalPremium() {
    const { udmPolicy } = this.props;
    return getFormattedValue(this.props.app.locale, udmPolicy[eCalcField.premium] || '0');
  }

  get policyFee() {
    return getFormattedValue(this.props.app.locale, this.props.udmPolicy[eCalcField.policyFee] || '0');
  }

  get vitalityFee() {
    return getFormattedValue(
      this.props.app.locale,
      this.props.udmPolicy.vitality !== undefined ? this.props.udmPolicy.vitality[eCalcField.vitalityFee] : '0'
    );
  }

  get maxAdditionalDeposit() {
    return getFormattedValue(
      this.props.app.locale,
      this.props.udmPolicy[eCalcField.maximumAdditionalDepositAmt] || '0'
    );
  }

  get annualizedModalPayment() {
    return getFormattedValue(
      this.props.app.locale,
      this.props.udmPolicy[eCalcField.annualizedInitialModalPremium] || '0'
    );
  }

  reload = () => {
    if (SummaryUtils.isFamilyTermRenewalCalc(this.props.product)) {
      const client1 = this.props.clients[eTermRenewalClient.defaultId];
      let callCalc = true;

      // FTRC validation - show errors on required fields being empty, block calc call unless valid
      if (client1 && !client1.dob) {
        this.dispatchScenarioAction('dob', this.props.intl.formatMessage({ id: 'error.emptyDate' }), true);
        callCalc = false;
      }

      if (!this.props.effectiveDate || moment().format('DD/MM/YYYY') === this.props.effectiveDate) {
        this.dispatchScenarioAction('effectiveDate', this.props.intl.formatMessage({ id: 'error.emptyDate' }), true);
        callCalc = false;
      }

      if (callCalc) {
        this.props.sceActions.toggleOutOfDate(true);
        this.props.sceActions.toggleForceUpdate(true);
        this.props.sceActions.performManualRefresh(true);
        this.dispatchAction('isRequestingCalculation', true, 'app');
        this.props.sceActions.requestUDMResponse();
      }
    } else if (SummaryUtils.isPar(this.props.product)) {
      this.props.sceActions.performManualRefresh(true);
      this.dispatchAction('isRequestingCalculation', true, 'app');
      this.props.sceActions.requestUDMResponse();
    } else {
      this.dispatchAction('isRequestingCalculation', true, 'app');
      this.props.sceActions.requestUDMResponse();
    }
  };

  switchUDM = (e) => {
    if (e.altKey) {
      this.props.sceActions.performManualRefresh();
      this.props.sceActions.toggleUDM(true);
    }
  };

  dispatchAction(field, value, target = 'coverage', isError = false) {
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target,
      field,
      value,
      scenarioTabId: this.props.activeScenarioTabId,
      coverageTabId: this.props.allCoverages.activeTabId,
    });
  }

  dispatchScenarioAction(field, value, isError = false, refresh = true) {
    // This is used for Family Term Renewal Calc --- Will be revisited when more clients are supported
    const { appActions, sceActions } = this.props;

    appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target: 'scenario',
      scenarioTabId: this.props.activeScenarioTabId,
      product: this.props.product,
      clientId: eTermRenewalClient.defaultId,
      field,
      value,
    });
    if (refresh) {
      sceActions.toggleOutOfDate(true);
    }
  }

  handleYearChange = (value) => {
    let minYear = 1;
    minYear =
      this.props.app.userType === eUserType.advisor
        ? ePremiumOffsetMinYear.advisorStartingAtMinYear
        : ePremiumOffsetMinYear.headOfficeStartingAtMinYear;
    const maxYear = 99;
    const maxYearPay100 = 100;
    const maxYearCheck = this.props.premiumDuration === ePremiumDuration.Pay100 ? maxYearPay100 : maxYear;
    if (+value < minYear && this.props.premiumOffset !== ePremiumOffset.earliest) {
      value = minYear;
    } else if (+value > maxYear) {
      value = maxYear;
    } else if (+value > maxYearCheck) {
      value = maxYearCheck;
    } else {
      // do nothing
    }
    return value;
  };

  onChange = (value) => {
    if (this.props.product === eProduct.PAR) {
      this.handleYearChange(value);
    }
  };

  renderPremiumSummary = (clientSummary) => {
    const { intl, app, depositOption, premiumFrequency, product, productValidationError, udmResponse, allCoverages } =
      this.props;
    return (
      <div id="summary_premium">
        <SummaryPremiumSection
          depositOption={depositOption}
          intl={intl}
          udmResponse={udmResponse}
          app={app}
          product={product}
          clientSummary={clientSummary}
          totalPremium={this.totalPremium}
          initialPremium={this.initialPremium}
          premiumFrequency={premiumFrequency}
          productValidationError={productValidationError}
          isLevelCOI={SummaryUtils.isLevelCOI(allCoverages)}
          isVitalityPlusSelected={SummaryUtils.isVitalityPlus(allCoverages)}
          maxAdditionalDeposit={this.maxAdditionalDeposit}
          annualizedModalPayment={this.annualizedModalPayment}
        />
      </div>
    );
  };

  renderCoverageSummary = (clientSummary) => {
    const {
      intl,
      app,
      dividendScale,
      effectiveDate,
      isCoverageSummaryFolded,
      product,
      udmResponse,
      selectedVitalityStatus,
      isVitalityPlusSelected,
      isCustomVitalityStatus,
      performanceCreditRate,
      altPerformanceCreditRate,
    } = this.props;
    return (
      <div id="summary_coverage">
        <SummaryCoverageSection
          isCoverageSummaryFolded={isCoverageSummaryFolded}
          clientSummary={clientSummary}
          app={app}
          intl={intl}
          configs={this.configs}
          product={product}
          isUDMLoading={udmResponse.isLoading}
          effectiveDate={effectiveDate}
          dividendScale={dividendScale}
          dispatchAction={this.dispatchAction}
          vitalityStatus={selectedVitalityStatus}
          isVitalityPlusSelected={isVitalityPlusSelected}
          isCustomVitalityStatus={isCustomVitalityStatus}
          performanceCreditRate={performanceCreditRate}
          altPerformanceCreditRate={altPerformanceCreditRate}
        />
      </div>
    );
  };

  renderPaymentSummary = () => {
    const {
      intl,
      app,
      clients,
      depositOption,
      isIllustrationRatesFolded,
      premiumFrequency,
      premiumOffset,
      premiumOffsetAltYear,
      premiumOffsetYear,
      premiumDuration,
      product,
      showManualRefresh,
      udmResponse,
      withdrawals,
      allCoverages,
      depositOptionDurationType,
      additionOptionType,
      depositOptionDuration,
      depositOptionAmount,
      outOfPocketDuration,
      isCoverageSolve,
    } = this.props;
    return (
      <div id="summary_payment">
        <SummaryPaymentSection
          isIllustrationRatesFolded={isIllustrationRatesFolded}
          dispatchAction={this.dispatchAction}
          showManualRefresh={showManualRefresh}
          premiumFrequency={premiumFrequency}
          depositOption={depositOption}
          isSwitchToCashDividends={depositOption.hasSwitchToCashDividends}
          premiumOffset={premiumOffset}
          isUDMLoading={udmResponse.isLoading}
          locale={app.locale}
          premiumOffsetAltYear={premiumOffsetAltYear}
          premiumOffsetYear={premiumOffsetYear}
          premiumDuration={premiumDuration}
          outOfPocketDuration={outOfPocketDuration}
          intl={intl}
          withdrawals={withdrawals}
          clients={clients}
          product={product}
          isInforce={allCoverages.inforce}
          additionOptionType={additionOptionType}
          depositOptionDurationType={depositOptionDurationType}
          depositOptionDuration={depositOptionDuration}
          depositOptionAmount={depositOptionAmount}
          userType={app.userType}
          onChange={this.onChange}
          initialPremium={this.initialPremium}
          app={app}
          isCoverageSolve={isCoverageSolve}
        />
      </div>
    );
  };

  renderRiderSummary = (riderSummary, numChildren) => {
    const { intl, app, clients, isRiderSummaryFolded, product, udmResponse } = this.props;
    return (
      <div id="summary_rider">
        <SummaryRiderSection
          isRiderSummaryFolded={isRiderSummaryFolded}
          riderSummary={riderSummary}
          dispatchAction={this.dispatchAction}
          locale={app.locale}
          intl={intl}
          isUDMLoading={udmResponse.isLoading}
          numChildren={numChildren}
          isRiderSelected={this.state.isRiderSelected}
          product={product}
          clients={clients}
          inforce={app.inforcePolicy}
        />
      </div>
    );
  };

  buildRiderSummary = () => {
    const {
      intl,
      clients,
      riders,
      ridersByClient,
      termRiders,
      udmCoverages,
      udmIllustrationCoverages,
      termOtherRiders,
      spousalRiders,
      product,
    } = this.props;
    return SummaryUtils.buildRiderSummary(
      termRiders,
      riders,
      clients,
      udmCoverages && udmIllustrationCoverages && udmCoverages.length < udmIllustrationCoverages.length
        ? udmIllustrationCoverages
        : udmCoverages,
      ridersByClient,
      `${intl.formatMessage({ id: 'rider.payor' })} 1`,
      termOtherRiders,
      spousalRiders,
      product,
      this.configs
    );
  };

  renderAdditionalCostSummary = () => {
    const { isAdditionalCostsFolded, product, isVitalityPlusSelected, inforce } = this.props;
    const isFtBtInforce = inforce && [eProduct.FT, eProduct.BT].includes(product);
    if (
      !isFtBtInforce &&
      (![eProduct.PAR, eProduct.PG, eProduct.Performax, eProduct.SB].includes(product) ||
        (product === eProduct.PAR && isVitalityPlusSelected))
    ) {
      return (
        <div id="summary_additionalcost">
          <SummaryAdditionalCostSection
            isAdditionalCostsFolded={isAdditionalCostsFolded}
            isFamilyTermVitality={SummaryUtils.isFamilyTermVitality(product)}
            dispatchAction={this.dispatchAction}
            policyFee={this.policyFee}
            vitalityFee={this.vitalityFee}
            isVitalityPlusSelected={isVitalityPlusSelected}
            isPar={SummaryUtils.isPar(product)}
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  getChildCount = (riderSummary, product) => {
    if (riderSummary.cpr) {
      return SummaryUtils.isPmaxOrSB(product)
        ? riderSummary.cpr.childCount || 0
        : riderSummary.cpr.children.length || 0;
    }
    return 0;
  };

  shouldRenderPerformaxRidersInSummary = () => {
    const { product, termRiders, termOtherRiders, spousalRiders } = this.props;
    return (
      SummaryUtils.isPmaxOrSB(product) &&
      (termRiders.allRiders.length > 0 || termOtherRiders.allRiders.length > 0 || spousalRiders.allRiders.length > 0)
    );
  };

  render = () => {
    const {
      clients,
      product,
      allCoverages,
      udmCoverages,
      depositOption,
      intl,
      udmResponse,
      showManualRefresh,
      isOutOfDate,
      isVitalityPlusSelected,
      inforce,
    } = this.props;
    // this.configs needs to be set before the clientSummary & riderSummary
    // renders or re-renders to ensure the language switches.
    this.configs = dropDownOptions(intl);
    const clientSummary = SummaryUtils.buildClientSummary(
      allCoverages,
      udmCoverages,
      udmResponse,
      clients,
      product,
      this.configs
    );
    const riderSummary = this.buildRiderSummary();
    const numChildren = this.getChildCount(riderSummary, product);
    const ownerResidence = () => depositOption && depositOption.ownerResidence;

    const summary = ProductConfig[product].summary;
    // Revisit post-MVP to fix Rider Summary
    // Add Inforce clause, and ensure duration is picked up
    return (
      <div className="summary-container" role="container-summary">
        <div className="summary summary-area">
          <div id="summary_header">
            <SummaryHeader
              udmResponse={udmResponse}
              totalPremium={this.totalPremium}
              showManualRefresh={showManualRefresh}
              isOutOfDate={isOutOfDate}
              switchUDM={this.switchUDM}
              reload={this.reload}
              ownerResidence={ownerResidence()}
              dispatchAction={this.dispatchAction}
              selectResidenceClicked={depositOption.selectResidenceClicked}
              product={product}
              isVitalityPlusSelected={isVitalityPlusSelected}
              intl={intl}
              inforce={inforce}
            />
          </div>

          <div className="content">
            {summary.premiumSummary && this.renderPremiumSummary(clientSummary)}

            <div className="summary-scroll">
              <div className="summary-accordion-wrapper">
                {summary.coverageSummary && this.renderCoverageSummary(clientSummary)}

                {summary.paymentSummary && this.renderPaymentSummary()}

                {
                  // Minimizing impact to other products by specificing PMax/SB for the term rider condition.
                  ((summary.riderSummary && (this.state.isRiderSelected || numChildren > 0)) ||
                    this.shouldRenderPerformaxRidersInSummary()) &&
                    this.renderRiderSummary(riderSummary, numChildren)
                }

                {summary.additionalCostSummary && this.renderAdditionalCostSummary()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const bronzeDepositAmount = (policy) => {
  return policy?.vitality?.bronzeDepositAmount ?? 0;
};

const inforceDepositAmountCalc = (udmDepositOption, inforceDepositOption) => {
  let maximumFirstYearAmount = 0;
  let minimumFirstYearAmount = 0;

  if (inforceDepositOption) {
    if (inforceDepositOption.maximumFirstYearAmount) {
      maximumFirstYearAmount = inforceDepositOption.maximumFirstYearAmount;
    }
    if (inforceDepositOption.minimumFirstYearAmount) {
      minimumFirstYearAmount = inforceDepositOption.minimumFirstYearAmount;
    }
  }

  if (udmDepositOption) {
    if (udmDepositOption.maximumDepositAmount) {
      maximumFirstYearAmount = udmDepositOption.maximumDepositAmount;
    }
    if (udmDepositOption.maximumFirstYearAmount) {
      maximumFirstYearAmount = udmDepositOption.maximumFirstYearAmount;
    }
    if (udmDepositOption.minimumDepositAmount) {
      minimumFirstYearAmount = udmDepositOption.minimumDepositAmount;
    }
    if (udmDepositOption.maximumFirstYearAmount) {
      minimumFirstYearAmount = udmDepositOption.minimumFirstYearAmount;
    }
  }

  return { minimumFirstYearAmount, maximumFirstYearAmount };
};

const mapDepositOption = (scenarioTabNavs, coverageTabNavs, policy, deposits) => {
  const activeTabId = scenarioTabNavs.activeTabId;
  const activeScenario = scenarioTabNavs.topBars[activeTabId];
  const coverageState = coverageTabNavs[activeTabId];
  const activeCoverageTabId = coverageState.activeTabId;
  const hasSwitchToCashDividends = coverageState[activeCoverageTabId].switchToCashDividends;
  const udmDepositOption = policy && policy.depositOption;
  const coverage = policy && policy.coverage;
  const sameAsBaseDurationYears = coverage && coverage[0] && coverage[0].depositDuration;
  const sameAsBaseToAge = policy && policy.depositOption && policy.depositOption.duration;
  const sameAsBaseToAgeDuration = () => (sameAsBaseToAge && sameAsBaseToAge > 0 ? sameAsBaseToAge : 0);
  const sameAsBaseDuration = () => udmDepositOption && udmDepositOption.duration;
  const durationMUL = () =>
    sameAsBaseDurationYears && sameAsBaseDurationYears > 0 ? sameAsBaseDurationYears : sameAsBaseToAgeDuration();
  const solveDuration = () => udmDepositOption && udmDepositOption.solveDuration;
  const { maximumFirstYearAmount, minimumFirstYearAmount } = inforceDepositAmountCalc(
    udmDepositOption,
    deposits[activeTabId]
  );

  return {
    type: activeScenario.depositOptionType,
    amount: activeScenario.depositOptionAmount,
    durationType: activeScenario.depositOptionDurationType,
    duration:
      activeScenario.depositOptionDurationType === eDepositOptionDurationType.year
        ? activeScenario.depositOptionDuration
        : sameAsBaseDuration(),
    isCustom: activeScenario.isDepositOptionCustom,
    depositOptionDuration: activeScenario.depositOptionDuration,
    minimumFirstYearAmount,
    maximumFirstYearAmount,
    bronzeDepositAmount: bronzeDepositAmount(policy),
    ownerResidence: deposits[activeTabId][eField.depositOwnerResidence],
    depositFrequency: deposits[activeTabId][eField.depositFrequency],
    depositType: deposits[activeTabId][eField.depositType],
    isAdditionalDepositSelected: deposits[activeTabId][eField.isAdditionalDepositSelected],
    additionalDeposit: deposits[activeTabId][eField.depositAdditionalDeposit],
    depositAmount: deposits[activeTabId][eField.depositAmount],
    durationMUL: durationMUL(),
    durationStart: deposits[activeTabId][eField.durationFrom],
    durationTo: deposits[activeTabId][eField.durationTo],
    selectResidenceClicked: deposits[activeTabId][eField.selectClicked],
    solveDuration: solveDuration(),
    hasSwitchToCashDividends,
  };
};

export const mapStateToProps = ({
  app,
  udmResponse,
  scenarioTabNavs,
  coverageTabNavs,
  clients,
  riders,
  termRiders,
  termOtherRiders,
  spousalRiders,
  withdrawals,
  deposits,
  vitalityStatus,
}) => {
  const activeTabId = scenarioTabNavs.activeTabId;
  const activeScenario = scenarioTabNavs.topBars[activeTabId];
  const coverageState = coverageTabNavs[activeTabId];
  const { illustration, udmIllustration } = udmResponse;

  const policy = illustration && illustration.policy;
  const ridersByClient = policy && policy.life;
  const udmCoverages = policy && policy.coverage;
  const dividendScale = {
    primary: activeScenario.primaryDividendScale,
    alternate: activeScenario.alternateDividendScale,
  };
  const depositAmount = (policy && policy.depositAmount) || 0;
  const udmIllustrationCoverages = () => udmIllustration && udmIllustration.policy && udmIllustration.policy.coverage;
  const selectedVitalityStatus = vitalityStatus[activeTabId][0].vitalityStatus;
  const isCustomVitalityStatus = vitalityStatus[activeTabId].length > 1;
  const activeCoverage = coverageState[coverageState.activeTabId];
  const isVitalityPlusSelected = activeCoverage.isVitalityPlusSelected;

  return {
    isOutOfDate: scenarioTabNavs.isOutOfDate,
    isForceUpdate: scenarioTabNavs.isForceUpdate,
    udmEnabled: scenarioTabNavs.udmEnabled,
    activeScenarioTabId: activeTabId,
    showManualRefresh: scenarioTabNavs.manualRefresh,
    productValidationError: activeScenario.productValidationError,
    udmPolicy: policy || {},
    udmCoverages: udmCoverages || [{}],
    udmIllustrationCoverages: udmIllustrationCoverages() || [{}],
    allCoverages: coverageState,
    clients: clients[activeTabId],
    riders: riders[activeTabId],
    termRiders: termRiders[activeTabId],
    termOtherRiders: termOtherRiders[activeTabId],
    spousalRiders: spousalRiders[activeTabId],
    effectiveDate: activeScenario.effectiveDate,
    product: activeScenario.product,
    premiumFrequency: activeScenario.premiumFrequency,
    premiumOffset: activeScenario.premiumOffset,
    premiumOffsetYear: activeScenario.premiumOffsetYear,
    premiumOffsetAltYear: activeScenario.premiumOffsetAltYear,
    outOfPocketDuration: activeScenario.outOfPocketDuration,
    isCoverageSummaryFolded: activeScenario.isCoverageSummaryFolded,
    isRiderSummaryFolded: activeScenario.isRiderSummaryFolded,
    isIllustrationRatesFolded: activeScenario.isIllustrationRatesFolded,
    isAdditionalCostsFolded: activeScenario.isAdditionalCostsFolded,
    additionOptionType: activeScenario.additionOptionType,
    depositOptionDurationType: activeScenario.depositOptionDurationType,
    depositOptionDuration: activeScenario.depositOptionDuration,
    depositOptionAmount: activeScenario.depositOptionAmount,
    depositOption: {
      ...mapDepositOption(scenarioTabNavs, coverageTabNavs, policy, deposits),
      depositAmount,
    },
    withdrawals: withdrawals[activeTabId],
    app,
    dividendScale,
    ridersByClient,
    udmResponse,
    selectedVitalityStatus,
    isVitalityPlusSelected,
    isCustomVitalityStatus,
    inforce: app.inforcePolicy,
    performanceCreditRate: activeScenario.performanceCreditRate,
    altPerformanceCreditRate: activeScenario.altPerformanceCreditRate,
    isCoverageSolve: activeCoverage.isCoverageSolve,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Summary));
