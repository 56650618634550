import * as actionTypes from '../../core/actionTypes';
import { eProduct, eReqStatus } from '../../core/configs';
import _ from 'lodash';

const initState = {
  id: '',
  type: '',
  status: '',
  validationStatus: '',
  isValidationError: false,
  illustration: {},
  validation: {}, // validation result will be set if there are errors, warnings or both
  udmErrorMessage: '', // UDM response error message
  requestErrorMesage: '', // system or network error message
  validationErrors: [], // validation error messages
  validationWarnings: [], // validation warning messages
  isLoading: false,
  isError: false,
};

const loadedResponse = (state, payload) => {
  const { response } = payload;
  const product = response.default?.illustration.policy.product;
  const respStatus = response && response.status && response.status.toUpperCase();
  // it is different from validation error. this error might happen before validation
  const isError = respStatus === eReqStatus.error || respStatus === eReqStatus.cancelled || response === '';
  // these used to be constant variables but according to SonarQube, it's getting too complex
  // so now they are methods, not so complex now lol
  const validations = () => (response && response.validations) || {};
  const validationStatus = () => validations().status || '';
  const illustration = () => {
    if (![eProduct.Performax, eProduct.SB].includes(product)) {
      return isError || validationStatusText === eReqStatus.error ? state.illustration : response.illustration;
    } else {
      return isError ? state.illustration : response.default.illustration;
    }
  };

  const udmIllustration = () => {
    if (response && response.illustration) {
      if (response.illustration.policy.calculationVectors === undefined && state.illustration.policy !== undefined) {
        response.illustration.policy.calculationVectors = state.illustration.policy.calculationVectors;
      }
      return response.illustration;
    } else {
      return state.illustration;
    }
  };

  const validationStatusText = validationStatus().toUpperCase();

  return {
    ...state,
    illustration: illustration(),
    udmIllustration: udmIllustration(),
    isLoading: respStatus === eReqStatus.cancelled,
    status: (response && response.status) || '',
    validationStatus: validationStatus(),
    type: (response && response.type) || '',
    isError,
    isValidationError: validationStatusText === eReqStatus.error,
    udmErrorMessage: (response && response.message) || '',
    validationErrors: _.filter(validations().validation, { status: 'Error' }),
    validationWarnings: _.filter(validations().validation, { status: 'Warning' }),
  };
};

export default function udmReducer(state, action) {
  state = state === undefined ? initState : state;
  switch (action.type) {
    case actionTypes.COMMON_INITIALIZE: {
      return initState;
    }

    case actionTypes.LOADING_UDM_RESPONSE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actionTypes.LOADED_UDM_RESPONSE: {
      return loadedResponse(state, action.payload);
    }

    case actionTypes.LOADED_INFORCE_POLICY: {
      const inforceState = { ...state };
      const inforcePolicy = action.payload.policyData.illustration.policy;
      const depositOption = inforcePolicy.depositOption;
      inforceState.illustration = { ...state.illustration };
      inforceState.illustration.depositOption = {};
      inforceState.illustration.depositOption.maximumFirstYearAmount = depositOption
        ? depositOption.minimumDepositAmount
        : '';
      inforceState.illustration.depositOption.minimumFirstYearAmount = depositOption
        ? depositOption.maximumDepositAmount
        : '';

      updatePGoldState(inforceState, inforcePolicy);
      updatePmaxSbState(inforceState, inforcePolicy, depositOption);

      inforceState.udmIllustration = { ...inforceState.illustration };
      return inforceState;
    }

    case actionTypes.CANCEL_REQUEST: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default:
      return state;
  }
}

const updatePGoldState = (inforceState, inforcePolicy) => {
  if (inforcePolicy.product === eProduct.PG) {
    inforceState.illustration.policy = {
      initialModalPremium: inforcePolicy.initialModalPremium,
      maximumAdditionalDepositAmt: inforcePolicy.maximumAdditionalDepositAmt,
      annualizedInitialModalPremium: inforcePolicy.annualizedInitialModalPremium,
    };
  }
};

const updatePmaxSbState = (inforceState, inforcePolicy, depositOption) => {
  if ([eProduct.Performax, eProduct.SB].includes(inforcePolicy.product)) {
    inforceState.illustration.policy = {
      initialModalPremium: inforcePolicy.coverage[0].minimumModalPremium,
      maximumAdditionalDepositAmt: inforcePolicy.maximumAdditionalDepositAmt,
      loanBalance: inforcePolicy.loanBalance,
      annualizedInitialModalPremium: inforcePolicy.annualizedInitialModalPremium,
    };
    inforceState.illustration.depositOption.lastDepositYear = depositOption.depositYear;
    inforceState.illustration.policy.tax = {};
    inforceState.illustration.policy.tax = {
      grandfatheredStatus: inforcePolicy.tax?.grandfatheredStatus,
      grandfatherStatus: inforcePolicy.tax?.status,
    };
  }
};
