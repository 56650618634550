import React from 'react';
import PropTypes from 'prop-types';
import DepositOption from '../../core/components/deposit-option';
import Withdrawals from '../withdrawal';
import PGoldWithdrawal from '../withdrawal/PGoldWithdrawal';
import Deposits from '../deposit';
import PremiumOffset from '../../core/components/premium-offset';
import { ProductConfig } from '../product-config';
import { eProduct } from '../../core/configs';

const renderPGoldSections = (product) => {
  return (
    <>
      {ProductConfig[product.product].paymentSections.additionPayment && <DepositOption product={product.product} />}
      {ProductConfig[product.product].paymentSections.withdrawals && <PGoldWithdrawal product={product.product} />}
    </>
  );
};
export const PaymentTab = function (product) {
  return (
    <div className="p-grid p-grid-no-margin payment-wrapper flexdisplay">
      {ProductConfig[product.product].paymentSections && (
        <React.Fragment>
          <React.Fragment>
            {ProductConfig[product.product].paymentSections.depositOption && (
              <DepositOption product={product.product} />
            )}
            {ProductConfig[product.product].paymentSections.deposits && <Deposits />}
            {ProductConfig[product.product].paymentSections.premiumOffset && (
              <PremiumOffset product={product.product} />
            )}
            {[eProduct.PG, eProduct.Performax, eProduct.SB].includes(product.product) && renderPGoldSections(product)}
            {product.product !== eProduct.PG && ProductConfig[product.product].paymentSections.withdrawals && (
              <Withdrawals product={product.product} />
            )}
          </React.Fragment>
        </React.Fragment>
      )}
    </div>
  );
};

export default PaymentTab;

PaymentTab.propTypes = {
  product: PropTypes.string,
};
