import * as actionTypes from '../../core/actionTypes';
import {
  eDurationType,
  eWithdrawalType,
  eField,
  eCoverageType,
  eDefault,
  eProduct,
  eBusinessType,
} from '../../core/configs';
import { assignDef, getClientAgeFromUdm, getPolicyYear } from '../../core/utils';
import _ from 'lodash';
import {
  getAgeFromValue,
  getAgeToValue,
  getYearFromValue,
  getYearToValue,
  getPGYearFromToValue,
} from './duration-logic';
import { scenarioSeq } from '../../core/sequenceUtils';

const defaultState = {
  [eField.duration]: eDurationType.age,
  [eField.durationFrom]: '',
  [eField.durationTo]: '',
  [eField.withdrawalTypeOption]: eWithdrawalType.none,
  [eField.withdrawalAmountOption]: eDefault[eField.withdrawalAmountOption],
  [eField.basedonClient]: 'Client.1',
  isFolded: false,
  [eField.withdrawalCustomAmounts]: [],
};

const initialState = {
  activeTabId: scenarioSeq(true),
  [scenarioSeq(true)]: {
    ...defaultState,
  },
  inforce: false,
};

const setMulProductDuration = (udmPolicy, illustration, state, scenarioTabId) => {
  if (udmPolicy.depositOption.durationType === eDurationType.age) {
    let clientAge = 0;
    if (illustration && illustration.party) {
      clientAge = illustration.party.find((party) => party.partyId === state[scenarioTabId][eField.basedonClient]).age;
    }

    return udmPolicy.depositOption.depositAge - clientAge;
  } else {
    return udmPolicy.depositDuration || udmPolicy.duration;
  }
};

const getLowestAge = (inforceCoverages) => {
  const baseCoverage = inforceCoverages.filter(
    (cvg) => cvg.indicatorCode === eField.indicatorCode.base && cvg.status === eBusinessType.IF
  );
  let lowestAge = baseCoverage[0].equivalentAge;
  baseCoverage.forEach((coverage) => {
    if (coverage.equivalentAge < lowestAge) {
      lowestAge = coverage.equivalentAge;
    }
  });
  return lowestAge;
};

const setDurationFromTo = (udmPolicy, duration, state, scenarioTabId) => {
  const activeUDMCoverage = udmPolicy.coverage[0];
  const coiType =
    udmPolicy.product === eProduct.MUL ? activeUDMCoverage.costOfInsurance.type : activeUDMCoverage.costOfInsurance;

  const durationFrom =
    udmPolicy.product === eProduct.PG
      ? getPGYearFromToValue(
          udmPolicy.product,
          getPolicyYear(udmPolicy.effectiveDate),
          getLowestAge(udmPolicy.coverage),
          state[scenarioTabId][eField.durationFrom]
        )
      : getYearFromValue(udmPolicy.product, coiType, duration, state[scenarioTabId][eField.durationFrom]);

  const durationTo =
    udmPolicy.product === eProduct.PG
      ? getPGYearFromToValue(udmPolicy.product, durationFrom, getLowestAge(udmPolicy.coverage), state[scenarioTabId][eField.durationTo])
      : getYearToValue(duration, state[scenarioTabId][eField.durationTo], durationFrom);

  return { durationFrom, durationTo };
};

const getUDMCoverageFromPolicy = (udmPolicy) => {
  let activeUDMCoverage = udmPolicy.coverage[0];
  if (udmPolicy.businessType === eBusinessType.IF) {
    activeUDMCoverage = udmPolicy.coverage.find(
      (cov) => cov.indicatorCode === eField.indicatorCode.base && cov.status === eBusinessType.IF
    );
  }
  return activeUDMCoverage;
};
const getParMulPGProductState = function (state, scenarioTabId, udmPolicy, illustration) {
  if (state[scenarioTabId][eField.duration] === eDurationType.age) {
    const activeUDMCoverage = getUDMCoverageFromPolicy(udmPolicy);
    const equivalentAge = activeUDMCoverage.equivalentAge;
    const clientAge = getClientAgeFromUdm(illustration, state, scenarioTabId);

    const durationFrom = getAgeFromValue(
      udmPolicy.product,
      udmPolicy.product === eProduct.MUL ? activeUDMCoverage.costOfInsurance.type : activeUDMCoverage.costOfInsurance,
      activeUDMCoverage.coverageType,
      equivalentAge,
      clientAge,
      state[scenarioTabId][eField.durationFrom]
    );
    const durationTo = getAgeToValue(
      activeUDMCoverage.coverageType,
      equivalentAge,
      clientAge,
      state[scenarioTabId][eField.durationTo],
      durationFrom
    );

    return {
      ...state,
      [scenarioTabId]: {
        ...state[scenarioTabId],
        [eField.durationFrom]: durationFrom,
        [eField.durationTo]: durationTo,
      },
    };
  } else {
    if (state[scenarioTabId][eField.duration] === eDurationType.year) {
      let duration;
      if (udmPolicy.product === eProduct.MUL) {
        duration = setMulProductDuration(udmPolicy, illustration, state, scenarioTabId);
      } else {
        duration = udmPolicy.duration;
      }
      const { durationFrom, durationTo } = setDurationFromTo(udmPolicy, duration, state, scenarioTabId);
      return {
        ...state,
        [scenarioTabId]: {
          ...state[scenarioTabId],

          [eField.durationFrom]: durationFrom,
          [eField.durationTo]: durationTo,
        },
      };
    }
  }
  return state;
};

const withdrawalReducer = (state, action) => {
  state = state === undefined ? initialState : state;
  switch (action.type) {
    case actionTypes.COMMON_INITIALIZE: {
      return { ...initialState };
    }
    case actionTypes.OPEN_SAVEDCASE: {
      const { scenarioTabNavs, withdrawals } = action.payload.model;
      // This logic to assign all the missing propertied
      // from the initial/default to the saved old object with default value.
      const targetState = withdrawals;
      assignDef(targetState, initialState);
      scenarioTabNavs.tabNavs.forEach((Scenario) => {
        assignDef(targetState[Scenario.id], defaultState);
      });
      return {
        ...targetState,
      };
    }
    case actionTypes.MWI_ON_CHANGE: {
      const { target, scenarioTabId, field, value } = action.payload;

      if (target === 'withdrawal') {
        return {
          ...state,
          [scenarioTabId]: {
            ...state[scenarioTabId],
            [field]: value,
          },
        };
      }

      return state;
    }
    case actionTypes.COVERAGE_TYPE: {
      const { oldValue, value, scenarioTabId, clients } = action.payload;
      if (oldValue !== value && value === eCoverageType.single) {
        return {
          ...state,
          [scenarioTabId]: {
            ...state[scenarioTabId],
            [eField.basedonClient]: clients[0],
          },
        };
      }
      return state;
    }

    default:
      return withdrawalReducer2(state, action);
  }
};

const checkMulDurationProduct = (udmPolicy, state, scenarioTabId) => {
  const checkParOrMulProduct =
    udmPolicy.product === eProduct.PAR || udmPolicy.product === eProduct.MUL || udmPolicy.product === eProduct.PG;
  const checkDuration = state[scenarioTabId][eField.durationFrom] || state[scenarioTabId][eField.durationTo];
  return { checkParOrMulProduct, checkDuration };
};

const withdrawalReducer2 = (state, action) => {
  switch (action.type) {
    //Check and build logic for the value in case it changed without opening the component
    //This logic should apply only if the product is PAR &
    //there is a change in one of the fields that are required to do the UI Validation/Rules
    case actionTypes.LOADED_UDM_RESPONSE: {
      const { response, scenarioTabId } = action.payload;
      const { illustration } = response;
      const udmPolicy = response && response.illustration && response.illustration.policy;

      if (!udmPolicy || !udmPolicy.coverage) {
        return state;
      }

      //Calc logic
      const { checkParOrMulProduct, checkDuration } = checkMulDurationProduct(udmPolicy, state, scenarioTabId);
      if (checkParOrMulProduct && checkDuration) {
        return getParMulPGProductState(state, scenarioTabId, udmPolicy, illustration);
      }
      return state;
    }
    case actionTypes.ADD_SCENARIO_TABNAVS: {
      return {
        ...state,
        activeTabId: action.payload.newTabId,
        [action.payload.newTabId]: { ...state[action.payload.currentTabId] },
      };
    }
    case actionTypes.CLICK_SCENARIO_TABNAVS: {
      return {
        ...state,
        activeTabId: action.payload,
      };
    }

    default:
      return withdrawalReducer3(state, action);
  }
};

const withdrawalReducer3 = (state, action) => {
  switch (action.type) {
    case actionTypes.WTH_DURATION_CHANGE: {
      const { valueFrom, valueTo, type, activeScenarioTabId } = action.payload;

      return {
        ...state,
        [activeScenarioTabId]: {
          ...state[activeScenarioTabId],
          [eField.duration]: type,
          [eField.durationFrom]: valueFrom,
          [eField.durationTo]: valueTo,
        },
      };
    }
    case actionTypes.WTH_CLIENT_CHANGE: {
      const { clientValueFrom, clientValueTo, clientId, clientActiveScenarioTabId } = action.payload;

      return {
        ...state,
        [clientActiveScenarioTabId]: {
          ...state[clientActiveScenarioTabId],
          [eField.basedonClient]: clientId,
          [eField.durationFrom]: clientValueFrom,
          [eField.durationTo]: clientValueTo,
        },
      };
    }
    case actionTypes.WTH_DEFAULT_DURATION: {
      const { defaultValueFrom, defaultValueTo, defaultActiveScenarioTabId } = action.payload;

      return {
        ...state,
        [defaultActiveScenarioTabId]: {
          ...state[defaultActiveScenarioTabId],
          [eField.durationFrom]: defaultValueFrom,
          [eField.durationTo]: defaultValueTo,
        },
      };
    }

    case actionTypes.REMOVE_SCENARIO_TABNAVS: {
      const newState = _.omit(state, [action.payload.id]);

      return {
        ...newState,
        activeTabId: action.payload.newActiveTabId,
      };
    }

    case actionTypes.LOADED_INFORCE_POLICY: {
      const policy = action.payload.policyData.illustration.policy;
      const policyYear = getPolicyYear(policy.effectiveDate);
      if ([eProduct.PG, eProduct.Performax, eProduct.SB].includes(policy.product)) {
        return {
          ...state,
          [state.activeTabId]: {
            ...state[state.activeTabId],
            durationPartyId: policy.ownerId,
            [eField.duration]: eDurationType.year,
            [eField.durationFrom]: policyYear,
            [eField.durationTo]: policyYear,
            [eField.withdrawalAmountOption]:
              [eProduct.Performax, eProduct.SB].includes(policy.product)
                ? eDefault[eField.withdrawalAmountOptionPMAX]
                : defaultState[eField.withdrawalAmountOption],
          },
          inforce: true,
        };
      } else {
        return {
          ...state,
          [state.activeTabId]: {
            ...state[state.activeTabId],
            durationPartyId: policy.ownerId,
          },
          inforce: true,
        };
      }
    }

    default:
      return state;
  }
};

export default withdrawalReducer;
