import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactModal from 'react-modal';
import * as Actions from './actions';
import * as ScenarioActions from '../scenario-tab-navs/actions';
import DeleteDialog from '../../core/components/delete-dialog';
import { eField, isSpreadsheetView, eTabName } from '../../core/configs';
import _ from 'lodash';
import './scenario-tab-navs.css';
import ScenarioNameModal from '../../core/components/change-modals/scenario-name-modal';
import { scenarioSeq } from '../../core/sequenceUtils';
import { trackActiveTab, getDataLayerProductInfoFromState } from '../../core/utils';

const showTopBarScenarioName = function (index, topBars, tab, intl) {
  return (
    <div className={`tab-title ${this.props.tabNavs.length !== 1 && index !== 0 ? 'p-col-8' : 'p-col-10'}`}>
      {topBars[tab.id].scenarioName
        ? this.scenarioNameShortened(topBars, tab.id)
        : `${intl.formatMessage({ id: this.commonScenarioTransId })} ${index + 1}`}
    </div>
  );
};

const getEditDeleteScenario = function (tab, activeTabId, index, topBars, intl) {
  return (
    <div
      className="p-grid p-grid-no-margin tabh-content"
      role="tab"
      aria-selected={tab.id === activeTabId ? 'true' : 'false'}
      id={`${index === 0 ? 'main-content-clientCoverage' : `scenario${index}`}`}
      tabIndex={0}
      ref={tab.id === activeTabId ? (el) => (this.lastTab = el) : undefined}
      onClick={() => this.clickScenario(tab.id)}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          this.clickScenario(tab.id);
        }
      }}
    >
      {showTopBarScenarioName.call(this, index, topBars, tab, intl)}
      <div
        className="p-col-2 edit"
        onClick={(e) => this.editScenario(e, `${tab.id}`)}
        onKeyPress={(e) => this.keyPress(e, this.editScenario, `${tab.id}`)}
        tabIndex={0}
        role="button"
        aria-label={`${intl.formatMessage({ id: 'common.edit' })} ${intl.formatMessage({
          id: this.commonScenarioTransId,
        })} ${index + 1}`}
      >
        <i className="material-icons tab-icon">edit</i>
      </div>

      {this.props.tabNavs.length !== 1 && index !== 0 && (
        <div
          className="p-col-2 delete"
          onClick={(e) => this.removeScenario(e, `${tab.id}`)}
          onKeyPress={(e) => this.keyPress(e, this.removeScenario, `${tab.id}`)}
          tabIndex={0}
          role="button"
          aria-label={`${intl.formatMessage({ id: 'common.remove' })} ${intl.formatMessage({
            id: this.commonScenarioTransId,
          })} ${index + 1}`}
        >
          <i className="material-icons tab-icon">delete</i>
        </div>
      )}
    </div>
  );
};

export class ScenarioTabNavs extends PureComponent {
  static propTypes = {
    tabNavs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      })
    ).isRequired,
    topBars: PropTypes.object,
    activeTabId: PropTypes.string.isRequired,
    actions: PropTypes.object,
    sceActions: PropTypes.object,
    spreadsheetRef: PropTypes.object,
    locale: PropTypes.string,
    location: PropTypes.object,
    pathname: PropTypes.string,
    intl: PropTypes.object,
    isSpreadsheetFullScreen: PropTypes.bool,
    productInfo: PropTypes.object,
    activeCoverageTab: PropTypes.number,
    activeScenario: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      showScnDel: false,
      scenarioId: '',
      title: '',
      updateFocus: false,
      showScenarioName: false,
    };

    this.hidemyScnDelete = this.hidemyScnDelete.bind(this);
    this.deleteScenario = this.deleteScenario.bind(this);
    this.closeScenarioNameModal = this.closeScenarioNameModal.bind(this);

    this.lastTab = React.createRef();

    this.commonScenarioTransId = 'common.scenario';
  }

  componentDidMount = () => {
    this.trackTabInDataLayer();
  };

  componentDidUpdate = () => {
    if (typeof this.lastTab.focus === 'function' && this.state.updateFocus) {
      this.lastTab.focus();
      this.setState({ updateFocus: false });
    }
  };

  trackTabInDataLayer = function () {
    trackActiveTab(this.props.activeTabId, eTabName[this.props.activeCoverageTab], this.props.productInfo);
  };

  addScenario = async () => {
    // add scenario to through the reducer
    this.props.actions.addScenarioTabNavs({
      newTabId: scenarioSeq(false, this.props.tabNavs),
      currentTabId: this.props.activeTabId,
    });
    // Save model and udm
    await this.props.sceActions.toggleOutOfDate(true);
    this.setState({ updateFocus: true });
    this.trackTabInDataLayer();
  };

  editScenario = (e, scenarioId) => {
    const { intl, topBars, tabNavs } = this.props;

    const tabIndex = topBars[scenarioId][eField.scenarioName]
      ? 0
      : tabNavs.findIndex(function (o) {
          return o.id === scenarioId;
        });
    const title =
      topBars[scenarioId].scenarioName || `${intl.formatMessage({ id: this.commonScenarioTransId })} ${tabIndex + 1}`;

    this.setState({ showScenarioName: true, scenarioId, title });
  };

  removeScenario = (e, scenarioId) => {
    const { intl, topBars, tabNavs } = this.props;

    e.stopPropagation();
    //Get the index of the deleted tab
    const tabIndex = topBars[scenarioId][eField.scenarioName]
      ? 0
      : tabNavs.findIndex(function (o) {
          return o.id === scenarioId;
        });
    const title =
      topBars[scenarioId].scenarioName || `${intl.formatMessage({ id: this.commonScenarioTransId })} ${tabIndex + 1}`;

    this.setState({ showScnDel: true, scenarioId, title });
  };

  async deleteScenario() {
    // get all the tabs and the current tab
    const { tabNavs, activeTabId } = this.props;
    // use the lodash library reject method to return a new array of objects without the specified scenarioId
    const newTabNavs = _.reject(tabNavs, { id: this.state.scenarioId });
    // set the new active tab Id based off remaining navtabs and current active navTab
    const newActiveTabId =
      newTabNavs.length === 1 || this.state.scenarioId === activeTabId ? newTabNavs[0].id : activeTabId;
    // pass the new tab through the reducer to update the global state
    await this.props.actions.removeSenarioTabNavs({ id: this.state.scenarioId, newActiveTabId });
    this.hidemyScnDelete();
    // save the updated model
    await this.props.sceActions.toggleOutOfDate(true);
    this.setState({ updateFocus: true });
    this.trackTabInDataLayer();
  }

  clickScenario = async (scenarioId) => {
    await this.props.actions.clickScenarioTabNavs(scenarioId);
    await this.props.sceActions.toggleOutOfDate(true);
    trackActiveTab(scenarioId, eTabName[this.props.activeCoverageTab], this.props.productInfo);
  };

  hidemyScnDelete() {
    this.setState({ showScnDel: false });
    //Fix iPad focus lost
    // setTimeout(this.setRaitingBtnFocus, 100, this.RaitingBtnRef);
  }
  keyPress(event, func, ...params) {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      func(event, ...params);
    }
  }

  closeScenarioNameModal() {
    this.setState({ showScenarioName: false });
    this.props.sceActions.toggleOutOfDate(true);
  }

  scenarioNameShortened = (topBars, tabId) => {
    const { scenarioName } = topBars[tabId];
    const startSubStringScenarioName = 0;
    const endSubStringScenarioName = 9;
    return scenarioName.length > endSubStringScenarioName
      ? scenarioName.substring(startSubStringScenarioName, endSubStringScenarioName) + '...'
      : scenarioName;
  };

  render() {
    const { intl, locale, topBars, activeTabId, isSpreadsheetFullScreen } = this.props;
    const tabNavLength = 4;
    return (
      <div
        className={`tab-nav ${
          isSpreadsheetView(this.props.location.pathname) ? 'scenarioTab-wrapper-nopadding' : 'scenarioTab-wrapper'
        } ${isSpreadsheetFullScreen ? 'scenarioTab-fullscreen' : ''}`}
      >
        <div className="p-grid p-grid-no-margin tab-section" role="tablist">
          {this.props.tabNavs.map((tab, index) => (
            <div
              key={tab.id}
              className={`p-col-2 tabh focusOutline ${tab.id === activeTabId ? 'active' : ''}`}
              role={tab.id === activeTabId ? 'heading' : ''}
              aria-level="1"
            >
              {getEditDeleteScenario.call(this, tab, activeTabId, index, topBars, intl)}
            </div>
          ))}

          {this.props.tabNavs.length !== tabNavLength && (
            <div className="add scenario-add">
              {/* eslint-disable-next-line */}
              <a
                className="add-scenario-link"
                onClick={() => this.addScenario()}
                onKeyPress={(e) => this.keyPress(e, this.addScenario)}
                tabIndex={0}
                aria-label={intl.formatMessage({ id: 'common.addScenario' })}
                role="button"
              >
                {intl.formatMessage({ id: 'common.addScenario' })}
              </a>
            </div>
          )}

          <ReactModal
            className={`delete-dialog-wrapper ${locale}`}
            contentLabel={`${intl.formatMessage({ id: 'common.delete' })} ${this.state.title}`}
            isOpen={this.state.showScnDel}
            shouldCloseOnOverlayClick={false}
            onRequestClose={this.hidemyScnDelete}
            role="dialog"
          >
            <DeleteDialog
              title={`${intl.formatMessage({ id: 'common.delete' })} ${this.state.title}`}
              message={<FormattedMessage id="scenario.deletemsg" />}
              delete={this.deleteScenario}
              cancel={this.hidemyScnDelete}
              locale={locale}
            />
          </ReactModal>
          <ReactModal
            className={`change-product-wrapper ${locale} not-duplicate}`}
            isOpen={this.state.showScenarioName}
            shouldCloseOnOverlayClick={false}
            shouldFocusAfterRender
            shouldReturnFocusAfterClose
            onRequestClose={this.closeScenarioNameModal}
            role="dialog"
            aria={{
              modal: 'true',
            }}
            ariaHideApp
          >
            <ScenarioNameModal
              title={`${intl.formatMessage({ id: 'scenario.scenarioname' })}`}
              intl={intl}
              closeScenarioNameModal={this.closeScenarioNameModal}
            />
          </ReactModal>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = ({ app, scenarioTabNavs, coverageTabNavs, vitalityStatus }) => {
  const scenarioTabId = scenarioTabNavs.activeTabId;
  const activeScenario = scenarioTabNavs.topBars[scenarioTabNavs.activeTabId];
  const product = activeScenario.product;
  const coverageState = coverageTabNavs[scenarioTabNavs.activeTabId];
  const coverage = coverageState[coverageState.activeTabId];
  const stateProductInfo = getDataLayerProductInfoFromState(
    coverage,
    vitalityStatus,
    product,
    app.inforcePolicy,
    activeScenario
  );

  return {
    locale: app.locale,
    tabNavs: scenarioTabNavs.tabNavs,
    topBars: scenarioTabNavs.topBars,
    activeTabId: scenarioTabId,
    productInfo: stateProductInfo,
    activeCoverageTab: activeScenario.activeTabViewTab,
    // isSpreadsheetView: spreadsheet[scenarioTabId].isOpen,
    // spreadsheetRef: spreadsheet[scenarioTabId].dataTableRef
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ScenarioTabNavs)));
