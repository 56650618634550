import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Client from '../../core/components/client';
import ClientSelection from '../../core/components/client-selection';
import RatingsButton from '../../core/components/ratings-button';
import { eGoodRatings, eHS, eProduct, eUserType, eSmokingStatus, eCoverageType } from '../../core/configs';
import { screenValidator, getClientOptions } from '../../core/utils';
import * as ScenarioActions from '../scenario-tab-navs/actions';
import * as CoverageActions from '../coverage-tab-navs/actions';
import { FormattedMessage } from 'react-intl';
import * as ClientActions from '../../services/reducers/components/client/actions';
import _ from 'lodash';

const getRatingsButton = function (client, ratings, coverage, locale, inforce, product) {
  return (
    <RatingsButton
      onSave={(ratingsOnSave) => this.saveRatings({ ratings: ratingsOnSave, clientId: client.id })}
      disabled={this.props.userType === eUserType.advisor && this.isRatingsDisabled([client])}
      partyId={client.id}
      ratings={ratings || coverage.ratings}
      locale={locale}
      inforce={inforce}
      containerStyle="ratings"
      buttonStyle="button-width"
      product={product}
    />
  );
};

export class CoverageClient extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    inforce: PropTypes.bool,
    scenarioTabId: PropTypes.string,
    udmEnabled: PropTypes.bool,
    coverage: PropTypes.object,
    lifeExpectancyAssumption: PropTypes.object,
    clientOptions: PropTypes.array.isRequired,
    showClientIcon: PropTypes.bool,
    showClientLabel: PropTypes.bool,
    showClientSelect: PropTypes.bool,
    showMultiClients: PropTypes.bool,
    includeAllClients: PropTypes.bool,
    selectedClient: PropTypes.string,
    showClientRatings: PropTypes.bool,
    showJointRatings: PropTypes.bool,
    uniqueId: PropTypes.string,
    uniqueCoverageId: PropTypes.string,

    activeCoverageId: PropTypes.string,
    product: PropTypes.string,
    saveRatings: PropTypes.func,
    resetRatings: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    userType: PropTypes.string,
    ratings: PropTypes.object,

    // appActions: PropTypes.object,
    coverageActions: PropTypes.object,
    clientActions: PropTypes.object,
    sceActions: PropTypes.object,

    // client1 for single type, client 2 for combined and joint type
    client1: PropTypes.object,
    client2: PropTypes.object,
    clients: PropTypes.object,

    // For Inforce Use
    inforceRider: PropTypes.object,
    coverages: PropTypes.object,
    isTir: PropTypes.bool,
  };

  static defaultProps = {
    clients: {},
    clientOptions: [],
    showClientIcon: true,
    showClientLabel: false,
    showClientSelect: false,
    showMultiClients: false,
    includeAllClients: false,
    selectedClient: 'Client.1',
    isTir: false,
  };

  constructor(props) {
    super(props);

    this.onHealthstyleChange = this.onHealthstyleChange.bind(this);
  }

  // TODO in multiple coverages, it should be dealt with in coverage-tab reducer with like a flag 'disabledRating'???
  isRatingsDisabled(clients) {
    const isTermInforce = this.props.inforce && [eProduct.BT, eProduct.FT].includes(this.props.product);
    const disabledRatings = clients.filter((client) => client && eGoodRatings.indexOf(client.healthstyle) >= 0);
    return !isTermInforce && disabledRatings.length === clients.length;
  }

  // change ratings for all coverages cuz a client's HS is same in all coverages
  onHealthstyleChange(thisClient, healthstyle) {
    const otherClient = thisClient.id === this.props.client1.id ? this.props.client2 : this.props.client1;

    this.props.coverageActions.resetRatings({
      scenarioTabId: this.props.scenarioTabId,
      thisClient,
      healthstyle,
      otherClient,
    });
  }

  // save ratings for the coverage
  saveRatings({ ratings, clientId }) {
    //OVRIDE THE LOCAL SAVERATITING ON CASE SAVE PASS FROM IOC COMPONENT
    if (this.props.saveRatings) {
      this.props.saveRatings({ ratings, clientId });
    } else {
      this.props.coverageActions.saveRatings({
        scenarioTabId: this.props.scenarioTabId,
        coverageTabId: this.props.coverage.id,
        clientId,
        ratings,
      });
      this.props.sceActions.toggleOutOfDate(true);
    }
  }

  displayInforceClientHeader(showClientIcon, showClientLabel) {
    return (
      <div className="p-col-10 client insured" role="container-clientselection">
        <div className="p-col-8 p-lg-8 p-sm-10 content">
          <span className="mwi-label label-group" role="heading" aria-level="3">
            {showClientIcon && (
              <i className="material-icons" aria-hidden="true" data-testid="material-icons">
                account_circle
              </i>
            )}
            {showClientLabel && (
              <label className="label" id="client-insured" data-testid="label-clientinsured">
                <FormattedMessage id="common.client" />
              </label>
            )}
          </span>
        </div>
      </div>
    );
  }

  shouldUpdateClientAge(inforce, inforceRider, client, isTermInforce, coverages, uniqueCoverageId) {
    // If the Inforce rider's client ID matches the current client,
    // update their age to match the rider.
    if (inforce && inforceRider && inforceRider.clientId === client.id) {
      return inforceRider.age;
    }

    if (isTermInforce) {
      // Term Inforce - if coverage is joint client HS and age is used from Poli N else from Poco
      // For term combined coverages, IDS is sending poco age and HS in lives object already
      const currentCoverage = coverages[uniqueCoverageId];
      const currentClient = coverages[uniqueCoverageId].life.find((life) => life.partyId === client.partyId);
      return [eCoverageType.single].includes(currentCoverage?.coverageType)
        ? currentCoverage?.equivalentAge
        : currentClient?.age;
    }

    return client.age;
  }

  shouldUpdateClientHealthStyle(product, isTir, inforceRider, client, isTermInforce, coverages, uniqueCoverageId) {
    if (product === eProduct.PG && isTir && inforceRider && inforceRider.clientId === client.id) {
      return eHS[inforceRider.healthstyle.toLowerCase()];
    }

    if (isTermInforce) {
      // Term Inforce - if coverage is joint client HS and age is used from Poli N else from Poco
      // For term combined coverages, IDS is sending poco age and HS in lives object already
      //Term inforce policy can have either HS or smoking status
      const currentCoverage = coverages[uniqueCoverageId];
      const currentClient = coverages[uniqueCoverageId].life.find((life) => life.partyId === client.partyId);
      if ([eCoverageType.single].includes(currentCoverage?.coverageType)) {
        let coverageHS = eHS[currentCoverage.healthstyle?.toLowerCase()];
        return coverageHS || eSmokingStatus[currentCoverage?.healthstyle.toLowerCase()];
      } else {
        let clientHS = eHS[currentClient.healthstyle?.toLowerCase()];
        return clientHS || eSmokingStatus[currentClient.healthstyle?.toLowerCase()];
      }
    }

    return client.healthstyle;
  }

  getRatingsForTermInforce(coverages, uniqueCoverageId, ratingsToUse) {
    const currentCoverage = coverages[uniqueCoverageId];
    if (currentCoverage && ![eCoverageType.single, eCoverageType.combined].includes(currentCoverage.coverageType)) {
      return currentCoverage.ratings;
    }
    return ratingsToUse;
  }

  getAllClients = (coverages, clients, client1, client2, showMultiClients, product, inforce, uniqueCoverageId) => {
    let allClients;
    if (showMultiClients) {
      const clientsIdList = [];
      coverages?.tabNavs?.map((id) => {
        _.keys(coverages[id].ratings).map((clientId) => {
          if (!clientsIdList.includes(clientId)) {
            clientsIdList.push(clientId);
          }
        });
      });

      const coverageToUse = coverages[uniqueCoverageId];
      const isTermInforce = [eProduct.BT, eProduct.FT].includes(product) && inforce;
      const isTermInforcedCombined = isTermInforce && coverageToUse.coverageType === eCoverageType.combined;
      const altClient1 = isTermInforcedCombined ? clients[coverageToUse?.life[0]?.partyId] : clients[clientsIdList[0]];
      const altClient2 = isTermInforcedCombined ? clients[coverageToUse?.life[1]?.partyId] : clients[clientsIdList[1]];

      allClients =
        [eProduct.PG, eProduct.Performax, eProduct.SB].includes(product) || isTermInforce
          ? [altClient1 && altClient1.id, altClient2 && altClient2.id]
          : [client1 && client1.id, client2 && client2.id];
    } else {
      allClients = clients.allClients;
    }

    return allClients;
  };

  get insured() {
    const {
      clients,
      client1,
      client2,
      product,
      coverage,
      coverages,
      scenarioTabId,
      showClientIcon,
      showClientLabel,
      showClientSelect,
      showMultiClients,
      includeAllClients,
      selectedClient,
      showClientRatings,
      uniqueId,
      uniqueCoverageId,
      onChange,
      udmEnabled,
      locale,
      inforce,
      inforceRider,
      ratings,
      isTir,
    } = this.props;
    const allClients = this.getAllClients(coverages, clients, client1, client2, showMultiClients, product, inforce, uniqueCoverageId);
    let ratingsToUse = isTir ? ratings : coverage.ratings;
    const isTermInforce = inforce && [eProduct.BT, eProduct.FT].includes(product);

    return allClients.map((id, index) => {
      const client = { ...clients[id] };
      let clientDiv = <div />;
      if (client && (showMultiClients || client.id === selectedClient)) {
        const clientOptions = getClientOptions(clients, includeAllClients, client, coverage);

        client.age = this.shouldUpdateClientAge(
          inforce,
          inforceRider,
          client,
          isTermInforce,
          coverages,
          uniqueCoverageId
        );
        client.healthstyle = this.shouldUpdateClientHealthStyle(
          product,
          isTir,
          inforceRider,
          client,
          isTermInforce,
          coverages,
          uniqueCoverageId
        );

        if (isTermInforce) {
          client.smokingstatus = client.healthstyle; //Term inforce policy can have either HS or smoking status
          ratingsToUse = coverages[uniqueCoverageId]?.ratings;
        }

        clientDiv = client.id && (
          <React.Fragment key={client.id}>
            {this.addClientSpacer(showMultiClients, allClients, index)}
            {!this.props.inforce && (
              <ClientSelection
                clientIdInCov={client.id}
                clientOptions={clientOptions}
                index={index}
                uniqueId={`${uniqueId}.${index}`}
                showClientIcon={showClientIcon}
                showClientLabel={showClientLabel}
                showClientSelect={showClientSelect}
                udmEnabled={udmEnabled}
                onChange={onChange}
              />
            )}

            {this.props.inforce && this.displayInforceClientHeader(showClientIcon, showClientLabel)}
            <Client
              client={client}
              index={index}
              uniqueId={`${uniqueId}.${index}`}
              scenarioTabId={scenarioTabId}
              coverageTabId={coverage.id}
              coverageOption={coverage.coverageOption}
              onHealthstyleChange={this.onHealthstyleChange}
              coverageEffectiveDate={coverages[uniqueCoverageId]?.effectiveDate}
            >
              {(screenValidator(product)?.ratingsAvailable &&
                showClientRatings &&
                getRatingsButton.call(this, client, ratingsToUse, coverages[uniqueCoverageId], locale, inforce, product)) || (
                <React.Fragment />
              )}
            </Client>
          </React.Fragment>
        );
      }

      return clientDiv;
    });
  }

  addClientSpacer(showMultiClients, allClients, index) {
    if (showMultiClients && allClients.length > 1 && index > 0) {
      return (
        <div className="p-col-12">
          <hr className="splitter client-splitter" />
        </div>
      );
    }
    return <></>;
  }

  render = () => {
    const {
      client1,
      client2,
      userType,
      product,
      coverage,
      ratings,
      locale,
      showJointRatings,
      inforce,
      coverages,
      uniqueCoverageId,
    } = this.props;
    let ratingsToUse = ratings || coverage.ratings;
    const isTermInforce = inforce && [eProduct.BT, eProduct.FT].includes(product);
    if (isTermInforce) {
      ratingsToUse = this.getRatingsForTermInforce(coverages, uniqueCoverageId, ratingsToUse);
    }

    return (
      <div className="p-grid p-grid-no-margin-all coverage-client">
        {screenValidator(product)?.ratingsAvailable && showJointRatings && (
          <div className="p-col-12 no-margin">
            <div className="p-col-10"> &nbsp; </div>
            <RatingsButton
              // For Joint type, two clients' ratings are same so we can take the first client
              onSave={(rating) => this.saveRatings({ ratings: rating, clientId: client1.id })}
              disabled={userType === eUserType.advisor && this.isRatingsDisabled([client1, client2])}
              containerStyle="joint-rating-container"
              buttonStyle={`joint-rating-btn button-width`}
              partyId={client1.id}
              ratings={ratingsToUse}
              locale={locale}
              inforce={inforce}
              product={product}
            />
          </div>
        )}

        {this.insured}
      </div>
    );
  };
}

export const mapStateToProps = ({ app, scenarioTabNavs, coverageTabNavs, clients }) => {
  const scenarioTabId = scenarioTabNavs.activeTabId;
  const coverages = coverageTabNavs[scenarioTabId];
  const coverage = coverages[coverages.activeTabId];
  const clientIdsInCov = _.keys(coverage.ratings);
  const product = scenarioTabNavs.topBars[scenarioTabId].product;

  return {
    locale: app.locale,
    inforce: app.inforcePolicy,
    scenarioTabId: scenarioTabNavs.activeTabId,
    udmEnabled: scenarioTabNavs.udmEnabled,
    activeCoverageId: coverages.activeTabId,
    clients: clients[scenarioTabId],
    client1: clients[scenarioTabId][clientIdsInCov[0]],
    client2: clients[scenarioTabId][clientIdsInCov[1]],
    userType: app.userType,
    coverage,
    coverages,
    product,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // appActions: bindActionCreators(AppActions, dispatch),
  coverageActions: bindActionCreators(CoverageActions, dispatch),
  clientActions: bindActionCreators(ClientActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoverageClient);
