import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import MWIDropdown from '../../core/components/mwi-dropdown';
import MWIInput from '../../core/components/mwi-input';
import NumberFormat from 'react-number-format';
import MWIAccordion from '../../core/components/mwi-accordion-stateless';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  eField,
  eDurationType,
  eLocale,
  eWithdrawalType,
  eWithdrawalAmount,
  eCoverageType,
  eProduct,
  eBusinessType,
  eWithdrawalAmountPMax,
} from '../../core/configs';
import { ageToYear, getClientOptions, getPolicyYear } from '../../core/utils';
import * as AppActions from '../../core/actions';
import * as WithdrawalActions from './actions';
import * as ScenarioActions from '../scenario-tab-navs/actions';
import './withdrawal.css';
import { getYearFromValue, getYearToValue, getPGYearFromToValue } from './duration-logic';
import { handleFocus } from './../../core/utils';
import MWINumberField from '../../core/components/mwi-calculated-number-field';
import { dropDownOptions } from '../../core/dropdown-config';

const getWithdrawAmountOptionField = function (withdrawal, locale, product) {
  return (
    <MWINumberField
      id={eField.withdrawalAmountOption}
      styleClass="p-col-2"
      containerClass="mwi-widget mwi-input number-data mwi-margin-top24 p-sm-6"
      onBlur={this.onBlur}
      value={withdrawal[eField.withdrawalAmountOption]}
      prefix={locale === eLocale.en ? '$ ' : ''}
      suffix={locale === eLocale.fr ? ' $' : ''}
      thousandSeparator={locale === eLocale.en ? ',' : ' '}
      minValue={product === eProduct.PG ? eWithdrawalAmount.min : eWithdrawalAmountPMax.min}
      maxValue={eWithdrawalAmount.max}
      locale={locale}
    />
  );
};

const getWithdrawAmountOptionInput = function (product, intl) {
  return (
    <MWIInput
      containerClass="p-col-2 p-lg-3 p-xl-3 p-sm-6"
      styleClass="mwi-w100"
      name={eField.withdrawalAmountOption}
      labelId={eField.withdrawalAmountOption}
      label={
        product === eProduct.MUL ? (
          <FormattedMessage id="common.withdrawalAmount" />
        ) : (
          <FormattedMessage id="common.amount" />
        )
      }
      placeholder={intl.formatMessage({ id: 'withdrawal.amount.specified' })}
      value={intl.formatMessage({ id: 'withdrawal.amount.specified' })}
      errors=""
      grid="2"
      disabled
    />
  );
};

const getMwiDropdownQuoteOption = function (withdrawal, withdrawalTypeOption) {
  return (
    <MWIDropdown
      name={eField.quoteOption}
      label={<FormattedMessage id="common.type" />}
      value={withdrawal[eField.withdrawalTypeOption]}
      options={withdrawalTypeOption}
      // disabled={product === eProduct.FTV}
      onChange={(e) => this.dispatchAction(eField.withdrawalTypeOption, e.target.value)}
      dropdownStyle="mwi-w100"
      containerStyle="p-col-2 p-lg-3 p-xl-2 p-sm-6"
      role="type-dropdown"
    />
  );
};

const getWithdrawalDurationFromField = function (withdrawal) {
  return (
    <NumberFormat
      className={`mwi-widget mwi-input number-data mwi-w100`}
      value={withdrawal[eField.durationFrom]}
      allowNegative={false}
      decimalSeparator="false"
      // onValueChange={values => (onChange ? onChange(id, values.value) : '')}
      onBlur={(e) => this.onBlur(eField.durationFrom, e.target.value)}
      decimalScale={0}
      fixedDecimalScale={false}
      id={eField.durationFrom}
      autoComplete="off"
      aria-required="true"
      onFocus={handleFocus}
    />
  );
};

const getWithdrawalDurationToField = function (withdrawal) {
  return (
    <NumberFormat
      className={`mwi-widget mwi-input number-data mwi-w100`}
      value={withdrawal[eField.durationTo]}
      allowNegative={false}
      decimalSeparator="false"
      // onValueChange={values => (onChange ? onChange(id, values.value) : '')}
      onBlur={(e) => this.onBlur(eField.durationTo, e.target.value)}
      decimalScale={0}
      fixedDecimalScale={false}
      id={eField.durationTo}
      autoComplete="off"
      aria-required="true"
      onFocus={handleFocus}
    />
  );
};

const getLowestAge = (inforceCoverages) => {
  const baseCoverage = inforceCoverages.filter(
    (cvg) => cvg.indicatorCode === eField.indicatorCode.base && cvg.status === eBusinessType.IF
  );
  let lowestAge = baseCoverage[0].equivalentAge;
  baseCoverage.forEach((coverage) => {
    if (coverage.equivalentAge < lowestAge) {
      lowestAge = coverage.equivalentAge;
    }
  });
  return lowestAge;
};

export class Withdrawal extends PureComponent {
  static propTypes = {
    locale: PropTypes.string,
    intl: PropTypes.object,
    product: PropTypes.string,
    activeScenarioTabId: PropTypes.string,
    withdrawal: PropTypes.object,
    clients: PropTypes.object,
    coverageType: PropTypes.string,
    duration: PropTypes.number,
    equivalentAge: PropTypes.number,
    clientAge: PropTypes.number,
    isOutOfDate: PropTypes.bool,
    isForceUpdate: PropTypes.bool,
    costOfInsurance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    appActions: PropTypes.object,
    withdrawalActions: PropTypes.object,
    sceActions: PropTypes.object,
    depositOwnerResidence: PropTypes.string,
    inforce: PropTypes.bool,
    policyEffectiveDate: PropTypes.object,
    inforceCoverages: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount() {
    const { withdrawal } = this.props;
    if (!withdrawal[eField.durationFrom] || !withdrawal[eField.durationTo]) {
      this.setDefaultValuesWTH();
    }
    if (this.props.isOutOfDate) {
      this.props.sceActions.performManualRefresh();
      this.props.sceActions.requestUDMResponse();
    }
  }

  componentDidUpdate(prevProps) {
    const { withdrawal } = this.props;

    if (prevProps.withdrawal[eField.basedonClient] !== withdrawal[eField.basedonClient]) {
      this.setDefaultValuesWTH();
    }
  }

  setDefaultValuesWTH() {
    const { withdrawal, activeScenarioTabId, duration, product, costOfInsurance } = this.props;

    if (withdrawal[eField.duration] === eDurationType.year) {
      const fromValue = getYearFromValue(product, costOfInsurance, duration, '');

      this.props.withdrawalActions.setDefaultDuration({
        defaultvalueFrom: fromValue,
        defaultvalueTo: getYearToValue(duration, '', fromValue),
        defaultactiveScenarioTabId: activeScenarioTabId,
      });
    }
  }

  dispatchAction(field, value, isError = false, refresh = true) {
    this.props.appActions[isError ? 'dispatchMWIUpdateError' : 'dispatchMWIOnChange']({
      target: 'withdrawal',
      scenarioTabId: this.props.activeScenarioTabId,
      field,
      value,
    });
    if (refresh) {
      this.props.sceActions.toggleOutOfDate(true);
    }
    this.props.sceActions.performManualRefresh(true);
  }

  onBlur(field, value) {
    //Validation check for Duration From/To Value
    const { withdrawal, policyEffectiveDate, inforceCoverages, product } = this.props;
    const lowestAge = getLowestAge(inforceCoverages);
    if (field === eField.durationFrom) {
      value = getPGYearFromToValue(product, getPolicyYear(policyEffectiveDate), lowestAge, value);
    } else {
      if (field === eField.durationTo) {
        value = getPGYearFromToValue(product, withdrawal.durationStart, lowestAge, value);
      }
    }
    this.dispatchAction(field, value);
  }

  onDurationTypeChange(value) {
    const { withdrawal, clientAge, activeScenarioTabId } = this.props;

    //this mean no changes
    if (withdrawal[eField.duration] === value) {
      return;
    }
    //get the equivalent calculated value from year to age and vice versa
    if (value === eDurationType.year) {
      this.props.withdrawalActions.selectDuration({
        valueFrom: ageToYear(withdrawal[eField.durationFrom], clientAge),
        valueTo: ageToYear(withdrawal[eField.durationTo], clientAge),
        type: eDurationType.year,
        activeScenarioTabId,
      });
    }
    this.props.sceActions.toggleOutOfDate(true);
  }

  render = () => {
    const { locale, intl, withdrawal, clients, coverageType, product, inforce } = this.props;

    const { withdrawalTypeOption } = dropDownOptions(intl);
    const clientOptions = getClientOptions(clients, true);
    const withdrawalVisible = [eProduct.PG, eProduct.Performax, eProduct.SB].includes(product) || !inforce;

    return (
      <div className=" p-col-12  withdrawalContent">
        {withdrawalVisible && (
          <MWIAccordion
            className="mwi-reverse-margin15 mwi-lightGreen "
            title={<FormattedMessage id="withdrawal.title" />}
            folded={withdrawal.isFolded}
            onClickChange={() => this.dispatchAction(eField.isFolded, !withdrawal.isFolded, false, false)}
          >
            <div id="quoteOptionContainer" className="p-col-12 mwi-margin-top24 mwi-margin-bottom24 flexdisplay">
              {getMwiDropdownQuoteOption.call(this, withdrawal, withdrawalTypeOption)}
            </div>
            {withdrawal[eField.withdrawalTypeOption] !== eWithdrawalType.none && (
              <React.Fragment>
                <div id="amountContainer" className="p-col-12 mwi-margin-bottom24 flexdisplay">
                  {getWithdrawAmountOptionInput(product, intl)}
                  {getWithdrawAmountOptionField.call(this, withdrawal, locale, product)}
                </div>
                <div id="durationContainer" className="p-col-12 flexdisplay mwi-margin-bottom24">
                  {[eProduct.PG, eProduct.Performax, eProduct.SB].includes(product) && this.onDurationTypeChange(eDurationType.year)}

                  <div className={`p-col-3 p-lg-2 p-sm-4 space-right`}>
                    <div className="mwi-label">
                      <label htmlFor={eField.durationFrom} aria-required="true">
                        {intl.formatMessage({
                          id: `${eField.yearfrom}`,
                        })}
                      </label>
                    </div>
                    <div>
                      {getWithdrawalDurationFromField.call(this, withdrawal)}
                      {/* <div className="mwi-error-msg">{errors[eField.coverageAmount]}</div> */}
                    </div>
                  </div>

                  <div className={`p-col-3 p-lg-3 p-xl-3 p-sm-4 space-right`}>
                    <div className="mwi-label">
                      <label htmlFor={eField.durationTo} aria-required="true">
                        {intl.formatMessage({
                          id: `${eField.yearto}`,
                        })}
                      </label>
                    </div>
                    <div>
                      {getWithdrawalDurationToField.call(this, withdrawal)}
                      {/* <div className="mwi-error-msg">{errors[eField.coverageAmount]}</div> */}
                    </div>
                  </div>
                </div>

                {coverageType === eCoverageType.jointLastPayLastDeath &&
                  withdrawal[eField.duration] === eDurationType.age && (
                    <div id="basedOnClientContainer" className="p-col-8 flexdisplay mwi-margin-bottom24">
                      <MWIDropdown
                        name={eField.basedonClient}
                        label={<FormattedMessage id="withdrawal.client" />}
                        value={withdrawal[eField.basedonClient]}
                        options={clientOptions}
                        onChange={(e) => this.dispatchAction(eField.basedonClient, e.target.value)}
                        dropdownStyle="mwi-w100"
                        containerStyle=" p-col-6 p-lg-8 p-xl-6"
                        role="basedOnClientContainer-dropdown"
                      />
                    </div>
                  )}
              </React.Fragment>
            )}
          </MWIAccordion>
        )}
      </div>
    );
  };
}

export const mapStateToProps = ({
  app,
  withdrawals,
  scenarioTabNavs,
  clients,
  coverageTabNavs,
  udmResponse,
  deposits,
}) => {
  const activeScenarioTabId = scenarioTabNavs.activeTabId;
  const withdrawal = withdrawals[activeScenarioTabId];
  const coverageState = coverageTabNavs[activeScenarioTabId];
  const { illustration, udmIllustration } = udmResponse;

  const policy = illustration && illustration.policy;
  const duration = policy && +policy.duration;

  const clientAge =
    udmIllustration &&
    udmIllustration.party &&
    udmIllustration.party.find((party) => party.partyId === withdrawal[eField.basedonClient])?.age;

  const equivalentAge = coverageState[coverageState.activeTabId].equivalentAge;

  const depositOwnerResidence = deposits[activeScenarioTabId][eField.depositOwnerResidence];
  const policyEffectiveDate = app.inforceData.policy ? app.inforceData.policy.effectiveDate : '';
  const inforceCoverages = app.inforceData.policy ? app.inforceData.policy.coverage : '';

  return {
    locale: app.locale,
    clients: clients[activeScenarioTabId],
    coverageType: coverageState[coverageState.activeTabId].coverageType,
    costOfInsurance: coverageState[coverageState.activeTabId].costOfInsurance,
    clientAge: +clientAge,
    isOutOfDate: scenarioTabNavs.isOutOfDate,
    isForceUpdate: scenarioTabNavs.isForceUpdate,
    depositOwnerResidence,
    withdrawal,
    duration,
    equivalentAge,
    activeScenarioTabId,
    inforce: app.inforcePolicy,
    policyEffectiveDate,
    inforceCoverages,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  withdrawalActions: bindActionCreators(WithdrawalActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Withdrawal));
