import { eProduct } from '../configs';

const enSavoirPlus = 'https://conseiller.manuvie.ca/conseillers/prive/outils/projets-de-contrats-en-vigueur.html';
const productPageURLFTVFr =
  'https://www.manulife.ca/content/dam/manulife-advisor-portal/documents/fr/forms/insurance/new-business-and-underwriting/manulife-insurance-family-term-with-vitality-product-page-nn1656f.pdf';

const locale = {
  'common.lang.english': 'Anglais',
  'common.lang.french': 'Français',
  'common.save': 'Enregistrer',
  'common.saveAs': 'Enregistrer sous',
  'common.saved': 'Sauvegardé',
  'common.shareIllustration': 'Partager le projet informatisé',
  'common.rateChange': 'Ce projet informatisé a peut-être changé depuis la dernière fois que vous l’avez ouvert.',
  'common.copy': 'Copier',
  'common.copied': 'Copié',
  'common.saving': 'Sauvegarder...',
  'common.newIllustration': 'Nouveau projet informatisé',
  'common.newInforceIllustration': 'Nouveau contrat en vigueur de projet informatisé',
  'common.policyNumber': 'Numero de contrat: ',
  'common.save.error': "Erreur lors de l'enregistrement",
  'common.profile': 'Profil',
  'common.reset': 'Rétablir',
  'common.edit': 'Modifier',
  'common.ok': 'OK',
  'common.cancel': 'Annuler',
  'common.create': 'Créer',
  'common.delete': 'Supprimer',
  'common.days': 'jours',
  'common.scenario': 'Scénario',
  'common.addScenario': 'Ajouter un scénario',
  'common.coverage': 'Couverture',
  'common.coverage.ellipsis': 'Couv.',
  'common.addCoverage': 'Ajouter une couverture',
  'common.male': 'Homme',
  'common.female': 'Femme',
  'common.rated': 'Surprime établie',
  'common.ratings': 'Tarification',
  'common.ratingsSmall': ' tarification',
  'common.ratings.Client1': 'Client 1',
  'common.ratings.Client2': 'Client 2',
  'common.jointTypeRatings': 'Tarification conjointe',
  'common.regularRatings': 'Tarification',
  'common.amount': 'Montant',
  'common.riders': 'Garanties complémentaires',
  'common.termriders': 'Temporaires',
  'common.client': 'Client',
  'common.clients': 'Client(s)',
  'common.manulife.illustration': 'Projets informatisés de Manuvie',
  'common.illustrations': 'Projets informatisés',
  'common.illustration': 'Projets informatisés',
  'common.illustrations.name': 'Projects informatisés',
  'common.forLife': 'la vie durant',
  'common.calculator': 'Calculateur',
  'common.calculator.name': 'CALCULATEUR',
  'common.viewreport': 'Créer un rapport PDF',
  'common.viewpdf': 'Afficher le document PDF',
  'common.customizereport': 'Personnaliser le rapport',
  'common.custom.MUL': 'Variable',
  'common.and': 'et',
  'common.years': 'ans',
  'common.yearss': 'an(s)',
  'common.for': 'pour',
  'common.life': 'la vie durant',
  'common.for.term': 'pendant ',
  'common.signin': 'Ouvrir une session',
  'common.advisor': 'Conseiller',
  'common.name': 'prénom',
  'common.advisorname': 'Conseiller',
  'common.presentedby': 'Presented by',
  'common.monthly': 'Mensuelle',
  'common.annually': 'Annuelle',
  'common.quarterly': 'Trimestrielle',
  'common.semiannually': 'Semestrielle',
  'common.summary.annually': 'annuelle',
  'common.summary.monthly': 'mensuelle',
  'common.vitality': 'Vitalité',
  'common.superscript': 'MC',
  'common.logo': 'Logo Manuvie',
  'common.required': '* Champ obligatoire',
  'common.system.error':
    'Un problème de connexion nous empêche actuellement de créer le projet informatisé. Veuillez fermer le programme et essayer de nouveau. Si le problème persiste, ',
  'common.specialRequestForm.error':
    'formulaire de demande spéciale n’est pas accessible pour le moment. Veuillez fermer le programme et essayer de nouveau. Si le problème persiste, ',
  'common.system.error.link': 'communiquez avec Manuvie.',
  'common.system.errortitle': 'Erreur détectée',
  'common.date.placeholder': 'jj/mm/aaaa',
  'common.start': 'Outil de Démarrer',
  'common.status': 'État',
  'common.customize': 'Personnaliser',
  'common.custom': 'Personnalisé',
  'common.reports': 'Rapports',
  'common.report': 'Rapport',
  'common.filldown': 'Remplir Tous les Champs',
  'common.email': 'Adresse de courriel',
  'common.open': 'Ouvrir',
  'common.refresh': 'Recalculer',
  'common.calculate': 'Il faut recalculer les primes.',
  'common.yes': 'Oui',
  'common.no': 'Non',
  'common.none': 'Aucun',
  'common.fromage': "De l'âge de",
  'common.toage': "Jusqu'à l'âge de",
  'common.fromyear': "De l'année",
  'common.toyear': "Jusqu'à l'année",
  'common.duration': 'Durée',
  'common.rates': 'TAUX',
  'common.type': 'Type',
  'common.typeKeywords': 'Taper des mots-clés',
  'common.remove': 'Retirer',
  'common.launch': 'Lancement',
  'common.startillustrations': 'Lancer le projet',
  'common.inforce': 'Inforce', // TODO: NEEDFRENCH
  'common.newbusiness': 'NewBusiness', // TODO: NEEDFRENCH
  'common.close': 'Fermer',
  'common.learnMore': 'En savoir plus',
  'common.more': 'Plus',
  'common.error': 'Erreur',
  'common.error.table.year': 'Année',
  'common.error.table.reqWithdrwal': 'Retrait demandé',
  'common.error.table.maxWithdrwal': 'Retrait maximum',
  'common.error.maxWithdrawal.part1': 'Le retrait demandé excède le maximum.',
  'common.error.maxWithdrawal.part2': 'Le maximum sera appliqué.',
  'common.importantInformation': 'Renseignements importants',
  'common.information.effectiveDate':
    'L’option Vitalité Plus n’est pas disponible pour la date sélectionnée. La date effective a été mise à jour.',
  'common.information.pay100':
    'La durée des primes jusqu’à 100 ans n’est pas disponible pour la date sélectionnée. La date effective a été mise à jour.',
  'common.information': 'Renseignements',
  'common.warning': 'Attention',
  'common.loading': 'Chargement',
  'common.progress': 'Veuillez patienter pendant que nous traitons votre demande.',
  'common.enabled': 'Activé',
  'common.disabled': 'Désactivé',
  'common.home': 'Accueil',
  'common.change': 'Changement',
  'common.copytext': 'Copier',
  'common.linkcopied': 'Lien copié !',
  'common.illustrations.link': 'https://www.insurance.manulife.ca/illustrations/',
  'common.combinatiosAvailable': 'Combinaisons disponibles :',
  'common.select': 'Sélectionner',
  'common.selectProduct': 'Page d’accueil du produit',
  'common.optional': 'facultatif',
  'common.wrong.email': 'Mauvais courriel',

  'error.coverageDate.outOfRange': 'La date doit se situer entre le 1er mai 2010 et le 4 octobre 2013',
  'error.coverageDate.invalidDay': 'La date d’effet de la couverture ne peut pas être le 29, 30 ou 31',
  'error.invalidDate': 'Date invalide',
  'error.emptyDate': 'La champ de la date doit être rempli',

  'sronly.newtab': 'Ouvre dans un nouvel onglet',
  'sronly.newwindow': 'Ouvre dans une nouvelle fenêtre',
  'sronly.requiredOnly': 'Obligatories',
  'sronly.pdf': 'PDF',

  //Session Timeed out Dialog
  'session.title': 'Fermeture de la session',
  'session.subtitle': 'Votre session dans l’outil de projets informatisés prendra fin dans :',
  'session.message.1': 'Veuillez cliquer sur ',
  'session.message.2': ' pour continuer à travailler ou sur  ',
  'session.message.3': ' pour mettre fin à votre session maintenant (les modifications ne seront pas enregistrées).',
  'session.continue': 'Continuer',
  'session.logout': 'Fermer la session',
  'session.min': 'min',
  'session.secs': 'secs',
  'session.autosaved': 'Enregistrement automatique',
  'session.24active.title': 'Fermeture de la session pour des raisons de sécurité',
  'session.24active.subtitle':
    'Pour des raisons de sécurité, vous êtes tenu de fermer toute session ouverte depuis 24 heures.',
  'session.24active.message': 'Votre projet informatisé a été enregistré sous le nom «filename»',
  'session.24active.prefix': 'Enregistrement automatique',
  //End of session Timed out

  'shareLink.suffix': ' - Partagé',

  'healthstyle.1': '1',
  'healthstyle.2': '2',
  'healthstyle.3': '3',
  'healthstyle.4': '4',
  'healthstyle.5': '5',

  'smokingstatus.nonsmoker': 'Non-fumeur',
  'smokingstatus.smoker': 'Fumeur',

  'scenario.deletemsg': 'Souhaitez-vous vraiment supprimer ce scenario?',
  'scenario.scenarioname': 'Nom du scenario',

  'advisor.profile': 'Profil de conseiller',
  'advisor.name': 'Nom du conseiller',
  'advisor.companyname': 'Nom de la société',
  'advisor.residence': 'Résidence',
  'advisor.phonenumber': 'Numéro de téléphone',
  'advisor.extension': 'Poste',
  'advisor.dialog.delete': 'Souhaitez-vous vraiment supprimer ce profil?',
  'advisor.deleteProfile': 'Supprimer le profil',

  'header.editprofile': 'Modifier le profil',
  'header.links': 'Liens',
  'header.addnewprofile': 'Ajouter un profil',
  'header.skip': 'Aller au contenu principal',
  'header.skipFooter': 'Passer au pied de page',
  'header.lang': 'EN',
  'header.switchlang': 'Switch to English',
  'header.help': 'Soutien',
  'header.accessibility': 'Affichage accessible',
  'header.accessibility.enable': 'Activer toutes les fonctions d’accessibilité',
  'header.accessibility.disable': 'Désactiver toutes les fonctions d’accessibilité',
  'header.faqs': 'Foire aux questions',
  'header.repsource': 'Inforep',
  'header.mycases': 'Mes propositions',
  'header.repsourceLink':
    'https://repsourcepublic.manulife.com/wps/portal/Repsource/PublicHome/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zivQx9TTwc3Q183F0NLAwcfUJdzM0DzQ0NDAz1wwkpiAJKG-AAjgb6BbmhigCiQrmY/dz/d5/L0lDUmlTUSEhL3dHa0FKRnNBLzROV3FpQSEhL2Zy/',
  'header.model.title': 'Enregistrer le projet informatisé',
  'header.model.label': 'Nom du projet informatisé',
  'header.model.placeholder': 'Entrer le nom du projet informatisé',
  'header.model.error': 'Le nom de ce projet informatisé existe déjà. Entrez un nom different.',

  'save-case.save-as.label': 'Au nom du conseiller suivant',

  'header.model.save.title': 'Enregistrer le projet informatisé',
  'header.model.share.title': 'Partager le projet informatisé',
  'header.model.label.share':
    'Ce lien fournit un aperçu du projet informatisé, y compris tous les renseignements à jour, sauf les champs Prénom et Nom de famille. Pour partager le lien, cliquez sur Copier, puis envoyez-le.',
  'header.model.links.title': 'Liens',
  'header.model.help.title': 'Aide',

  'assign.model.header.label': 'Attribuer le projet informatisé',
  'assign.model.text1': 'Vous êtes sur le point d’attribuer ce projet informatisé à',
  'assign.model.text2':
    'Ce conseiller pourra consulter et modifier ce projet informatisé. Voulez-vous vraiment poursuivre?',
  'assign.model.button.assign': 'Oui',
  'assign.model.tooltip':
    'Lorsque vous attribuez un projet informatisé à un conseiller, ce dernier peut le consulter et le modifier.',
  'assign.model.error.required': 'Obligatoire',
  'assign.model.myself': 'Moi-même',

  'sidenav.dashboard': 'Tableau de bord',
  'sidenav.illustration': 'Projet informatisé en cours',
  'sidenav.productstoillustrate': 'Produits qui feront l’objet d’un projet informatisé',
  'sidenav.application': 'Proposition en cours',
  'utilnav.advisorportal': 'Portail des conseillers',
  'utilnav.advisorportal.sit.link': 'https://advisor-fr.sit.manulife.com/landing',
  'utilnav.advisorportal.uat.link': 'https://uat.conseiller.manuvie.ca/landing',
  'utilnav.advisorportal.prod.link': 'https://conseiller.manuvie.ca/landing',
  'utilnav.contactus': 'Nous joindre',
  'utilnav.contactus.sit.link': 'https://advisor-fr.sit.manulife.com/conseillers/connexions/pour-nous-joindre.html',
  'utilnav.contactus.uat.link': 'https://uat.conseiller.manuvie.ca/conseillers/connexions/pour-nous-joindre.html',
  'utilnav.contactus.prod.link': 'https://conseiller.manuvie.ca/conseillers/connexions/pour-nous-joindre.html',
  'utilnav.profile': 'Profil',
  'utilnav.profile.manulifeid': 'Manuvie ID',
  'utilnav.profile.manulifeid.link': 'https://id.manulife.ca/advisor/manageid',
  'utilnav.profile.preferences': 'Préférences',
  'utilnav.profile.preferences.link': 'https://advisor.manulife.ca/advisors/connect/contact-us.html',
  'utilnav.profile.signout': 'Se déconnecter',

  // dashboard
  'dashboard.title': 'Tableau de bord',
  'dashboard.tab.illustrations': 'Projects informatisés',
  'dashboard.tab.applications': 'Propositions',
  'dashboard.tab.new.applications': 'Nouvelles affaires',
  'dashboard.tab.tca.applications': 'Transformation d’assurance temporaire',
  'dashboard.startIllustration': 'Commencer un nouveau projet',
  'dashboard.startApplication': 'Commencer une proposition électronique de Manuvie',
  'dashboard.startTcaApplication': 'Commencer une proposition de transformation électronique',
  'dashboard.startApplication.infoText':
    'Produits disponibles: Vie Manuvie avec participation et Vie Manuvie avec participation avec <b><i>Vitalité Plus</i></b>, VU Manuvie et VU Manuvie avec <b><i>Vitalité Plus</i></b>, Temporaire Famille et Temporaire Famille avec <b><i>Vitalité Plus</i></b>, et Chèque-vie',
  'dashboard.table.recordsperpage': 'records par page',
  'dashboard.table.resetFilters': 'Réinitialiser les filtres',
  'dashboard.table.illustrationName': 'Nom du projet informatisé',
  'dashboard.table.applicationName': 'Numéro de proposition ou de contrat',
  'dashboard.table.new.applicationName': 'Numéro de nouvelle proposition ou de nouveau contrat',
  'dashboard.table.origin.applicationName': 'Numéro de contrat original',
  'dashboard.table.insuredName': 'Nom de l’assuré(e)',
  'dashboard.table.select.all': 'Tous',
  'dashboard.table.product': 'Produit',
  'dashboard.table.new.product': 'Nouveau produit',
  'dashboard.table.createdBy': 'Créé par',
  'dashboard.table.lastUpdatedOn': 'Dernière mise à jour (date)',
  'dashboard.table.lastUpdatedBy': 'Dernière mise à jour (auteur)',
  'dashboard.table.nomatch.illustration': 'Aucun dossier ne correspond à vos critères.',
  'dashboard.table.no.illustration': 'Aucun projet informatisé n’a été enregistré.',
  'dashboard.table.no.application': 'Aucune demande de souscription en cours de traitement.',
  'dashboard.table.footer.showing': 'Résultats de',
  'dashboard.table.footer.to': ' à ',
  'dashboard.table.footer.of': ' sur ',
  'dashboard.table.footer.records': ' dossiers',
  'dashboard.delete.app.tooltip':
    'Vous ne pouvez pas supprimer une proposition si vous n’êtes pas le représentant ayant conclu la vente ou après qu’elle a été soumise.',
  'dashboard.apps.submitted.desc': 'Pour des précisions, consultez votre boîte de réception sécurisée.',
  'dashboard.apps.submitted.desc.new': 'Télécharger la proposition',
  'dashboard.apps.await.desc.pre': 'Vous avez',
  'dashboard.apps.await.desc.post': 'jours à compter de la date de signature pour soumettre votre demande.',

  'feedback.title': 'Commentaires',
  'feedback.link': 'https://horizons.confirmit.ca/wix/p107654193826.aspx?l=3084',

  'landing.welcome': 'Bienvenue dans l’outil Projets informatisés de Manuvie!',
  'landing.whatsnew': 'Nouveautés',
  'landing.newinrelease': 'Quoi de neuf dans la version d’octobre ?',
  'landing.cpr': 'Protection des enfants',
  'landing.bvp': 'Protection de la valeur de l’entreprise (PVE)',
  'landing.importantnote': 'Remarque importante :',
  'landing.vitalitystatus': 'niveau Vitalité{superscript}',
  'landing.newtext1':
    'Ouverture de session sécurisée (processus d’authentification unique) au moyen de votre identifiant Inforep.',
  'landing.newtext2': 'Illustrer la Temporaire Entreprise',
  'landing.newtext3': 'Capacité à résoudre la couverture en saisissant un montant de prime',
  'landing.newtext4':
    'Possibilité d’ajouter l’avenant Option d’assurabilité garantie et l’avenant Décès et mutilation accidentels',
  'landing.newtext5': 'Pages-produits',
  'landing.newtext6': 'Révision des primes des assurances Temporaire – changements prenant effet le 6 novembre 2018',
  'landing.newtext7': 'Nouvelle fonction de sondage et de rétroaction à l’écran',
  'landing.newtext8':
    'Dans le nouvel affichage du tableau, chaque occurrence de la cinquième année est mise en surbrillance.',
  'landing.newtext9':
    'Corrections de fautes d’orthographe dans la version française, utilisation de majuscules, production de rapports avec un iPad, recours par défaut au montant maximal de PVE et ajout de clients non surprimés à des couvertures combinées',
  'landing.important1': 'IMPORTANT',
  'landing.important2': 'Nouvelles Affaires – Quoi de neuf?',
  'landing.important3': `Maintenant disponibles : Concept de vente Plan d'assurance-retraite de société et concept de vente Bon patrimonial de société!`,
  'landing.important6': '<b>Baisse du taux de VU Manuvie avec CDA uniforme!</b>',
  'landing.important16': 'Nouveaux tarifs pour les assurances Temporaire 10 ans et Temporaire 20 ans!',
  'landing.important17': 'Renseignements importants concernant le taux d’intérêt sur les dividendes',
  'landing.announcement.par': '• Vie Manuvie avec participation',
  'landing.announcement.parLink':
    'https://conseiller.manuvie.ca/conseillers/assurance/assurance-vie/vie-entiere.html#vie-manuvie-avec-participation',
  'landing.announcement.parHover': 'Link opens in new window',
  'landing.announcement.parVP': '• Vie Manuvie avec participation – <i>Vitalité Plus</i>',
  'landing.announcement.parVPLink':
    'https://conseiller.manuvie.ca/conseillers/assurance/assurance-vie/vie-entiere.html#vie-manuvie-avec-participation---vitalite-plus',
  'landing.announcement.parVPHover': 'Link opens in new window',
  'landing.announcement.new': 'NOUVEAU',
  'landing.announcement.title.termPrices':
    'À compter du 11 mai 2024, nous avons le plaisir de proposer des tarifs plus avantageux pour plusieurs âges et montants d’assurance pour les assurances Temporaire famille et Temporaire famille avec <i>Vitalité Plus</i><sup>MC</sup>.',
  'landing.announcement.title1.divRec2024':
    'À compter du 1<sup>er</sup> septembre 2024, le taux d’intérêt sur les dividendes sera de 6,35 % pour les contrats Vie Manuvie avec participation et Vie Manuvie avec participation – <i>Vitalité Plus</i><sup>MC</sup>. ',
  'landing.announcement.title2.divRec2024':
    'Les projets informatisés de Manuvie seront mis à jour le 22 août 2024 pour tenir compte du taux d’intérêt sur les dividendes qui entre en vigueur le 1<sup>er</sup> septembre 2024.',
  'landing.important14': 'En vigueur le 29 mai 2023 :',
  'landing.important15': 'Préparez vos projets par vous-même pour :',
  'landing.announcement.title1':
    'L’outil Projets informatisés de Manuvie permet désormais de créer des projets de contrats en vigueur pour toutes les versions de VU Manuvie, à l’exception de VU Manuvie – <i>Vitalité Plus</i><sup>MC</sup>. Sélectionnez VU Manuvie pour commencer.',
  'landing.announcement.title2': `Pour découvrir les autres versions, consultez la page <a href=${enSavoirPlus}>En savoir plus</a> concernant les projets de contrats en vigueur.`,
  'landing.announcement.title3': `<b>Contrats en vigueur</b>`,
  'landing.announcement.message4': 'VU Manuvie',
  'landing.announcement.message5': 'Performax Or ',
  'landing.announcement.message6': 'Performax ',
  'landing.announcement.message2': 'Le produit Temporaire Entreprise n’est plus offert.',
  'landing.announcement.message3':
    '. Les projets informatisés du Système Diamant ne seront plus acceptés pour Vie Manuvie avec participation.',
  'landing.announcement.link2': 'https://conseiller.manuvie.ca/conseillers/assurance/manuvie-vitalite.html',
  'landing.announcement.divReclink':
    'https://conseiller.manuvie.ca/content/dam/manulife-advisor-portal/documents/fr/marketing-materials/insurance/manulife-par/insurance-manulife-par-dividend-scalef.pdf',
  'landing.announcement.rateChange':
    'Au 1<sup>er</sup> septembre 2024, le barème des dividendes pour les contrats Performax établis après le 24 septembre 1999 et avant 2009 sera augmenté de 0,6%. ',
  'landing.announcement.rateChangelink':
    'https://www.manuvie.ca/particuliers/soutien/assurance/participation-de-manuvie.html',
  'landing.important.feedback': 'COMMENTAIRES',
  // 'landing.newtext1':
  //   'Possibilité de créer des projets pour des contrats multivie : combinés, contrats conjoints premier décès et contrats conjoints dernier décès',
  // 'landing.newtext2': 'Possibilité d’ajouter les garanties {childProtector} et {bvpProtector} au projet informatisé',
  // 'landing.newtext3': 'Relevé sommaire des commissions',
  // 'landing.newtext4': 'Nouvelle saisie numérique pour la date de naissance (jj/mm/aaaa)',
  // 'landing.newtext5': 'Nouvelle option personnalisable de sélection du {vitalityStatus}',
  'landing.includedinrelease': 'Éléments inclus dans la version de septembre :',
  'landing.includedtext1':
    'Possibilité de créer des projets pour des contrats multivie : combinés, contrats conjoints premier décès et contrats conjoints dernier décès',
  'landing.includedtext2':
    'Possibilité d’ajouter les garanties {childProtector} et {bvpProtector} au projet informatisé',
  'landing.includedtext3': 'Relevé sommaire des commissions',
  'landing.includedtext4': 'Nouvelle saisie numérique pour la date de naissance (jj/mm/aaaa)',
  'landing.includedtext5': 'Nouvelle option personnalisable de sélection du {vitalityStatus}',
  'landing.product.selector.title': 'Sélectionner le produit',
  // 'landing.includedtext1': 'Produits Temporaire Famille et Temporaire Famille – {vitality}',
  // 'landing.includedtext2':
  //   'Couverture individuelle pour les assurances Temporaire 10 ans, Temporaire 20 ans, Temporaire 65 ans et Temporaire viagère',
  // 'landing.includedtext3': 'Garantie d’exonération en cas d’invalidité totale',
  // 'landing.includedtext4': 'Possibilité de passer de la Temporaire Famille à la Temporaire Famille – {vitality}',
  // 'landing.includedtext5': 'Affichage du tableur et possibilité d’exportation vers Excel',
  // 'landing.includedtext6': 'Rapport personnalisé',
  // 'landing.includedtext7': 'Rapport complet de projet informatisé',
  // 'landing.includedtext8': 'Liens rapides',
  // 'landing.includedtext9': 'Service de soutien offert en français et en anglais',
  'landing.whysignin': 'Pourquoi ouvrir une session',
  'landing.universallife': 'Vie Universelle',
  'landing.wholelife': 'Vie Entiere',
  'landing.term': 'Temporaire',
  'landing.lifecheque': 'Assurance maladies graves',
  'landing.livingbenefits': 'Prestations du vivant',
  'landing.inforce': 'Contrats en vigueur',
  'landing.inforce.title': 'En vigueur',
  'landing.newbusiness': 'Affaire nouvelle',
  'landing.inforce.businesstypelabel': 'Affaire nouvelle ou projet de contrat en vigueur existant?',
  'landing.inforce.policynumberlabel': 'Entrer le numéro de contrat',
  'landing.if.infomessage': 'Pour plus de renseignements relativement aux contrats en vigueur, ',
  'landing.if.link1': 'https://conseiller.manuvie.ca/conseillers/prive/outils/projets-informatises-de-manuvie.html',
  'landing.if.unauthorizedlink1':
    'https://conseiller.manuvie.ca/conseillers/prive/outils/projets-de-contrats-en-vigueur.html',
  'landing.if.search': 'Recherche',
  'landing.concept.note':
    'Des concepts de ventes de premier plan vous seront proposés lorsque vous aurez choisi le produit sur lequel porte le projet informatisé.',
  'landing.note':
    '{Important} Prenez note qu’à compter du 31 octobre 2018, le rapport Preuves d’assurabilité produit au moyen de cet outil comprendra les changements apportés aux primes, qui entrent en vigueur le 6 novembre 2018. Si vous voulez produire un rapport Preuves d’assurabilité avant le 6 novembre 2018, veuillez utiliser le Système Diamant.',
  'landing.term.rateDecrease.title': '<b>Le taux de l’assurance temporaire diminue!</b>',
  'landing.term.rateDecrease.message':
    'Dans la plupart des cas, les couvertures Temporaire 10 ans et Temporaire 20 ans au titre des contrats Temporaire Famille, Temporaire Entreprise et Temporaire Famille avec Vitalité offrent des taux d’assurance moins élevés.',
  'landing.ft.rateDecrease.message':
    'En date du 20 novembre 2021 : Dans la plupart des cas, les couvertures Temporaire 10 ans et Temporaire 20 ans au titre du contrat Temporaire Famille offrent des taux d’assurance moins élevés.',
  'landing.bt.rateDecrease.message':
    'En date du 20 novembre 2021 : Dans la plupart des cas, les couvertures Temporaire 10 ans et Temporaire 20 ans au titre du contrat Temporaire Entreprise offrent des taux d’assurance moins élevés.',
  'landing.all.clickhere': 'cliquez ici',
  'landing.all.clickhere1': 'Cliquez ici',
  'landing.par.productDescription':
    'Des valeurs de rachat garanties dès les premières années, de la souplesse et une protection à long terme, accompagnées d’éventuels avantages sur le plan fiscal.',
  'landing.par.productDescription2':
    'Le contrat Vie Manuvie avec participation offre non seulement de précieuses garanties, mais également les caractéristiques suivantes:',
  'landing.par.productDescription3':
    'Vie Manuvie avec participation avec <b><i>Vitalité Plus</i></b> réunit ces mêmes caractéristiques formidables et permet aux clients d’obtenir des récompenses et économiser de l’argent - tout en améliorant leur santé.',
  'landing.all.learnVitality': 'En savoir plus sur le programme Manuvie <i>Vitalité</i> <sup>MD</sup>',
  'landing.all.vitalitySimulator': 'Simulateur de Récompenses et Statut Manuvie <i>Vitalité</i>',
  'landing.inforce.error.unauthorized':
    'Vous avez entré un numéro de contrat qui ne peut produire de projet informatisé avec cet outil. ',
  'landing.inforce.error.leadTimeValidation':
    "Ce contrat ne peut pas être illustré pour le moment en raison des activités de traitement de l'anniversaire. Veuillez réessayer à nouveau 23 jours ou moins avant de la date de l'anniversaire.",
  'landing.inforce.unauthorized': ' pour savoir comment demander un projet informatisé.',
  'landing.inforce.error.notillustratable': 'Vous avez entré un numéro de contrat qui n’est pas en vigueur.',
  'landing.inforce.error.paidUpPolicy':
    'Un contrat d’assurance libérée réduite ne peut pas faire l’objet d’un projet informatisé.',
  'landing.inforce.error.productmismatch':
    'Vous avez entré un numéro de contrat auquel vous n’avez pas accès. Veuillez vérifier le numéro de contrat et essayer de nouveau.',
  'landing.inforce.error.unavailable':
    'L’outil Projets de contrats en vigueur n’est pas accessible pour le moment. Veuillez réessayer plus tard.',
  'landing.inforce.error.vitalityplus':
    'Vous avez entré un numéro de contrat qui a <b><i>Vitalité Plus</i></b><sup>MC</sup> et n’est pas possible de créer des projets informatisés.',
  'landing.inforce.error.vitalityplus.terminforce':
    'Vous avez entré un numéro de contrat avec Vitalité ou Vitalité Plus et pour lequel des projets informatisés ne peuvent être produits.',
  'landing.inforce.error.specialquote':
    'Les contrats à tarification spéciale ne peuvent pas faire l’objet d’un projet informatisé.',
  'landing.inforce.error.specialquote.PAR':
    'Vous avez entré un numéro de contrat qui ne peut produire de projet informatisé avec cet outil.',
  'landing.inforce.error.redflag':
    'Vous avez entré un numéro de contrat qui ne peut produire de projet informatisé avec cet outil.',
  'landing.inforce.error.waiver':
    'Il est impossible de créer des projets informatisés pour les contrats faisant l’objet d’une exonération des primes.',
  'landing.inforce.error.TermOptionNotSupported':
    'Vous avez entré un numéro de contrat qui a l’option Temporaire et qui ne peut produire de projet informatisé.',
  'landing.inforce.error.UseSpecialRequestForm':
    'Vous avez entré un numéro de contrat qui ne peut produire de projet informatisé avec cet outil.',
  'landing.inforce.error.CPRNotSupported':
    'Vous avez entré un numéro de contrat qui ne peut produire de projet informatisé avec cet outil.',
  'landing.inforce.error.TermOptionPlusCoverageWithoutTermOptionCoverageNotSupported':
    'Vous avez entré un numéro de contrat qui ne peut produire de projet informatisé avec cet outil.',
  'landing.inforce.error.NoPaymentsError':
    'Vous avez entré un numéro de contrat qui ne peut produire de projet informatisé avec cet outil.',
  'landing.inforce.warning.redflag': 'Vous avez entré un numéro de contrat qui est sur Alerte.',
  'landing.inforce.irfOnlyProduct1': 'Vous avez entré un numéro de contrat pour le produit ',
  'landing.inforce.irfOnlyProduct2': ' qui ne peut produire de projet informatisé avec cet outil.',
  'landing.inforce.irfintro': ' Veuillez utiliser le ',
  'landing.inforce.irflink': 'formulaire de demande spéciale',
  'landing.inforce.irfoutro': '.',
  'landing.mul.productDescription':
    'VU Manuvie offre à vos clients une assurance vie permanente qui peut aussi les aider à faire croître leurs placements, quel que soit leur profil d’investisseur.',
  'landing.mul.productDescription2':
    'La couverture peut prendre la forme d’une couverture à coût uniforme ou d’une couverture temporaire renouvelable annuellement (TRA). Ce produit offre une vaste gamme d’options de placement, dont une option assortie d’une garantie de taux d’intérêt minimal de 1 %.',
  'landing.mul.productDescription4':
    'VU Manuvie avec <b><i>Vitalité Plus</i></b> réunit ces mêmes caractéristiques formidables et permet aux clients d’obtenir des récompenses et de réduire le coût de leur assurance, tout en améliorant leur santé.',

  'landing.mulinforce.contactEmail': 'Digital_desk@manuvie.ca',
  'landing.mulinforce.supportcentreEmail': 'DTSC@manuvie.ca',
  'landing.mulinforce.illustrationEmail': 'inforceillustrations@manuvie.ca',
  'landing.mulinforce.productDescription':
    'VU Manuvie offre à vos clients une assurance vie permanente qui peut devenir un élément important de leurs plans financiers. Cet outil vous aide à gérer vos contrats VU Manuvie en vigueur à l’aide de projets informatisés pour toutes les versions de produits VU Manuvie, à l’exception de ceux comptant <i>Vitalité Plus<sup>MC</sup></i>.',
  'landing.mulinforce.productDescription1':
    'Utilisez cet outil pour créer des projets informatisés qui illustrent les contrats VU Manuvie. Voici ce que vous pouvez faire :',
  'landing.mulinforce.productBullet0': 'Montrer l’état actuel (projet informatisé « tel quel »)',
  'landing.mulinforce.productBullet1': 'Modifier les taux d’intérêt applicables au contrat',
  'landing.mulinforce.productBullet2':
    'Indiquer le montant des dépôts et la périodicité (paiement accéléré, fin des dépôts, dépôts supplémentaires)',
  'landing.mulinforce.productBullet3': 'Personnaliser le montant des dépôts pour chaque période',
  'landing.mulinforce.productDescription2':
    'À l’heure actuelle, l’outil ne permet pas de créer un projet informatisé dans les cas suivants :',
  'landing.mulinforce.toolBullet0': 'Modifications au capital assuré',
  'landing.mulinforce.toolBullet1': 'Garanties Assurance temporaire (GAT) de tiers',
  'landing.mulinforce.toolBullet2': 'Modifications du montant de la couverture au titre de la GAT',
  'landing.mulinforce.toolBullet3': 'Retraits',
  'landing.mulinforce.toolBullet4':
    'Passage d’un terme renouvelable annuellement (TRA) à un coût de l’assurance uniforme (CDAU)',
  'landing.mulinforce.productDescription3A':
    'Le cas échéant, nous pouvons fournir un nouveau devis pour le coût de l’assurance. Pour connaître les coordonnées du représentant attitré de votre réseau, cliquez sur le lien ',
  'landing.mulinforce.productDescription3B': 'Nous joindre', // utilnav.contactus.prod.link
  'landing.mulinforce.productDescription3C':
    ', puis consultez la section <b>Questions portant sur les affaires en vigueur et les nouvelles affaires.</b>',
  'landing.mulinforce.productDescription4A':
    'Communiquez avec l’équipe de soutien de Projets de contrats en vigueur à l’adresse ',
  'landing.mulinforce.productDescription4B': 'inforceillustrations@manuvie.ca', // landing.mulinforce.illustrationEmail
  'landing.mulinforce.productDescription4C': ' pour :',
  'landing.mulinforce.contactBullet0': 'Obtenir de l’aide pour remplir une demande de projet de contrat en ligne',
  'landing.mulinforce.contactBullet1': 'Poser des questions concernant un projet informatisé que vous avez exécuté',
  'landing.mulinforce.productDescription5A':
    'Si vous avez des questions d’ordre technique, veuillez communiquer avec le Centre de soutien technique, Distribution à l’adresse ',
  'landing.mulinforce.productDescription5B': 'DTSC@manuvie.ca', // landing.mulinforce.supportcentreEmail
  'landing.mulinforce.productDescription5C': ' ou au 1 800 667-4266.',
  'landing.mulinforce.productDescription6':
    'Le service de projets informatisés pour les contrats VU Manuvie en vigueur est offert du lundi au vendredi de 6 h 30 à minuit (HE) et le samedi de 7 h à 17 h (HE).',

  'landing.performaxgold.openingParagraph':
    'Utilisez cet outil pour créer des projets informatisés concernant des contrats Performax Or avec l’affectation des crédits de rendement (CR) selon l’option Assurance libérée ou Compte de capitalisation. Voici ce que vous pouvez faire :',
  'landing.performaxgold.productBullet0': 'Montrer l’état actuel (projet informatisé « tel quel »)',
  'landing.performaxgold.productBullet1': 'Modifier le taux de CR',
  'landing.performaxgold.productBullet2':
    'Sélectionner l’éclipse de paiement la plus rapide ou préciser l’éclipse de paiement',
  'landing.performaxgold.productBullet3':
    'Résilier une garantie Assurance temporaire à l’anniversaire contractuel suivant ou ultérieurement',
  'landing.performaxgold.productBullet4':
    'Modifier les versements supplémentaires – montant uniforme ou versement supplémentaire pour l’année courante',
  'landing.performaxgold.productBullet5': 'Demander un retrait (non personnalisé)',
  'landing.performaxgold.specialRequestParagraph1':
    'À l’heure actuelle, on peut créer des projets informatisés à l’aide du ',
  'landing.performaxgold.specialRequestForm': 'formulaire de demande spéciale',
  'landing.performaxgold.specialRequestParagraph2': ' pour ce qui suit :',
  'landing.performaxgold.specialRequestBullet0': 'Réduire le montant de la garantie Assurance temporaire',
  'landing.performaxgold.specialRequestBullet1': 'Mettre fin à des garanties autres que d’Assurance temporaire',
  'landing.performaxgold.specialRequestBullet2':
    'Modifier la répartition des paiements additionnels entre l’option Dépôts et le compte de capitalisation',
  'landing.performaxgold.specialRequestBullet3': 'Établir plus de deux assurances de base',
  'landing.performaxgold.specialRequestBullet4': 'Établir plus de trois garanties Assurance temporaire',
  'landing.performaxgold.limitationsParagraph':
    'À l’heure actuelle, on ne peut pas créer des projets informatisés pour ce qui suit :',
  'landing.performaxgold.limitationsBullet0': 'Un contrat avec option de CR selon l’option Assurance temporaire',
  'landing.performaxgold.limitationsBullet1': 'La réduction du montant d’assurance de base',
  'landing.performaxgold.limitationsBullet2': 'La résiliation d’une couverture d’assurance de base',
  'landing.performaxgold.limitationsBullet3': 'Un changement de l’indice-santé',

  // Below .parinforce. text is for PMax.
  'landing.performax.openingParagraph':
    'Utilisez cet outil pour créer des projets informatisés qui illustrent les contrats Performax assortis d’une option d’affectation des participations. Voici ce que vous pouvez faire :',
  'landing.performax.productBullet0': 'Montrer l’état actuel (projet informatisé « tel quel »)',
  'landing.performax.productBullet1':
    'Modifier l’option d’affectation des participations (sauf pour une réduction de prime)',
  'landing.performax.productBullet2':
    'Opter pour l’éclipse de prime la plus rapprochée, paramétrer l’éclipse de prime ou prévoir un congé de prime',
  'landing.performax.productBullet3':
    'Résilier une garantie Assurance temporaire (GAT), une garantie Assurance temporaire – autre assuré (GATA) ou une garantie Protection du conjoint (GPC) lors du prochain anniversaire contractuel ou par la suite',
  'landing.performax.productBullet4': 'Modifier le paiement au titre de l’option Dépôts – montant uniforme',
  'landing.performax.productBullet5': 'Demander un retrait (non personnalisé)',
  'landing.performax.specialRequestParagraph1':
    'À l’heure actuelle, on peut créer des projets informatisés à l’aide du ',
  'landing.performax.specialRequestForm': 'formulaire de demande spéciale',
  'landing.performax.specialRequestParagraph2': ' pour ce qui suit :',
  'landing.performax.specialRequestBullet0': 'Réduire le montant de la GAT, de la GATA et/ou de la GPC',
  'landing.performax.specialRequestBullet1': 'Résilier des garanties autres que la GAT, la GATA ou la GPC',
  'landing.performax.specialRequestBullet2': 'Passer au statut de non-fumeur',
  'landing.performax.specialRequestBullet3': 'Réduire le capital assuré de base',
  'landing.performax.specialRequestBullet4':
    'Créer des projets informatisés qui illustrent les contrats dont le capital assuré est supérieur à 10 millions de dollars',
  'landing.performax.limitationsParagraph':
    'À l’heure actuelle, on ne peut pas créer des projets informatisés pour ce qui suit :',
  'landing.performax.limitationsBullet0':
    'Modification de l’option d’affectation des participations d’un contrat en vue d’une réduction de prime',

  // Below .parinforce. text is for SecurityBuilder.
  'landing.sb.openingParagraph':
    'Utilisez cet outil pour créer des projets informatisés qui illustrent les contrats Sécuriplan assortis d’une option d’affectation des participations. Voici ce que vous pouvez faire :',
  'landing.sb.productBullet0': 'Montrer l’état actuel (projet informatisé « tel quel »)',
  'landing.sb.productBullet1': 'Modifier l’option d’affectation des participations (sauf pour une réduction de prime)',
  'landing.sb.productBullet2':
    'Opter pour l’éclipse de prime la plus rapprochée, paramétrer l’éclipse de prime ou prévoir un congé de prime',
  'landing.sb.productBullet3':
    'Résilier une garantie Assurance temporaire (GAT), une garantie Assurance temporaire – autre assuré (GATA) ou une garantie Protection du conjoint (GPC) lors du prochain anniversaire contractuel ou par la suite',
  'landing.sb.productBullet4': 'Modifier le paiement au titre de l’option Dépôts – montant uniforme',
  'landing.sb.productBullet5': 'Demander un retrait (non personnalisé)',
  'landing.sb.specialRequestParagraph1': 'À l’heure actuelle, on peut créer des projets informatisés à l’aide du ',
  'landing.sb.specialRequestForm': 'formulaire de demande spéciale',
  'landing.sb.specialRequestParagraph2': ' pour ce qui suit :',
  'landing.sb.specialRequestBullet0': 'Réduire le montant de la GAT, de la GATA et/ou de la GPC',
  'landing.sb.specialRequestBullet1': 'Résilier des garanties autres que la GAT, la GATA ou la GPC',
  'landing.sb.specialRequestBullet2': 'Passer au statut de non-fumeur',
  'landing.sb.specialRequestBullet3': 'Réduire le capital assuré de base',
  'landing.sb.specialRequestBullet4':
    'Créer des projets informatisés qui illustrent les contrats dont le capital assuré est supérieur à 10 millions de dollars',
  'landing.sb.limitationsParagraph':
    'À l’heure actuelle, on ne peut pas créer des projets informatisés pour ce qui suit :',
  'landing.sb.limitationsBullet0':
    'Modification de l’option d’affectation des participations d’un contrat en vue d’une réduction de prime',

  'landing.parInforce.openingParagraph':
    'Utilisez cet outil pour créer des projets informatisés qui illustrent les contrats Vie Manuvie avec participation. Voici ce que vous pouvez faire :',
  'landing.parInforce.productBullet0': 'Montrer l’état actuel (projet informatisé « tel quel »)',
  'landing.parInforce.productBullet1':
    'Résilier une garantie Assurance temporaire à l’anniversaire contractuel suivant ou ultérieurement',
  'landing.parInforce.productBullet2': 'Réduire le montant de la garantie Assurance temporaire',
  'landing.parInforce.productBullet3': 'Sélectionner l’éclipse de prime la plus rapide ou préciser l’éclipse de prime',
  'landing.parInforce.productBullet4':
    'Modifier ou ajouter des versements au titre de l’option Dépôt (uniforme, personnalisé, unique)',
  'landing.parInforce.productBullet5': 'Demander un retrait (non personnalisé)',
  'landing.parInforce.productBullet6': 'Passage à l’option de dividende en espèces',
  'landing.parInforce.productBullet7': 'Modifier le barème des participations alternatif',
  'landing.parInforce.specialRequestParagraph1':
    'À l’heure actuelle, on peut créer des projets informatisés à l’aide du ',
  'landing.parInforce.specialRequestForm': 'formulaire de demande spéciale',
  'landing.parInforce.specialRequestParagraph2': ' pour ce qui suit :',
  'landing.parInforce.specialRequestBullet0': 'Mettre fin à des garanties autres que d’Assurance temporaire',
  'landing.parInforce.specialRequestBullet1': 'Demander une réduction du montant d’assurance de base',
  'landing.parInforce.specialRequestBullet2': 'Établir plus de trois garanties Assurance temporaire',
  'landing.parInforce.limitationsParagraph':
    'À l’heure actuelle, on ne peut pas créer des projets informatisés pour ce qui suit :',
  'landing.parInforce.limitationsBullet0': 'Changement de statut en matière d’usage de tabac',
  'landing.parInforce.limitationsBullet1':
    'Passage de l’option de dividende en espèces à l’option de dividende d’assurance libérée',
  'landing.parInforce.limitationsBullet2': 'Ajout d’une GAT ou d’autres avenants',
  'landing.parInforce.limitationsBullet3': 'Passage à Vitalité Plus (le cas échéant)',

  'landing.parInforce.vp.openingParagraph': 'Utilisez cet outil pour créer des projets informatisés qui illustrent les contrats Vie Manuvie avec participation avec <i>Vitalité Plus</i><sup>MC</sup>. Voici ce que vous pouvez faire :',
  'landing.parInforce.vp.productBullet0': 'Montrer l’état actuel (projet informatisé « tel quel »)',
  'landing.parInforce.vp.productBullet1': 'Résilier une garantie Assurance temporaire à l’anniversaire contractuel suivant ou ultérieurement',
  'landing.parInforce.vp.productBullet2': 'Réduire le montant de la garantie Assurance temporaire',
  'landing.parInforce.vp.productBullet3': 'Sélectionner l’éclipse de prime la plus rapide ou préciser l’éclipse de prime',
  'landing.parInforce.vp.productBullet4': 'Modifier ou ajouter des versements au titre de l’option Dépôt (uniforme, personnalisé, unique)',
  'landing.parInforce.vp.productBullet5': 'Demander un retrait (non personnalisé)',
  'landing.parInforce.vp.productBullet6': 'Passage à l’option de dividende en espèces',
  'landing.parInforce.vp.productBullet7': 'Modifier le barème des participations alternatif',
  'landing.parInforce.vp.productBullet8': 'Modifier le niveau Vitalité',
  'landing.parInforce.vp.specialRequestParagraph1': 'À l’heure actuelle, on peut créer des projets informatisés à l’aide du ',
  'landing.parInforce.vp.specialRequestForm': 'formulaire de demande spéciale',
  'landing.parInforce.vp.specialRequestParagraph2': 'pour ce qui suit :',
  'landing.parInforce.vp.specialRequestBullet0': 'Mettre fin à des garanties autres que d’Assurance temporaire',
  'landing.parInforce.vp.specialRequestBullet1': 'Demander une réduction du montant d’assurance de base',
  'landing.parInforce.vp.specialRequestBullet2': 'Établir plus de trois garanties Assurance temporaire',
  'landing.parInforce.vp.limitationsParagraph': 'À l’heure actuelle, on ne peut pas créer des projets informatisés pour ce qui suit :',
  'landing.parInforce.vp.limitationsBullet0': 'Changement de statut en matière d’usage de tabac',
  'landing.parInforce.vp.limitationsBullet1': 'Passage de l’option de dividende en espèces à l’option de dividende d’assurance libérée',
  'landing.parInforce.vp.limitationsBullet2': 'Ajout d’une GAT ou d’autres avenants',

  'landing.ftbtinforce.productDescription1':
    "Cet outil permet de créer un projet informatisé pour les primes actuelles et les primes de renouvellement pour les contrats d'assurance Temporaire Famille et Temporaire Entreprise. Vous pouvez :",
  'landing.ftbtinforce.productBullet0': 'afficher les primes actuelles et les primes de renouvellement',
  'landing.ftbtinforce.productBullet1':
    'afficher toutes les options de couverture : Temporaire 10 ans, Temporaire 20 ans, Temporaire 65 ans et Temporaire viagère',
  'landing.ftbtinforce.productDescription2':
    'À l’heure actuelle, l’outil ne permet pas de créer un projet informatisé dans les cas suivants :',
  'landing.ftbtinforce.toolBullet0': 'Une modification au capital assuré',
  'landing.ftbtinforce.toolBullet1': "La résiliation d’une couverture d'assurance de base",
  'landing.ftbtinforce.toolBullet2': "Un contrat de plus de six couvertures d'assurance de base",
  'landing.ftbtinforce.toolBullet3': 'Un contrat de plus de quatre couvertures GPE',
  'landing.ftbtinforce.toolBullet4': "Un changement dans l'indice-santé ou le tabagisme",
  'landing.ftbtinforce.toolBullet5': 'Temporaire Famille - <i>Vitalité</i><sup>MC</sup>',
  'landing.ftbtinforce.toolBullet6': 'Temporaire Famille - <i>Vitalité Plus</i><sup>MC</sup>',
  'landing.ftbtinforce.productDescription3A':
    'Dans ces situations, trouvez les coordonnées du représentant attitré de votre réseau, cliquez sur le lien ',
  'landing.ftbtinforce.productDescription3B': 'Nous joindre,', // utilnav.contactus.prod.link
  'landing.ftbtinforce.productDescription3C':
    ' puis consultez la section <b>Questions portant sur les affaires en vigueur et les nouvelles affaires.</b>',

  'landing.ft.productDescription':
    'Nous offrons la gamme la plus complète et la plus souple d’assurances temporaires sur le marché. Lorsque vous ajoutez sa gamme de caractéristiques personnalisables, notre excellentsoutien aux ventes et notre formidable service des règlements – le tout appuyé par la solidité de la Financière Manuvie, vous conviendrez que notre assurance temporaire est la solution parfaite.',
  'landing.ft.productDescription2':
    'La Temporaire Famille est conçue pour répondre aux besoins de protection des familles. Elle offre une grande souplesse et couvre toute la famille, à prix abordable, au moyen d’un seul contrat.',
  'landing.ft.productDescription3':
    'Temporaire Famille avec <b><i>Vitalité Plus</i></b> réunit ces mêmes caractéristiques formidables et permet aux clients d’obtenir des récompenses et de réduire le coût de leur assurance, tout en améliorant leur santé.',
  'landing.lc.productDescription':
    'Chèque-vie est l’un des produits d’assurance maladies graves les plus complets du marché. Cette solution prévoit une prestation en espèces si votre client reçoit un diagnostic de l’une des conditions couvertes. Ainsi, votre client n’aura pas à se soucier de questions financières et pourra se concentrer sur ce qui compte vraiment : son rétablissement. Il pourra utiliser l’argent à sa discrétion, par exemple pour :',
  'landing.lc.productDescription2':
    'Avec 24 conditions couvertes, une couverture pour les maladies à un stade précoce, une protection intégrée pour les soins de longue durée, un large éventail de types de régimes et des garanties optionnelles, Chèque-vie est un excellent choix pour vos clients.',
  'landing.lc.productBullet0': 'Remplacer le revenu perdu;',
  'landing.lc.productBullet1': 'Effectuer ses versements hypothécaires;',
  'landing.lc.productBullet2': 'Réduire sa charge de travail pour faciliter son rétablissement;',
  'landing.lc.productBullet3': 'Embaucher une infirmière ou une personne soignante;',
  'landing.lc.productBullet4': 'Trouver les meilleurs soins de santé offerts.',
  'landing.ftrc.warning': 'Fonctionnalité réduite seulement',
  'landing.ftrc.productDescription':
    'Le calculateur de la prime de renouvellement vous permet d’effectuer des vérifications rapides des coûts au prochain renouvellement de vos clients. Vous pouvez utiliser cet outil pour les contrats Temporaire Famille comprenant :',
  'landing.ftrc.productDescription2':
    'Utilisez le calculateur de la prime de renouvellement pour une couverture à la fois.',
  'landing.ftrc.productDescription3':
    'Le calculateur peut estimer les coûts des montants de couverture actuels ou réduits. Vous ne pouvez pas l’utiliser pour estimer le coût des augmentations de couverture.',
  'landing.ftrc.productDescription4':
    'Vérifiez souvent les mises à jour et les améliorations apportées au calculateur de la prime de renouvellement, y compris l’augmentation de la période de couverture et les rapports PDF avec toutes les dates de renouvellement.',
  'landing.ftrc.with.newtext1A':
    'une couverture Temporaire 10 ans dont la date d’effet de la couverture se situe entre le 1',
  'landing.ftrc.with.newtext1B': 'er',
  'landing.ftrc.with.newtext1C':
    ' mai 2010 et le 4 octobre 2013 (ne peut être utilisé pour les couvertures ajoutées aux contrats avec compte parapluie)',
  'landing.ftrc.with.newtext2': 'sur une tête ',
  'landing.ftrc.with.newtext.notcombined': '(non combinée)',
  'landing.ftrc.with.newtext3': 'une couverture individuelle',
  'landing.ftrc.with.newtext4': 'à risque normal (non évalué)',
  'landing.ftrc.title': 'NOUVEAUTÉ! Calculateur de la prime de renouvellement de la Temporaire Famille 10 ans',
  'landing.par.newtext1':
    'Plafond global de l’option Dépôts préapprouvée – même si vous ne l’avez pas demandé – et aussi dans le cas des contrats issus de la transformation d’un contrat d’assurance temporaire.',
  'landing.par.newtext2':
    'Les surprimes ne sont jamais appliqués à l’assurance libérée et à l’assurance de l’option Dépôts.',
  'landing.par.newtext3': 'Les contrats sont rédigés dans un langage simple et convivial.',
  'landing.par.newtext4': 'Aucuns frais de contrat.',
  'landing.par.pirpannouncement':
    'Bonne nouvelle! Le concept de vente du Plan d’assurance-retraite (PAR particuliers) est maintenant disponible avec le produit Vie Manuvie avec participation.',
  'landing-whats-new1':
    'Calcul de la couverture. Entrez un montant de prime et les outils de projets informatisés déduiront le coût de toute garantie complémentaire et calculeront le montant d’assurance qu’il est possible de souscrire. Le minimum de produit s’applique.',
  'landing-whats-new2': 'Pouvoir préciser l’année pour virer des dividendes en espèces',
  'landing.contactwholesaler': 'https://www.manuvie.ca/conseillers/connexions/pour-nous-joindre.html',
  'landing.googlechrome.link':
    'https://www.google.ca/intl/fr/chrome/?brand=CHBD&gclid=CjwKCAjwwab7BRBAEiwAapqpTHFx-G33GDPgqk8NMTb3ks6ibOQ22YgjwdQoL_eKEQxRcBA7nCWGvBoC2OEQAvD_BwE&gclsrc=aw.ds',
  'landing.msedge.link': 'https://www.microsoft.com/fr-fr/edge',
  'landing.ie.learnmore.link':
    'https://support.microsoft.com/fr-ca/help/4028606/windows-10-change-your-default-browser',

  'logout.modal.heading1': 'Votre session expirera sous peu',
  'logout.modal.content1': 'Votre session prendra fin en raison de votre inactivité. Veuillez enregistrer vos données.',
  'logout.modal.content2': 'Voulez-vous garder votre session ouverte?',
  'logout.modal.stay.signed.in': 'Garder la session ouverte',
  'logout.modal.sign.out': 'Fermer la session maintenant',

  'toolbar.customizereport': 'Personnaliser le Rapport',

  'topbar.effectivedate': "Date d'effet",

  'product.label': 'Produit',
  'product.ft': 'Temporaire Famille',
  'product.ftv': 'Temporaire Famille avec Vitalité',
  'product.ft.title': 'Temporaire Famille et Temporaire Famille avec <i>Vitalité Plus</i><sup>MC</sup>',
  'product.bt': 'Temporaire Entreprise',
  'product.lc': 'Chèque-vie',
  'product.lc.title': 'Chèque-vie<sup style="font-size: 12px">MD</sup>',
  'product.cilc': 'Assurance maladies graves (Chèque-vie)',
  'product.sy': 'Combinaison d’assurances (Synergie)',
  'product.sy.link': 'https://tauxmanuvie.com/',
  'product.sy.hover': "S'ouvre dans une nouvelle fenêtre",
  'product.par': 'Vie Manuvie avec participation',
  'product.par.title':
    'Vie Manuvie avec participation et Vie Manuvie avec participation avec <i>Vitalité Plus</i><sup>MC</sup>',
  'product.par.inforce.title': 'Vie Manuvie avec participation',
  'product.par.vp.inforce.title': 'Vie Manuvie avec participation avec <i>Vitalité Plus</i><sup>MC</sup>',
  'product.pg': 'Performax Or',
  'product.performaxgold': 'Performax Or',
  'product.performax': 'Performax',
  'product.performaxgold.inforce.title': 'Performax Or',
  'product.performax.inforce.title': 'Performax',
  'product.securitybuilder.inforce.title': 'Sécuriplan',
  'product.securitybuilder': 'Sécuriplan',
  'product.ftrc': 'Calculateur de renouvellement: Temporaire Famille 10 ans',
  'product.mul': 'VU Manuvie',
  'product.mul.title': 'VU Manuvie et VU Manuvie avec <i>Vitalité Plus</i><sup>MC</sup>',
  'product.mul.inforce.title': 'VU Manuvie',
  'product.mulinforce.title': 'VU Manuvie',
  'product.gi': 'Vie Manuvie à émission garantie',
  'product.iv': 'InnoVision',
  'product.iv.title': 'InnoVision Fr Title Placeholder',
  'product.innovision.inforce.title': 'InnoVision',
  'product.if': 'Contrats en vigueur',
  'product.businessterm.inforce.title': 'Temporaire Famille et Temporaire Entreprise',
  'product.familyterm.inforce.title': 'Temporaire Famille et Temporaire Entreprise',
  'product.terminforce.link': 'Primes de renouvellement pour l’assurance Temporaire Famille et Entreprise',
  'product.terminforce':
    'Contrats en vigueur | Primes de renouvellement pour l’assurance Temporaire Famille et Entreprise',

  'product.architect.inforce.title': 'Architecte',
  'product.champion.inforce.title': 'Le Champion',
  'product.dividendterm.inforce.title': 'AextraTerme',
  'product.dlul.inforce.title': 'VUDV',
  'product.futureprotection.inforce.title': 'Protection de l’avenir',
  'product.intrepid.inforce.title': 'L’Intrépide',
  'product.lifeaccumulator.inforce.title': 'Accumulateur-Vie',
  'product.lifesaver.inforce.title': 'Vie Épargne',
  'product.mlidlpar.inforce.title': 'MLI/DL PAR',
  'product.naltraditional.inforce.title': 'Traditionnel de LNA',
  'product.newworldseries.inforce.title': 'La série du Nouveau Monde',
  'product.maritimelifeproducts.inforce.title': 'La Maritime',
  'product.performanceplan.inforce.title': 'Vie dynamique (séries 95 et 97)',
  'product.securityul.inforce.title': 'VU Sécurité',
  'product.solutions.inforce.title': 'Solution',
  'product.securedprotection.inforce.title': 'Protection stabilisée',
  'product.securityul2.inforce.title': 'Vie Universelle Sécurité',
  'product.ul100.inforce.title': 'U100',

  'covType.single': 'Sur une tête',
  'covType.singleMUL': 'Individuelle',
  'covType.singlePG': 'Individuelle',
  'covType.singlePMax': 'Individuelle',
  'covType.combined': 'Combinée',
  'covType.jftd': 'Conjointe premier décès',
  'covType.jftd.abbr': 'CPD',
  'covType.jltd': 'Conjointe dernier décès',
  'covType.jltd.abbr': 'CDD',
  'covType.jltd.costtofirstdeath': 'Conjointe dernier décès, coûts jusqu’au premier décès',
  'covType.jltd.costtolastdeath': 'Conjointe dernier décès, coûts jusqu’au dernier décès',
  'covType.jltd.lastdeath': "Conjointe dernier décès, payables jusqu'au dernier décès",
  'covType.jltd.lastdeath.abbr': 'CDD',
  'covType.jltd.firstdeath.abbr': 'CDD',

  'covOption.t10': 'Temporaire 10 ans',
  'covOption.t10.abbr': 'T10',
  'covOption.t20': 'Temporaire 20 ans',
  'covOption.t20.abbr': 'T20',
  'covOption.t65': "Temporaire jusqu'à 65 ans",
  'covOption.t65.abbr': 'T65',
  'covOption.tlife': 'Temporaire viagère',
  'covOption.tlife.abbr': 'TVie',

  'deposit.label': 'Dépôts',
  'deposit.mul.years': 'Années',
  'deposit.ownerresidence': 'Lieu de résidence du titulaire du contrat',
  'deposit.ownerresidence.error': 'Obligatoire',
  'deposit.ownerresidence.select': 'Sélectionnez',
  'deposit.ownerresidence.alberta': 'Alberta',
  'deposit.ownerresidence.britishcolumbia': 'Colombie-Britannique',
  'deposit.ownerresidence.manitoba': 'Manitoba',
  'deposit.ownerresidence.newbrunswick': 'Nouveau-Brunswick',
  'deposit.ownerresidence.newfoundlandandlabrador': 'Terre-Neuve-et-Labrador',
  'deposit.ownerresidence.northwestterritories': 'Territoires du Nord-Ouest',
  'deposit.ownerresidence.novascotia': 'Nouvelle-Écosse',
  'deposit.ownerresidence.nunavut': 'Nunavut',
  'deposit.ownerresidence.ontario': 'Ontario',
  'deposit.ownerresidence.princeedwardisland': 'Île-du-Prince-Édouard',
  'deposit.ownerresidence.quebec': 'Québec',
  'deposit.ownerresidence.saskatchewan': 'Saskatchewan',
  'deposit.ownerresidence.yukon': 'Yukon',
  'deposit.ownerresidence.notapplicable': 'Sans objet',
  'deposit.frequency': 'Fréquence des dépôts',
  'deposit.type': 'Montant du dépôt',
  'deposit.type.learnmore': 'Calculs des dépôts:',
  'deposit.type.learnmore.header1': 'Nom du calcul',
  'deposit.type.learnmore.header2': 'Description',
  'deposit.type.learnmore.header3': 'Points importants',
  'deposit.type.learnmore.column1-1': 'Spécifié',
  'deposit.type.learnmore.column1-2': 'Le montant du dépôt est connu.',
  'deposit.type.learnmore.column1-3': ' ',
  'deposit.type.learnmore.column2-1': 'Maximum uniforme',
  'deposit.type.learnmore.column2-2':
    'Étant donné un montant d’assurance spécifié, le calcul fournit le dépôt maximum uniforme pour la durée spécifiée.',
  'deposit.type.learnmore.column2-3-1': 'Le calcul tient compte du coût des garanties complémentaires.',
  'deposit.type.learnmore.column2-3-2': 'Il est impossible de sélectionner un dépôt de 1ʳᵉ année supplémentaire.',
  'deposit.type.learnmore.column2-3-3': 'Des retraits peuvent être sélectionnés, mais le calcul n’en tient pas compte.',
  'deposit.type.learnmore.column2-3-4': 'Le compte auxiliaire n’est pas utilisé.',
  'deposit.type.learnmore.column2-3-5':
    'Le calcul peut donner lieu à un avis de déchéance : « Ce contrat tombera en déchéance. Veuillez augmenter la durée du dépôt. »',
  'deposit.type.learnmore.column3-1': 'Minimum uniforme',
  'deposit.type.learnmore.column3-2':
    'Étant donné un montant d’assurance spécifié, le calcul fournit le dépôt minimum uniforme pour la durée spécifiée.',
  'deposit.type.learnmore.column3-3-1': 'Le calcul tient compte du coût des garanties complémentaires.',
  'deposit.type.learnmore.column3-3-2':
    'Il est possible de sélectionner un dépôt de 1ʳᵉ année additionnel; le calcul en tient compte.',
  'deposit.type.learnmore.column3-3-3': 'Il est impossible de sélectionner des retraits.',
  'deposit.type.learnmore.column3-3-4':
    'Le compte auxiliaire est utilisé de façon limitée pour maintenir le contrat en vigueur.',
  'deposit.type.learnmore.column3-3-5':
    'Le calcul peut donner lieu à un avis de déchéance : « Ce contrat tombera en déchéance. Veuillez augmenter la durée du dépôt. »',
  'deposit.type.learnmore.column4-1': 'Minimum annuel',
  'deposit.type.learnmore.column4-2':
    'Étant donné un montant d’assurance spécifié, le calcul fournit le dépôt minimum annuel.',
  'deposit.type.learnmore.column4-3-1': 'Le calcul tient compte du coût des garanties complémentaires.',
  'deposit.type.learnmore.column4-3-2':
    'Il est possible de sélectionner un dépôt de 1ʳᵉ année additionnel, mais le calcul n’en tient pas compte.',
  'deposit.type.learnmore.column4-3-3': 'Il est impossible de sélectionner des retraits.',
  'deposit.type.learnmore.column4-3-4': 'Le compte auxiliaire n’est pas utilisé.',
  'deposit.type.specified': 'Spécifié',
  'deposit.type.levelmax': 'Maximum uniforme',
  'deposit.type.levelmin': 'Minimum uniforme',
  'deposit.type.yearlymin': 'Minimum annuel',
  'deposit.amount.monthly': 'Montant mensuel',
  'deposit.amount.annual': 'Montant annuel',
  'deposit.duration': 'Durée',
  'deposit.to.age': "Jusqu'à l'âge de",
  'deposit.additional.deposit': 'Inclure un dépôt additionnel la 1re année',
  'deposit.additional.deposit.inforce': 'Dépôt supplémentaire pour l’année en cours',
  'deposit.additional.deposit.inforce.PG': 'Paiement supplémentaire pour l’année en cours',
  'deposit.additional.deposit.inforce.par': 'Illustrer un paiement option Dépôt maintenant',
  'deposit.mul.lvlmax': 'Max. uniforme ',
  'deposit.mul.lvlmin': 'Min. uniforme ',
  'summary.depositoption.MUL.yearly': 'Minimum annuel ',

  'depositoption.label': "Paiements de l'option Dépôts (OD)",
  'additionalpayments.label': 'Paiements additionnels',
  // 'depositoption.type': 'Type',
  'depositoption.type.specified': 'Spécifié',
  'depositoption.type.levelmax': 'Uniforme maximum',
  'depositoption.type.none': 'Aucun',
  // 'depositoption.amount': 'Montant',
  'depositoption.amount.monthly': 'Montant (par mois)',
  'depositoption.amount.annually': 'Montant (par année)',
  'depositoption.amount.quarterly': 'Montant (par trimestre)',
  'depositoption.amount.semi-annually': 'Montant (par semestre)',
  'depositoption.duration': 'Durée',
  'depositoption.duration.years': 'Pendant',
  'depositoption.duration.sameasbase': 'Même que couv. de base',
  'depositoption.custom': 'Variable',

  'divOption.pui': 'Assurance libérée',
  'divOption.cash': 'En espèces',
  'divOption.PaidUpInsurance': 'Assurance libérée',
  'divOption.Performaxcash': 'Encaissement',
  'divOption.termOption': 'Option Temporaire',
  'divOption.Accumulation': 'Accumulation',
  'divOption.AccumulationMaintainExistingPUI': 'Capitalisation, maintien de l’assurance libérée existante',
  'divOption.AccumulationTransferPUIToAccumulation':
    'Capitalisation, virement de l’assurance libérée au compte de capitalization',
  'divOption.PremiumReduction': 'Réduction des primes',

  'coverage.toFaceAmount': 'Capital assuré de l’OT',
  'coverage.topFaceAmount': 'Capital assuré de l’OTP',
  'coverage.option.termOptionPlus': 'Option Temporaire Plus',
  'coverage.pMax.costDuration': `Coûts jusqu'à 99 ans`,
  'coverage.blank': ' ',
  'dividendscale.label': 'Hypothèse relative au barème des participations ',
  'dividendscale.concept': 'Barème des participations',
  'dividendscale.primary': 'Barème des participations principal',
  'dividendscale.alternate': 'Autre barème des participations',
  'divscale.current': 'Actuel',
  'divscale.lesshalf': 'Actuel moins 0,50 %',
  'divscale.lessone': 'Actuel moins 1,0 %',
  'divscale.lessonehalf': 'Actuel moins 1,5 %',
  'divscale.lesstwo': 'Actuel moins 2,0 %',
  'divscale.lesstwohalf': 'Actuel moins 2,5 %',
  'conceptdivscale.primary': 'Principal',
  'conceptdivscale.alternate': 'Autre',

  'divscale.Performax.lesshalf': 'Actuel moins 0,5%',
  'divscale.Performax.lessone': 'Actuel moins 1,0%',
  'divscale.Performax.lessonehalf': 'Actuel moins 1,5%',
  'divscale.Performax.lesstwo': 'Actuel moins 2,0%',
  'divscale.Performax.lesstwohalf': 'Actuel moins 2,5%',

  'premiumDuration.pay10': 'Pendant 10 ans',
  'premiumDuration.pay20': 'Pendant 20 ans',
  'premiumDuration.Pay90': `Jusqu'à 90 ans`,
  'premiumDuration.Pay100': `Jusqu'à 100 ans`,

  'costDuration.pay100': `Jusqu'à 100 ans`,
  'costDuration.pay15': 'Pendant 15 ans',

  'pcOption.pui': 'Assurance libérée',
  'pcOption.accumulatedInterest': 'Compte de capitalisation',

  'vitality.na': 'S/O',
  'vitality.platinum': 'Platine',
  'vitality.gold': 'Or',
  'vitality.silver': 'Argent',
  'vitality.bronze': 'Bronze',
  'vitality.remove': 'Retirer',
  'vitality.year': 'An',
  'vitality.age': 'Âge',
  'vitality.status': 'Niveau Vitalité',

  'party.firstName': 'Prénom',
  'party.first': 'Premier',
  'party.lastName': 'Nom de famille',
  'party.last': 'Dernier',
  'party.dob': 'Date de naissance',
  'party.age': 'Âge',
  'party.healthstyle': 'Indice-santé (IS)',
  'party.sex': 'Sexe',
  'party.smokingstatus': 'Statut fumeur',
  'coverage.planType': 'Type de régime',
  'coverage.planType.Performer': 'Performer',
  'coverage.planType.PerformaxI': 'Performax I',
  'coverage.planType.PerformaxII': 'Performax II',
  'coverage.planType.Performax88': 'Performax 88',
  'coverage.planType.Performax90': 'Performax 90',
  'coverage.planType.Performax92': 'Performax 92',
  'coverage.planType.Performax93': 'Performax 93',
  'coverage.planType.Performax94': 'Performax 94',
  'coverage.planType.Performax2003': 'Performax 2003',
  'coverage.planType.SecurityBuilder': 'Sécuriplan',

  'dividendLabel.toFaceAmount': 'Capital assuré de l’OT ',

  'coverage.type': 'Type de couverture',
  'coverage.par.type': 'Type',
  'coverage.mul.dateOfIllustration': 'Date du projet informatisé: ',
  'coverage.mul.policyDate': 'Date du contrat: ',
  'coverage.mul.deathBenefitType': 'Type de capital-décès',
  'coverage.mul.deathTypes.facePlus': 'Capital assuré majoré',
  'coverage.mul.deathTypes.facePlusSummary': 'Capital-décès de type capital assuré majoré',
  'coverage.mul.deathTypes.level': 'Uniforme',
  'coverage.mul.deathTypes.levelSummary': 'Capital-décès uniforme',
  'coverage.mul.deathTypes.accValEachDeath': 'Valeur des comptes à chaque décès',
  'coverage.mul.AVDeathPercentage': '% de la VC au premier décès',
  'coverage.mul.coiTypes.levelToAge100': "CDA uniforme jusqu'à 100 ans",
  'coverage.mul.coiTypes.levelFor10Years': 'CDA uniforme pendant 10 ans',
  'coverage.mul.coiTypes.levelFor20Years': 'CDA uniforme pendant 20 ans',
  'coverage.mul.coiTypes.yrtToAge8520Year': 'TR1 jusqu’à 85 ans/pendant 20 ans',
  'coverage.mul.coiTypes.yrtToAge85': 'TR1 jusqu’à 85 ans',
  'coverage.mul.coiTypes.yrtToAge100': 'TR1 jusqu’à 100 ans',
  'coverage.mul.coiType': 'Type de coût de l’assurance (CDA)',
  'coverage.mul.coiSwitch': 'Passer à un CDA uniforme',
  'coverage.mul.coiSwitchSummary': 'Passer à un CDA uniforme l’année',
  'coverage.mul.coi.atYear': "À l'année",
  'coverage.mul.insurTypes.specified': 'Spécifié',
  'coverage.mul.insurTypes.minLevel': 'Couverture minimum',
  'coverage.mul.insurTypes.maxLevel': 'Couverture maximum',
  'coverage.option': 'Option de couverture',
  'coverage.amount': 'Montant',
  'coverage.label': 'COUVERTURE',
  'coverage.frequency': 'Périodicité de la prime',
  'coverage.vitality': 'Niveau Vitalité{superscript}',
  'coverage.vitality.nosuperscript': 'Niveau Vitalité',
  'coverage.with': 'Avec',
  'coverage.vitalityPlus': 'Vitalité Plus',
  'coverage.changeToBronze': 'Passage à Bronze après ans',
  'coverage.fromyear': "À partir de l'année",
  'coverage.toyear': "Jusqu'à l'année",
  'coverage.coverageAmount': 'Montant de couverture',
  'coverage.par.vitalityPlus.learnmore':
    'Vie Manuvie avec participation avec <b><i>Vitalité Plus</i></b> est offerte uniquement dans le cadre d’une couverture individuelle.',
  'coverage.mul.vitalityPlus.learnmore':
    'VU Manuvie assortie de <b><i>Vitalité Plus</i></b> est offerte uniquement dans le cadre d’une couverture individuelle.',
  'coverage.mul.vitalityPlus.learnmore2':
    '<b><i>Vitalité Plus</i></b> n’est pas offert s’il s’agit d’une couverture conjoint dernier décès.',
  'coverage.mul.vitalityStatus.learnmore.row-1':
    'Réduction Vitalité sur la couverture d’assurance pendant la période de rajustement des primes:',
  'coverage.mul.vitalityStatus.learnmore.row-2':
    'Le dépôt minimum pour la première année représente une réduction de 10 %. Cette réduction peut changer après la première année, en fonction du niveau Vitalité de la personne assurée chaque année.',
  'coverage.mul.vitalityStatus.learnmore.row-3':
    'La valeur par défaut illustrée est le niveau Or, que vous pouvez modifier à compter de la deuxième année. À compter de la troisième année, vous pouvez illustrer un passage au niveau Bronze.',
  'coverage.mul.vitalityStatus.learnmore.row-4':
    'Pour obtenir des conseils sur le niveau Vitalité à indiquer dans l’illustration, référez-vous à la page ',
  'coverage.mul.vitalityStatus.learnmore.healthyLiving': 'Simulateur de Récompenses et Statut Manuvie <i>Vitalité</i>',
  'coverage.mul.vitalityStatus.learnmore.column1-1': 'Niveau Platine:',
  'coverage.mul.vitalityStatus.learnmore.column1-2': '<b>15 %</b>',
  'coverage.mul.vitalityStatus.learnmore.column2-1': 'Niveau Or:',
  'coverage.mul.vitalityStatus.learnmore.column2-2': '<b>10 %</b>',
  'coverage.mul.vitalityStatus.learnmore.column3-1': 'Niveau Argent:',
  'coverage.mul.vitalityStatus.learnmore.column3-2': '<b>2 %</b>',
  'coverage.mul.vitalityStatus.learnmore.column4-1': 'Niveau Bronze:',
  'coverage.mul.vitalityStatus.learnmore.column4-2': '<b>0 %</b>',
  'coverage.ft.vitalityPlus.learnmore':
    'Temporaire Famille assortie de <b><i>Vitalité Plus</i></b> n’est offerte que dans le cadre de la couverture individuelle.',
  'coverage.ft.vitalityPlus.learnmore2':
    '<b><i>Vitalité Plus</i></b> n’est pas offert s’il s’agit d’une couverture conjointe ou si elle comprend plus d’un client.',
  'coverage.ft.vitalityStatus.learnmore.row-1':
    'Réduction Vitalité sur la couverture d’assurance pendant la période de rajustement des primes :',
  'coverage.ft.vitalityStatus.learnmore.row-2':
    'La prime initiale représente une réduction de 10 % lors de la première année.',
  'coverage.ft.vitalityStatus.learnmore.row-2-2':
    'Les primes peuvent changer chaque année, à la hausse ou à la baisse, pendant la période de rajustement de la prime, en fonction du niveau Vitalité de la personne assurée.',
  'coverage.ft.vitalityStatus.learnmore.row-3':
    'La valeur par défaut illustrée est le niveau Or que vous pouvez personnaliser.',
  'coverage.par.coverageAmount': "Montant d'assurance",
  'coverage.par.coverageAmount.learnmore': 'Calculs de la couverture',
  'coverage.par.coverageAmount.learnmore.header1': 'Nom du calcul',
  'coverage.par.coverageAmount.learnmore.header2': 'Description',
  'coverage.par.coverageAmount.learnmore.header3': 'Points importants',
  'coverage.par.coverageAmount.learnmore.column1-1': 'Spécifié',
  'coverage.par.coverageAmount.learnmore.column1-2': 'Le montant d’assurance est connu.',
  'coverage.par.coverageAmount.learnmore.column1-3': '',
  'coverage.par.coverageAmount.learnmore.column2-1': 'Maximum uniforme',
  'coverage.par.coverageAmount.learnmore.column2-2':
    'Étant donné un montant de dépôt et une durée spécifiés, le calcul fournit le montant d’assurance uniforme maximum.',
  'coverage.par.coverageAmount.learnmore.column2-3-1': 'Le calcul tient compte du coût des garanties complémentaires.',
  'coverage.par.coverageAmount.learnmore.column2-3-2':
    'Il est impossible de sélectionner un dépôt de 1ʳᵉ année supplémentaire.',
  'coverage.par.coverageAmount.learnmore.column2-3-3': 'Il est impossible de sélectionner des retraits.',
  'coverage.par.coverageAmount.learnmore.column2-3-4':
    'Le compte auxiliaire est utilisé de façon limitée pour maintenir le contrat en vigueur.',
  'coverage.par.coverageAmount.learnmore.column2-3-5':
    'Un message d’erreur s’affichera si le dépôt spécifié entraîne un montant d’assurance supérieur aux limites du produit.',
  'coverage.par.coverageAmount.learnmore.column3-1': 'Minimum uniforme',
  'coverage.par.coverageAmount.learnmore.column3-2':
    'Étant donné un montant de dépôt et une durée spécifiés, le calcul fournit le montant d’assurance uniforme minimum.',
  'coverage.par.coverageAmount.learnmore.column3-3-1': 'Le calcul tient compte du coût des garanties complémentaires.',
  'coverage.par.coverageAmount.learnmore.column3-3-2':
    'Il est impossible de sélectionner un dépôt de 1ʳᵉ année supplémentaire.',
  'coverage.par.coverageAmount.learnmore.column3-3-3': 'Il est impossible de sélectionner des retraits.',
  'coverage.par.coverageAmount.learnmore.column3-3-4': 'Le compte auxiliaire est utilisé.',
  'coverage.par.coverageAmount.learnmore.column3-3-5':
    'Un message d’erreur s’affichera si le dépôt spécifié entraîne un montant d’assurance supérieur aux limites du produit.',
  'coverage.solveForCoverage': 'Calculer en saisissant un montant de prime',
  'coverage.includeMaxDepositOption': 'Inclure l’option Dépôts?',
  'coverage.solveForCoverage.warning':
    'L’option Calcul de la couverture n’est offerte que dans le cas d’une couverture individuelle. Le montant calculé a été changé pour un montant de couverture spécifié.',
  'coverage.premiumAmount': 'Montant de la prime',
  'coverage.premiumduration': 'Durée des primes',
  'coverage.costduration': 'Durée du coût',
  'coverage.pcoption': 'Option Crédit de rendement',
  'coverage.divoption': "Option d'affectation des participations",
  'coverage.switchtocash': 'Changer pour Versement en espèces',
  'coverage.switchtocashyear': 'Année',
  'coverage.marginaltaxrate': 'Taux d’imposition marginal',
  'coverage.specialQuote': 'Soumission spéciale',
  'specialQuote.opt.none': 'Aucune',
  'specialQuote.opt.S1': 'Option 1 (S1)',
  'specialQuote.opt.H1': 'Siège social (H1)',
  'specialQuote.opt.S2': 'Option 2 (S2)',

  'client.insured': 'Personne assurée',

  'rider.child': 'Enfant',
  'rider.payor': 'Payeur',
  'rider.tdw': "Exonération en cas d'invalidité (EIT)",
  'rider.tdw.payor': "Exonération en cas d'invalidité totale du payeur (EIT)",
  'rider.bvp': "Protection de la valeur de l'entreprise (PVE)",
  'rider.gio': "Option d'assurabilité garantie (OAG)",
  'rider.gir': 'GIR',
  'rider.adnd': 'Garantie décès et mutilation accidentels (DMA)',
  'rider.adb': 'Garantie décès accidentel (GDA)',
  'rider.cpr': 'Garantie protection des enfants (GPE)',
  'rider.pwdd': "Garantie exonération - décès\\'invalidité (GEDI)",
  'rider.rop': 'Garantie remboursement des primes (GRP)',
  'rider.tdw.abbr': 'EIT',
  'rider.tdw.payor.abbr': 'EIT du payeur',
  'rider.bvp.abbr': 'PVE',
  'rider.gio.abbr': 'OAG',
  'rider.gir.abbr': 'GIR',
  'rider.adnd.abbr': 'DMA',
  'rider.adb.abbr': 'GDA',
  'rider.cpr.abbr': 'GPE',
  'rider.addchild': 'Ajouter un enfant',
  'rider.remove': 'Retirer un enfant',
  'rider.unselect.all': 'Tout décocher',
  'rider.addtermrider': 'Ajouter un garantie assurance temporaire',
  'rider.title': 'Temporaire',
  'rider.tor.title': 'Garantie assurance temporaire – autre (GATA)',
  'rider.spr.title': 'Garantie protection du conjoint (GPC)',
  'rider.t05': '5 ans',
  'rider.t05.abbr': 'T05',
  'rider.t10': '10 ans',
  'rider.t10.abbr': 'T10',
  'rider.t20': '20 ans',
  'rider.t20.abbr': 'T20',
  'rider.t65': 'Uniforme (T65)',
  'rider.t65.abbr': 'T65',
  'pmax.rider.t05.abbr': 'GAT-05',
  'pmax.rider.t10.abbr': 'GAT-10',
  'pmax.rider.t65.abbr': 'GAT-65',
  'pmax.rider.tor.t05.abbr': 'GATA-05',
  'pmax.rider.tor.t10.abbr': 'GATA-10',
  'pmax.rider.tor.t65.abbr': 'GATA-65',
  'pmax.rider.spr.t05.abbr': 'GPC-05',
  'pmax.rider.spr.t10.abbr': 'GPC-10',
  'pmax.rider.spr.t65.abbr': 'GPC-65',
  'rider.duration': 'Durée (ans)',
  'riders.label': 'GAR. COMPL.',
  'rider.pwdd.abbr': 'GEDI',
  'rider.rop.abbr': 'GRP',

  'topbar.clientcoverage': 'Client et couverture',
  'topbar.spreadsheet': 'Tableur',
  'topbar.inforceRequestForm': 'Formulaire de demande spéciale',
  'topbar.help': "Besoin d'aide?",
  'topbar.help.link': 'https://conseiller.manuvie.ca/conseillers/prive/outils/projets-informatises-de-manuvie.html',
  'topbar.help.link.par':
    'https://conseiller.manuvie.ca/conseillers/prive/outils/projets-informatises-de-manuvie.html#projets-informatises-de-contrats-en-vigueur',
  'topbar.concepts': 'Concepts',
  'topbar.print': 'Imprimez la page',

  'profile.businessCard.select': 'Sélectionner une carte professionnelle',
  'profile.businessCard': 'Carte professionnelle',
  'profile.businessCard.tooltip.text1':
    'Les renseignements des cartes professionnelles sont utilisés dans le pied de page du rapport.',
  'profile.businessCard.tooltip.text2': 'Pour sélectionner une carte professionnelle :',
  'profile.businessCard.tooltip.text3': '○ Choisissez un conseiller dans la liste déroulante;',
  'profile.businessCard.tooltip.text4': '○ Entrez le nom du conseiller dans le champ de recherche.',
  'profile.businessCard.tooltip.text5': 'Pour ajouter, modifier ou supprimer une carte professionnelle :',
  'profile.businessCard.tooltip.text6': '1. Cliquez sur le bouton <b>Rapports</b>.',
  'profile.businessCard.tooltip.text7': '2. Sélectionnez <b>Personnaliser</b>.',
  'profile.businessCard.tooltip.text8':
    '3. Faites vos changements dans la section « Gérer les cartes professionnelles ».',

  'report.addNew': 'Ajouter',
  'report.back': 'Retour aux project informatisé',
  'report.options': 'Options de rapport',
  'report.language': 'Langue du rapport',
  'report.layout': 'Type de rapport',
  'report.layout.allyears': 'Toutes les années',
  'report.layout.condensed': 'Rapport condensé',
  'report.preparedfor': 'Préparé pour',
  'report.label.pages': 'Pages du rapport',
  'report.label.footer': 'Gérer les cartes professionnelles',
  'report.businessCard': 'Afficher les renseignements de la carte professionnelle dans le pied de page',
  'report.businessCard.required': 'Obligatoire',
  'report.businessCard.delete.title': 'Supprimer une carte professionnelle',
  'report.businessCard.delete.message': 'Souhaitez-vous vraiment supprimer cette carte professionnelle?',
  'report.businessCard.tooltip1':
    'Ces renseignements seront utilisés pour le projet informatisé et les rapports connexes des conseillers seulement.',
  'report.businessCard.tooltip2': 'Pour sélectionner une carte professionnelle :',
  'report.businessCard.tooltip3': '○ Choisissez un conseiller dans la liste déroulante;',
  'report.businessCard.tooltip4': '○ Entrez le nom du conseiller dans le champ de recherche.',
  'report.selectall': 'Sélectionner tout',
  'report.underwriting': "Preuves d'assurabilité",
  'report.productpage': 'Page produit',
  'report.commission': 'Sommaire des commissions',
  'report.standalone': 'Rapports du Conseiller',
  'report.option.cpd': 'Détail des couvertures et des primes',
  'report.option.cd': 'Détail de la couverture',
  'report.option.irr': 'Valeurs relatives au taux de rendement interne (TRI)',
  'report.option.pd': 'Renseignements sure le contrat',
  'report.option.pod': "Détails de l'éclipse de prime",
  'report.option.pos': "Sommaire de l'éclipse de prime",
  'report.option.ps': 'Sommaire du produit et notes importantes sure le projet informatisé',
  'report.option.rp': 'Primes des garanties complémentaires',
  'report.option.td': ' Valeurs fiscales',
  'report.option.tcop': 'Données fiscales pour les contrats détenus par une société',
  'report.option.td.PG': 'Données fiscales',
  'report.option.tpop': 'Données fiscales pour les contrats détenus individuellement',
  'report.option.tpcd':
    "Données fiscales pour les contrats assortis de l'option Versement des participations en espèces",
  'report.option.ur': "Preuves d'assurabilité",
  'report.option.vpgnb': 'Taux des primes selon les niveaux Vitalité{superscript} Or et Bronze',
  'report.option.vp': 'Points Vitalité{superscript}',
  'report.option.vpc': 'Comparaison de la prime selon le niveau Vitalité{superscript}',
  [`report.link${eProduct.FT}`]:
    'https://conseiller.manuvie.ca/content/dam/manulife-advisor-portal/documents/fr/forms/insurance/new-business-and-underwriting/insurance-family-term-product-page-nn0799f.pdf',
  [`report.link${eProduct.BT}`]:
    'https://www.manulife.ca/content/dam/manulife-advisor-portal/documents/fr/forms/insurance/new-business-and-underwriting/manulife-insurance-family-and-business-term-product-page-nn0799f.pdf',
  [`report.link${eProduct.FTV}`]: productPageURLFTVFr,
  [`report.link${eProduct.PAR}`]:
    'https://advisor.manulife.ca/content/dam/manulife-advisor-portal/documents/fr/forms/insurance/new-business-and-underwriting/manulife-insurance-manulife-par-product-page-nn1675f.pdf',
  [`report.link${eProduct.LC}`]:
    'https://conseiller.manuvie.ca/content/dam/manulife-advisor-portal/documents/fr/forms/insurance/new-business-and-underwriting/manulife-insurance-lifecheque-product-page-nn0949f.pdf',
  [`report.link${eProduct.MUL}`]:
    'https://conseiller.manuvie.ca/content/dam/manulife-advisor-portal/documents/fr/forms/insurance/new-business-and-underwriting/manulife-insurance-manulife-ul-product-page-nn1636f.pdf',
  [`report.underwrite.link${eProduct.LC}`]:
    'https://conseiller.manuvie.ca/content/dam/manulife-advisor-portal/documents/fr/marketing-materials/insurance/new-business-and-underwriting/private/manulife-insurance-critical-illness-insurance-evidence-of-insurability-requirements-mp1033146f.pdf',
  [`report.link${eProduct.MUL + 'VP'}`]:
    'https://conseiller.manuvie.ca/content/dam/manulife-advisor-portal/documents/fr/forms/insurance/new-business-and-underwriting/insurance-manulife-ul-with-vitality-plus-product-page-nn1707f.pdf',
  [`report.link${eProduct.FT + 'VP'}`]:
    'https://conseiller.manuvie.ca/content/dam/manulife-advisor-portal/documents/fr/forms/insurance/new-business-and-underwriting/insurance-family-term-with-vitality-plus-product-page-nn1656f.pdf',
  [`report.link${eProduct.PAR + 'VP'}`]:
    'https://conseiller.manuvie.ca/content/dam/manulife-advisor-portal/documents/fr/forms/insurance/new-business-and-underwriting/insurance-manulife-par-with-vitality-plus-product-page-nn1706f.pdf',
  [`report.link${eProduct.PG}`]:
    'https://conseiller.manuvie.ca/content/dam/manulife-advisor-portal/documents/fr/forms/insurance/new-business-and-underwriting/manulife-insurance-performax-gold-product-page-nn1547f.pdf',
  'report.viewreportmessage': 'Cliquez sur Ouvrir pour afficher le rapport en format PDF dans un nouvel onglet.',
  'report.dialog.startNewAppTitle': 'Remplir une nouvelle proposition électronique',
  'report.dialog.startNewAppMsg':
    'Cliquez sur « ouvrir » pour remplir une nouvelle proposition et importer vos données de projet informatisé.',
  'report.dialog.error.dob':
    'Pour remplir une nouvelle proposition, ajoutez la date de naissance des clients et des garantie d’enfants',
  'report.dialog.error.name': 'Pour remplir une nouvelle proposition, ajoutez les noms des clients.',
  'report.dialog.businessCard.title.add': 'Ajouter une carte professionnelle',
  'report.dialog.businessCard.title.edit': 'Modifier une carte professionnelle',

  'advisor.title': 'Advisor Profile',

  'scenarioPanel.dda.startNewApp': 'Remplir une proposition électronique',

  'summary.title': 'Sommaire',
  'summary.solve': 'Calculer',
  'summary.esla': 'Âge conjoint:',
  'summary.esla.mul': 'Âge conjoint :',
  'summary.cost': 'Coût',
  'summary.coverage': 'COUVERTURE',
  'summary.premium': 'Prime',
  'summary.totalpremium': 'Prime totale',
  'summary.refresh': 'Cliquez recalculer.',
  'summary.residence': 'Résidence',
  'summary.residence.error.notselected': 'Choisissez le lieu de résidence du titulaire du contrat',
  'summary.firstYearMinDeposit': 'Dépôt minimum de 1re année',
  'summary.firstYearMaxDeposit': 'Dépôt maximum de 1re année',
  'summary.currentYearMinDeposit': 'Dépôt minimum pour l’année en cours',
  'summary.currentYearMaxDeposit': 'Dépôt maximum pour l’année en cours',
  'summary.bronzeMinDeposit': 'Dépôt minimum – Bronze',
  'summary.mul.depositoption': 'Dépôts',
  'summary.depositoption': "L'option dépôts",
  'summary.depositoption.monthly': 'Mensuelle',
  'summary.depositoption.annually': 'Annuelle',
  'summary.depositoption.MUL.monthly': 'Mensuellement',
  'summary.depositoption.MUL.annually': 'Annuellement',
  'summary.depositoption.MUL.annually.small': 'annuel ',
  'summary.depositoption.MUL.monthly.small': 'mensuel ',
  'summary.depositoption.for': ' pendant ',
  'summary.depositoption.sameasbase': 'Même que durée du coût du contrat',
  'summary.ridersummary': 'GARANTIES COMPLÉMENTAIRES',
  'summary.policyfee': 'Frais de contrat',
  'summary.vitalitycharge': 'Charge Vitalité',
  'summary.vitalitypremium': 'Prime',
  'summary.vitalitypremium2': 'Prime Vitality',
  'summary.error.vitality':
    'Seule la couverture sur une tête est offerte dans le cadre de la Temporaire Famille {vitality}. Modifiez le type de couverture “sure une tête”.',
  'summary.additionalcost': 'COÛTS ADDITIONNELS',
  'summary.additionalDepositFirstYear': 'Dépôt additionnel 1re année',
  'summary.illustrationrates': 'Taux du projet informatisé',
  'summary.dividendscale': 'Barème des participations',
  'summary.primaryrate': 'Principal',
  'summary.payments': 'PAIEMENTS',
  'summary.premiumoffset': 'Éclipse de prime',
  'summary.premiumoffsetyear.primary': `Principal : à compter de l'année XX`,
  'summary.premiumoffsetyear.alternate': `Autre : à compter de l'année XX`,
  'summary.alternaterate': 'Autre: ',
  'summary.alternate': 'Autre ',
  'summary.withdrawal': 'Retraits',
  'common.withdrawalAmount': 'Montant de retrait',
  'summary.withdrawal.for': 'pendant ',
  'summary.status': 'Le présent sommaire a été mis à jour.',
  'summary.coveragecost': 'Coût actuel de la couverture*',
  'summary.coverageeffective': "Date d'effet de la couverture",
  'summary.ageoncoverageeffective': 'Âge à la date d’effet de la couverture',
  'summary.coveragedisclaimer':
    '*Coût de la couverture seulement. Ne comprend pas les frais de contrat, la tarification, le coût de la garantie complémentaire ou la couverture additionnelle.',
  'summary.nextrenewaldate': 'Prochaine date de renouvellement',
  'summary.nextrenewalage': 'Âge au prochain renouvellement',
  'summary.coveragecostnext': 'Coût de la couverture au prochain renouvellement',
  'summary.coverageend': '*',
  'summary.defaultdate': 'jj mmm, aaaa',
  'summary.bronzeMinimum.tooltip.text':
    'Si votre client n’atteint pas un niveau Vitalité supérieur au niveau Bronze, il paiera le dépôt annuel minimum de la deuxième année. Pour le CDA uniforme seulement.',
  'summary.with': 'avec',
  'summary.currentYearBasePayment': 'Paiement de base pour l’année en cours',
  'summary.currentYearMaxPayment': 'Paiement maximum pour l’année en cours',
  'summary.pgold.plannedBasePayment': 'Paiement de base prévu',
  'summary.pgold.additionalPayments': 'Paiements additionnels',
  'summary.premiumfrequency.sameasbase': 'même que durée du coût du contrat',
  'summary.premiumfrequency.earliest': 'pendant XX ans',
  'summary.premiumfrequency.startingat': 'pendant XX ans',
  'summary.performanceCreditRate.primary': 'Principal',
  'summary.performanceCreditRate.alternate': 'Autre',
  'summary.earlycashvalueenhancer': 'Accroissement accéléré de la valeur de rachat',
  'summary.pgold.costs': 'Coûts ',
  'summary.premiumoffset.PG': 'Éclipse de paiement',
  'summary.premiumoffset.PMax': 'Éclipse de prime',
  'summary.inforce.currentyear': 'Année courante du contrat',

  'coveragecompare.compare': 'Comparaison des couvertures',
  'coveragecompare.frequency.Monthly': 'Prime mensuelle',
  'coveragecompare.frequency.Annually': 'Prime annuelle',
  'coveragecompare.term10': 'T10',
  'coveragecompare.term20': 'T20',
  'coveragecompare.disclaimer': 'En supposant que le niveau Or est atteint.',

  'basedOnAmount.label': 'Basé sur le montant',
  'payments.label': 'PAIEMENTS',
  'payments.title': 'Paiements',
  'payments.premium': 'Périodicité de la prime',
  'payments.premiumoffset': 'Éclipse de prime',
  'payments.premiumoffsetyear': "L'Année",
  'payments.frequency': 'Périodicité',
  'payments.plannedbaselabel': 'Paiement de base prévu:',
  'payments.fromyear': "De l'année",
  'payments.toyear': "Jusqu'à l'année",

  'premiumoffset.no': 'Non',
  'premiumoffset.earliest': 'Éclipse de prime la plus rapprochée',
  'premiumoffset.startingatyear': 'Débutant à',
  'premiumoffset.paymentstartingat': 'Éclipse de paiement débutant à',
  'premiumoffset.sameaspolicy': 'Même que durée du coût du contrat',
  'premiumoffset.inforce': 'En éclipse de paiment',
  'premiumoffset.inforce.yes': 'Oui – en éclipse de prime',
  'premiumoffset.inforce.no': 'Non – paiement des primes',
  'premiumoffset.paymentcoverpolicycosts': 'Paiement pour couvrir les coûts du contrat',
  'premiumoffset.premiumstartingat': 'Éclipse de prime débutant à',
  'premiumoffset.onpremiumoffsetindicator': 'En éclipse de prime',
  'premiumoffset.premiumholiday': 'Congé de prime',

  'rating.title': 'Tarification Assurance',
  'rating.perm.rate': 'Tarification permanente',
  'rating.temp.rate': 'Tarification temporaire',

  'spreadsheet.exportBtn': 'Exporter vers Excel',
  'spreadsheet.PolicyYear': 'An',
  'spreadsheet.PrimaryAttainedAge': 'Âge',
  'spreadsheet.yearAge': 'An | Âge',
  'spreadsheet.FaceAmount': 'Coverage totale',
  'spreadsheet.Monthly.BasePremium': 'Prime mensuelle totale Assurance ',
  'spreadsheet.Annually.BasePremium': 'Prime annuelle totale Assurance ',
  'spreadsheet.Monthly.RiderPremium': 'Prime mensuelle totale du contrat',
  'spreadsheet.Annually.RiderPremium': 'Prime annuelle totale du contrat',
  'spreadsheet.Monthly.ModalScheduledPremium': 'Prime mensuelle plan premium',
  'spreadsheet.Annually.ModalScheduledPremium': 'Prime annuelle plan premium',
  'spreadsheet.ScheduledPremium': 'Prime annuelle plan premium',
  'spreadsheet.Monthly.TdwDeduction': 'Prime mensuelle totale EIT',
  'spreadsheet.Annually.TdwDeduction': 'Prime annuelle totale EIT',
  'spreadsheet.Monthly.BvpDeduction': 'Prime mensuelle totale PVE',
  'spreadsheet.Annually.BvpDeduction': 'Prime annuelle totale PVE',
  'spreadsheet.Monthly.CprDeduction': 'Prime mensuelle totale GPE',
  'spreadsheet.Annually.CprDeduction': 'Prime annuelle totale GPE',
  'spreadsheet.Monthly.AdbDeduction': 'Prime mensuelle totale DMA',
  'spreadsheet.Annually.AdbDeduction': 'Prime annuelle totale DMA',
  'spreadsheet.Monthly.GioDeduction': 'Prime mensuelle totale OAG',
  'spreadsheet.Annually.GioDeduction': 'Prime annuelle totale OAG',
  'spreadsheet.TotalAnnualPolicyCost': 'Prime annuelle totale du contrat',
  'spreadsheet.ExpenseDeduction': 'Frais de contrat',
  'spreadsheet.VitalityModalFee': 'Frais de Vitalité',
  'spreadsheet.AdjustedCostBasis': 'Coût de base rajusté',
  'spreadsheet.NetCostOfPureInsurance': "Coût net de l'assurance pure",
  'spreadsheet.CdaCredit': 'Crédit CDC',
  'spreadsheet.VitalityStatus': 'Niveau Vitalité',
  'spreadsheet.TotalT10FaceAmount': 'Couverture totale Temp. 10 ans',
  'spreadsheet.Monthly.TotalT10ModalPremium': 'Prime mensuelle totale Temp. 10 ans',
  'spreadsheet.Annually.TotalT10ModalPremium': 'Prime annuelle totale Temp. 10 ans',
  'spreadsheet.TotalT20FaceAmount': 'Couverture totale Temp. 20 ans',
  'spreadsheet.Monthly.TotalT20ModalPremium': 'Prime totale Temp. 20 ans',
  'spreadsheet.Annually.TotalT20ModalPremium': 'Prime totale Temp. 20 ans',
  'spreadsheet.TotalT65FaceAmount': 'Couverture totale Temp. à 65 ans',
  'spreadsheet.Monthly.TotalT65ModalPremium': 'Prime totale Temp. à 65 ans',
  'spreadsheet.Annually.TotalT65ModalPremium': 'Prime totale Temp. à 65 ans',
  'spreadsheet.TotalTermLifeFaceAmount': 'Couverture totale Temp. viagère',
  'spreadsheet.Monthly.TotalTermLifeModalPremium': 'Prime totale Temp. viagère',
  'spreadsheet.Annually.TotalTermLifeModalPremium': 'Prime totale Temp. viagère',
  'spreadsheet.TotalPlannedPremium': 'Prime annuelle totale planifiée',

  'spreadsheet.Monthly.GuaranteedPremium': 'Prime annualisée garantie',
  'spreadsheet.Annual.GuaranteedPremium': 'Prime annuelle garantie',
  'spreadsheet.GuaranteedDeathBenefit': 'Capital-décès garanti ',
  'spreadsheet.GuaranteedInsuranceAmount': 'Montant d’assurance garanti',
  'spreadsheet.GuaranteedCashValue': 'Valeur de rachat garantie',
  'spreadsheet.IllustatedDepositPayment': 'Paiement de l’option dépôts indiqué dans le projet',
  'spreadsheet.MaxAnnualDepositPayment': 'Plafond annuel de l’option dépôts',
  'spreadsheet.AnnualDividend': 'Versement de participations annuel (scénario principal)',
  'spreadsheet.PaidUpInsurance': 'Assurance libérée (scénario principal)',
  'spreadsheet.DepositOptionInsurance': 'Assurance de l’option dépôts (scénario principal)',
  'spreadsheet.DividendCash': 'Versement de participations en espèces (scénario principal)',
  'spreadsheet.IRRDeathBenefit': 'TRI du capital-décès % (scénario principal)',
  'spreadsheet.IRRTotalCashValue': 'TRI de la valeur de rachat totale % (scénario principal)',
  'spreadsheet.AnnualDividendAlternate': 'Versement de participations annuel (autre scénario)',
  'spreadsheet.DividendCashAlternate': 'Versement de participations en espèces (autre scénario)',
  'spreadsheet.PaidUpInsuranceAlternate': 'Assurance libérée (autre scénario)',
  'spreadsheet.DepositOptionInsuranceAlternate': 'Assurance de l’option Dépôts (autre scénario)',
  'spreadsheet.IRRDeathBenefitAlternate': 'TRI du capital-décès % (autre scénario)',
  'spreadsheet.IRRTotalCashValueAlternate': 'TRI de la valeur de rachat totale % (autre scénario)',
  'spreadsheet.AdjustedCostBasisAlternate': 'Coût de base rajusté (autre scénario)',
  'spreadsheet.NetCostOfPureInsuranceAlternate': "Coût net de l'assurance pure (autre scénario)",
  'spreadsheet.CdaCreditAlternate': 'Crédit au compte de dividendes en capital (autre scénario)',
  'spreadsheet.TermRiderInsurance': 'Montant d’assurance de la garantie assurance temporaire - 1 - ',
  'spreadsheet.TDWRiderPremium': 'Prime de la garantie assurance temporaire - 1 -',
  'spreadsheet.GIORiderPremium': 'Prime de la garantie OAG pour [Client Name]',
  'spreadsheet.PayorWaverRider': 'Coût de la garantie exonération en cas d’invalidité totale - Payeur',
  'spreadsheet.ChildProtectionRider': 'Prime de la garantie protection des enfants',
  'spreadsheet.RequestedBeforeCash': 'Retraits demandés avant impôt',
  'spreadsheet.Withdrawal': 'Retrait',
  'spreadsheet.TaxableWithdrawal': 'Retrait imposable',
  'spreadsheet.NetWithdrawal': 'Retrait net',
  'spreadsheet.TaxableCancellationValue':
    'Partie imposable de la valeur de rachat à la résiliation (scénario principal)',
  'spreadsheet.TaxableCashDividend':
    'Partie imposable de l’option versement des participations en espèces (scénario principal)',
  'spreadsheet.TaxOnCancellationValue': 'Impôt sur la valeur de rachat à la résiliation (scénario principal)',
  'spreadsheet.CancellationCashValue': 'Valeur de rachat à la résiliation après impôt (scénario principal)',
  'spreadsheet.TaxableCancellationValueAlternate':
    'Partie imposable de la valeur de rachat à la résiliation (autre scénario)',
  'spreadsheet.TaxableCashDividendAlternate':
    'Partie imposable de l’option versement des participations en espèces (autre scénario)',
  'spreadsheet.TaxOnCancellationValueAlternate': 'Impôt sur la valeur de rachat à la résiliation (autre scénario)',
  'spreadsheet.CancellationCashValueAlternate': 'Valeur de rachat à la résiliation après impôt (autre scénario)',
  'spreadsheet.MarginalTaxRate': 'Taux d’imposition marginal (%)',
  'coverage.policyInterestRate': 'Taux d’intérêt du contrat',
  'coverage.sideAccountInterestRate': 'Taux d’intérêt du Compte auxiliaire',
  'coverage.performanceCreditRate': 'Taux du crédit de rendement',
  'coverage.altPerformanceCreditRate': 'Autre taux du crédit de rendement',
  'coverage.performanceCreditRateURL':
    'https://conseiller.manuvie.ca/content/dam/manulife-advisor-portal/documents/fr/marketing-materials/insurance/performax-gold/insurance-performax-gold-performance-credit-ratef.pdf',
  'spreadsheet.Refund': 'Remboursement au titulaire de contrat',
  'spreadsheet.AdjustedCostBasisPrimary': 'Coût de base rajusté (scénario principal)',
  'spreadsheet.NetCostOfPureInsurancePrimary': "Coût net de l'assurance pure (scénario principal)",
  'spreadsheet.CdaCreditPrimary': 'Crédit au compte de dividendes en capital (scénario principal)',
  'spreadsheet.TaxOnWithdrawal': 'Impôt sur le retrait',
  'spreadsheet.recalculate': 'Recalculer les Colonnes',
  'spreadsheet.recalculate.sr': 'Cliquez sur Entrée',
  'spreadsheet.error': 'Le paiement de l’OD demandé dépasse la limite annuelle',
  'spreadsheet.filldown.sr': 'Cliquez sur MAJ + Entrée',
  'spreadsheet.fullscreen': 'Mode plein écran',
  'spreadsheet.exitfullscreen': 'Quitter mode plein écran',

  'mycases.illustration.btn': 'Nouveau projet informatisé',
  'mycases.table.header.Illustration': 'Project informatisé',
  'mycases.table.header.Illustration.formfield': 'Pour lancer la recherche, entrer le nom du projet informatisé',
  'mycases.table.header.Illustration.sortbutton': 'Trier le projet informatisé par ordre alphabétique',
  'mycases.table.header.sortAscending': 'Classé en ordre chronologique',
  'mycases.table.header.sortDescending': 'Classé en ordre ante chronologique',
  'mycases.table.header.clientname': 'Nom du client',
  'mycases.table.header.datesaved': 'Date de l’enregistrement',
  'mycases.table.header.datesaved.formfield': 'Trier le projet informatisé par date d’enregistrement',
  'mycases.table.header.datesaved.sortbutton': 'Trier le projet informatisé par date d’enregistrement',
  'mycases.table.header.concept': 'Concept',
  'mycases.table.header.product': 'Produit',
  'mycases.table.header.action': 'Mesure à prendre',
  'mycases.table.header.action.opencase': 'Ouvrir le projet informatisé',
  'mycases.table.header.action.deletecase': 'Supprimer le projet informatisé',
  'mycases.dialog.delete.illustrations':
    'Souhaitez-vous vraiment supprimer le(s) projet(s) informatisé(s) sélectionné(s)?',
  'mycases.dialog.delete.applications': 'Souhaitez-vous vraiment supprimer la (les) proposition(s) sélectionnée(s)?',
  'mycases.deleteIllustration': 'Supprimer le projet informatisé',
  'mycases.deleteApplication': 'Supprimer la proposition',
  'mycases.deleteIllustrationNotification': 'Les illustration(s) ont été supprimées avec succès.',
  'mycases.deleteApplicationNotification': 'Les demandes ont été supprimées avec succès. ',
  'mycases.dialog.status.updatedToFT':
    'Votre projet informatisé a été mis à jour pour tenir compte de la Temporaire Famille',
  'mycases.dialog.status.andFT': ' et Temporaire Famille',
  'mycases.dialog.status.withFTV': ' assortie de Vitalité Plus',

  'footer.quicklinks': 'Liens utiles',
  'footer.advisorGuide': 'Guide du conseiller',
  'footer.copyright': 'La Compagnie d’Assurance-Vie Manufacturers',
  'footer.termms': 'Terms & Conditions',
  'footer.termsLink': 'http://mmanulife.ca',
  'footer.legal': 'Notice juridique',
  'footer.legalLink': 'https://www.manulife.com/fr/legal.html',
  'footer.privacy': 'Politique de confidentialité',
  'footer.privacyLink': 'https://www.manuvie.ca/politiques-de-confidentialite.html',
  'footer.accessibility': 'Accessibilité',
  'footer.accessibilityLink': 'https://www.manuvie.ca/a-propos-de-nous/accessibilite.html',
  'footer.manulife': 'Manuvie.ca',
  'footer.manulife.caLink': 'https://www.manuvie.ca',
  'footer.career': 'Carrières',
  'footer.career.hyperlink': 'https://www.manulife.com/fr/careers/why-join-us.html',

  'help.coverageSolve': 'Calcul de la couverture',
  'help.comingSoon': 'À venir bientôt :',
  'help.solveForCoverage':
    'L’option {coverageSolve} est offerte lorsque vous créez un projet informatisé. Entrez un montant de prime et l’outil de projets informatisés déduit tous les frais et coûts des garanties complémentaires pour calculer le montant d’assurance que le client peut souscrire avec la différence. Les exigences en matière de couverture minimale s’appliquent.',
  'help.solveForCoverage.Par':
    'Entrez un montant de prime et l’outil de projets informatisés déduiront le coût de toute garantie complémentaire et calculeront le montant d’assurance qu’il est possible de souscrire. Le minimum de produit s’applique.',
  'help.customizeVitality1': 'Pour personnaliser un niveau Vitalité',
  'help.customizeVitality2': ', cliquez sur le bouton ',
  'help.customizeVitality3': 'Personnaliser',
  'help.customizeVitality4':
    'Vous pouvez modifier le niveau Vitalité par année à partir de la deuxième année. Lorsque vous cliquez sur ',
  'help.customizeVitality5': 'Remplir tous les champs',
  'help.customizeVitality6': ' , tous les champs seront mis à jour en fonction de l’option que vous avez choisie.',
  'help.customizeVitality7': "l'Option de couverture",
  'help.customizeVitality8': ' ou ',
  'help.customizeVitality9': "l'âge",
  'help.customizeVitality10': ' du client.',
  'help.healthstyle':
    'Si votre client est non-fumeur, nous vous recommandons de choisir l’indice-santé 3 pour le projet informatisé et pour déterminer le paiement à percevoir. Nous offrons les indices-santé 1 et 2 pour des âges et des montants spécifiques. Nous devons tout de même procéder à la tarification pour déterminer l’admissibilité. ',
  'help.healthstyle1': 'Vous pouvez ajouter une surprime si votre client a un indice-santé 3, 4 ou 5.',
  'help.healthstyle2': 'Indice‑santé 1 :  Non‑fumeurs représentant le risque le plus faible',
  'help.healthstyle3': 'Indice‑santé 2 :  Non‑fumeurs  représentant un risque plus faible que la normale',
  'help.healthstyle4': 'Indice‑santé 3 :  Non‑fumeurs  représentant un risque normal',
  'help.healthstyle5':
    'Indice‑santé 4 :  Consommateurs de produits à base de tabac ou de nicotine autres que la cigarette ou la cigarette électronique',
  'help.healthstyle6': 'Indice‑santé 5 :  Consommateurs de cigarettes ou utilisateurs de cigarettes électroniques',
  'help.smokingstatus': 'Smoking status help.',
  'help.levelmax':
    "Le type de paiement uniforme maximum pour la Durée des primes pendant 10 fonctionne seulement lorsqu'une garantie d'assurance temporaire est sélectionnée. Autrement, choisissez 'Spécifié' et utilisez l'option Personnaliser. Il se peut qu'il ne soit pas passible de faire des paiements de l'OD Durant les premiers dix ans. Les paiements de l'OD peuvent être faits après la fin de la durée des primes.",
  'help.summary':
    'Le sommaire vous donne un aperçu interactif de la prime. Si vous voyez l’icône du message d’erreur (x), c’est qu’il y a une erreur. Vous ne pourrez pas produire un projet informatisé ou consulter la feuille de calcul, et les primes ne seront pas à jour, tant que vous n’aurez pas corrigé l’erreur.',

  'ftrc.calculator.header.label': 'Avant de commencer :',
  'ftrc.calculator.header.details1A': 'Ayez en main les renseignements sur l’assuré. Vous pouvez les trouver dans ',
  'ftrc.calculator.header.details1.linkText': 'Mes clients',
  'ftrc.calculator.header.details1.link': 'https://conseiller.manuvie.ca/myclients',
  'ftrc.calculator.header.details1B': ' ou dans d’autres documents d’assurance.',
  'ftrc.calculator.header.details2A':
    'Assurez-vous que le contrat comprend une couverture Temporaire 10 ans avec une date d’effet entre le 1',
  'ftrc.calculator.header.details2B': 'er',
  'ftrc.calculator.header.details2C': ' mai 2010 et le 4 octobre 2013.',
  'ftrc.calculator.footer.message.1':
    'Le calculateur de renouvellement de la Temporaire Famille détermine les coûts actuels et les coûts de renouvellement en fonction des renseignements que vous fournissez. Vous pouvez entrer le même montant de couverture actuel ou un montant de couverture réduit. Le coût actuel de la couverture indiqué dans le Sommaire est basé sur le montant de couverture que vous entrez dans le calculateur.',
  'ftrc.calculator.footer.message.2':
    'Si vous entrez un montant de couverture identique à celui du contrat et s’il y a une divergence entre les coûts indiqués par le calculateur et ceux du contrat, les valeurs du contrat prévalent toujours. Si vous entrez un montant de couverture réduit, le nouveau montant de couverture est assujetti aux règles administratives actuelles.',
  'ftrc.calculator.footer.message.3':
    'Les montants indiqués ne constituent pas un projet informatisé complet ni une offre d’assurance. ',
  'ftrc.calculator.footer.message.4': 'Réservé aux conseillers.',

  'ftrc.calculatorInput.product': 'Produit : Temporaire Famille',
  'ftrc.calculatorInput.coverageType': 'Type de couverture : Sur une tête',
  'ftrc.calculatorInput.coverageOption': 'Option de couverture : Temporaire 10 ans',
  'ftrc.calculatorInput.coverageEffectiveDate.label': 'Date d’effet de la couverture',
  'ftrc.calculatorInput.coverageAmount.label': 'Montant de couverture',
  'ftrc.calculatorInput.insuredInformation': 'Renseignements sur l’assuré',
  'ftrc.calculatorInput.sex.Label': 'Sexe',
  'ftrc.calculatorInput.dob.label': 'Date de naissance',
  'ftrc.calculatorInput.help.healthstyle': 'Il s’agit de l’indice-santé établi le plus récent de l’assuré.',
  'ftrc.calculatorInput.help.coverage.amount':
    'Vous pouvez entrer le montant de couverture actuel ou un montant de couverture réduit.',
  'ftrc.calculatorInput.help.coverage.effective.date.1':
    'Vous avez besoin de la date d’effet de la couverture pour calculer les coûts de renouvellement exacts. Consultez Mes clients ou les documents d’assurance pour trouver cette date. La date d’effet de la couverture peut différer de la date du contrat.',
  'ftrc.calculatorInput.help.coverage.effective.date.2': 'Exemple tiré de Mes clients :',

  'corporate.IRP': 'Plan d’assurance-retraite – Sociétés',
  'corporate.EB': 'Bon patrimonial - Sociétés',
  'corporate.NONE': 'Aucun',
  'concept.personal.IRP': 'Plan d’assurance-retraite – Particuliers',
  'concept.personal.EB': 'Bon patrimonial - Particuliers',

  'concept.title': 'Concepts',
  'concept.tabTitle': 'CONCEPTS',
  'concept.selectConcept': 'Sélectionnez un concept',
  'concept.alternateInvestment.label': 'Autre placement',
  'concept.taxInformation.label': 'Renseignements fiscaux',
  'concept.corporateIRPStructure': 'Structure du PAR de société',
  'concept.lifeExpectancyAssumption.label': 'Hypothèses relatives à l’espérance de vie',
  'concept.loaninformation.label': 'Renseignements sur le prêt',
  'concept.corporateTaxRate': "Taux d'imposition de la société",
  'concept.personalDividendTaxRate': 'Taux d’imp. dividendes du particulier',
  'concept.altIntOptInterest': 'Intérêts',
  'concept.altIntOptDividends': 'Dividendes',
  'concept.altIntOptDefCapGains': 'Gains en capital différés',
  'concept.altIntOptRelCapGains': 'Gains en capital réalisés',
  'concept.altIntBtnGrowth': 'Croissance',
  'concept.altIntBtnBalanced': 'Équilibré',
  'concept.altIntBtnIncome': 'Revenu',
  'concept.altIntColOptionAllocation': 'Affectation',
  'concept.altIntColOptionRateOfReturns': 'Taux de rendement',
  'concept.altIntColOptionAllocationStrategy': 'Stratégie d’affectation',
  'concept.altIntColOptionAllocationError': 'Le maximum permis est de 100 %',
  'concept.lumpsumAmountLabel': "Baser l'autre placement sur un dépôt fortaitaire",
  'concept.deathAtAge': 'Décès à l’âge de',
  'concept.illustrateAdditional': 'Ajouter (années)',
  'concept.years': 'années',
  'concept.presentdiscountrate': 'Taux d’actualisation',
  'concept.taxRates': 'Taux d’imposition',
  'concept.viewConcept': 'Visionner le concept',
  'concept.description.None': 'Sélectionnez un concept dans la liste pour en afficher la description',
  'concept.description.CIRP':
    'Le Plan d’assurance-retraite de société procure une protection d’assurance vie et la possibilité d’accéder aux valeurs accumulées en franchise d’impôt dans le futur. La valeur accumulée d’un contrat d’assurance vie exonéré peut servir de garantie pour un prêt bancaire et fournir des liquidités dans l’avenir. Les sommes empruntées peuvent servir à verser des dividendes aux actionnaires, à effectuer des placements, à acheter de la machinerie ou de l’équipement, et plus encore.',
  'concept.description.PIRP':
    'Le Plan d’assurance-retraite procure une protection d’assurance vie et la possibilité d’accéder aux valeurs accumulées en franchise d’impôt dans le futur. La valeur accumulée d’un contrat d’assurance vie exonéré peut servir de garantie pour un prêt bancaire et fournir des liquidités dans l’avenir. Les sommes empruntées peuvent servir à augmenter votre revenu de retraite, à effectuer des placements, à faire des cadeaux, etc.',
  'concept.description.EstateBond.1':
    'Les entreprises investissent souvent les bénéfices   non répartis ou les liquidités excédentaires dans des placements imposables.   Dans bien des cas, les bénéfices non répartis ou les liquidités excédentaires   sont investis de cette façon pour les raisons suivantes: ',
  'concept.description.EstateBond.2': 'le propriétaire d’entreprise   n’a pas besoin de plus de revenus; ou',
  'concept.description.EstateBond.3':
    'le propriétaire d’entreprise   fait l’objet d’un taux d’imposition marginal plus élevé que celui de l’entreprise.',
  'concept.description.EstateBond.4':
    'Le Bon patrimonial de société permet de tirer   avantage de l’assurance vie exonérée et illustre comment les sommes provenant   de la société peuvent être affectées dans un environnement à l’abri de   l’impôt. Il peut ainsi réduire l’impôt courant et maximiser les sommes   disponibles aux ayants droit du propriétaire lors de son décès.',
  'concept.description.PEB.1':
    'Bon nombre de gens choisissent d’investir leur épargne personnelle dans des certificats de placement garanti (CPG) ou dans d’autres placements imposables. Si vous n’avez pas besoin de vos placements pour augmenter votre revenu à la retraite, vous pouvez léguer votre épargne à vos héritiers.',
  'concept.description.PEB.2':
    'Le Bon patrimonial permet de transférer l’épargne accumulée dans un contrat d’assurance vie exonéré. Il peut ainsi réduire l’impôt courant et maximiser les sommes disponibles à vos héritiers advenant votre décès.',
  'concept.corporateIRPStructure.borrowingType': 'Type d’emprunt',
  'concept.corporateIRPStructure.corporateBorrowing': 'Emprunt par la société',
  'concept.corporateIRPStructure.investmentTax': 'Taux d’imp. des sociétés sur le revenu de plac.',
  'concept.corporateIRPStructure.companyType': 'Type de société',
  'corporateIRPStructure.holdingCompany': 'Société de portefeuille',
  'corporateIRPStructure.operatingCompany': 'Société exploitante',
  'concept.CSVLoanPercentage': 'Pourcentage du prêt sur la VRN',
  'concept.bankLoanRate': 'Taux du prêt bancaire',
  'concept.baseLoanAmountDuration': 'Baser le montant du prêt de base et la durée sur',
  'concept.youngestLife': 'l’assuré le plus jeune',
  'concept.loanAmount.specified': 'Montant spécifié',
  'concept.loanAmount.solveMaximum': 'Calcul – Maximum',
  'concept.lifeExpectancyAge': 'Espérance de vie',
  'concept.loanAmount': 'Montant du prêt',
  'concept.corporateIRPStructure.operatingIncomeTax': "Taux d’impôt de l'entr. sur le revenu opératif",
  'concept.assumeInterestDeductibility': 'Présumer que l’intérêt est déductible',
  'concept.taxInformationRates.label': 'Renseignements fiscaux et taux',
  'concept.taxRatesUrl':
    'https://www.manuvie.ca/conseillers/assurance/fiscalite-retraite-et-planification-successorale/taux-dimposition-des-particuliers-et-des-societes.html',
  'concept.collateralInsuranceDeduction': 'Déduct. coût assur. en garant.',
  'concept.mul.details': 'Pour les concepts VU Manuvie, écrivez à l’adresse suivante : ',
  'concept.parvp.details':
    'Pour les concepts Vie Manuvie avec participation avec <b><i>Vitalité Plus</i></b> écrivez à l’adresse suivante : ',
  'concept.parpay100.details':
    'Pour les concepts Vie Manuvie avec participation avec durée des primes jusqu’à 100 ans, écrivez à l’adresse suivante : ',
  'concept.all.email': 'ConceptQuote@manulife.ca',
  'concept.all.details2': 'Veuillez inclure le projet informatisé du produit ainsi que les hypothèses du concept.',
  'concept.all.details3': 'Voici quelques concepts que nous offrons :',
  'concept.all.details3A': 'Plan d’assurance-retraite',
  'concept.all.details3B': 'Plan d’assurance-retraite de société',
  'concept.all.details3C': 'Bon patrimonial',
  'concept.all.details3D': 'Bon patrimonial de société',

  'withdrawal.title': 'Retraits',
  'withdrawal.beforeTax': 'Avant impôt',
  'withdrawal.afterTax': 'Après impôt',
  'withdrawal.amount.specified': 'Spécifié',
  'withdrawal.client': 'Selon le client',

  'quicklinks.reference': 'Référence:',
  'quicklinks.term': 'Terme:',
  'quicklinks.wholelife': 'Vie entière:',
  'quicklinks.concepts': 'Concepts:',
  'quicklinks.link.guidetopar':
    'https://www.manulife.ca/content/dam/manulife-advisor-portal/documents/fr/marketing-materials/insurance/manulife-par/manulife-insurance-manulife-par-advisor-guide-mp1158345f.pdf',
  'quicklinks.label.guidetopar': `Vie Manuvie avec participation – Guide du conseiller`,
  'quicklinks.link.concepts': 'https://www.manuvie.ca/conseillers/assurance/concepts-de-strategies-de-vente.html',
  'quicklinks.label.concepts': `Concepts de vente d'assurance`,
  'quicklinks.FTVGuide': 'La Temporaire Famille - {vitality} de Manuvie Guide du conseiller',
  'quicklinks.healthstyleGuide': 'L’Indice-santé – Guide d’information pour les conseillers',
  'quicklinks.handbook': 'Assurance individuelle – manuel de tarification pour les conseillers',
  'quicklinks.requirements': 'Exigences de tarification',
  'quicklinks.eApplication': 'Propositions électroniques - Assurance',
  'quicklinks.sampleContracts': 'Modèles de contrats de l’Assurance individuelle',
  'quicklinks.advisorGuideLink':
    'https://repsourcepublic.manulife.com/wps/wcm/connect/repsourceFormsMktg_fr/MarketingMaterials/Insurance/Family+and+Business+Term/ins_ftbt_mk1464termadvisorbro?presentationtemplate=repsourceDesign2/showPDF',
  'quicklinks.FTVGuideLink':
    'https://repsourcepublic.manulife.com/wps/wcm/connect/repsourceFormsMktg_fr/MarketingMaterials/Insurance/Manulife+Vitality/ins_vitality_ftbtadvguidemk3343?presentationtemplate=repsourceDesign2/showPDF ',
  'quicklinks.healthstyleGuideLink':
    'https://www.manulife.ca/content/dam/manulife-advisor-portal/documents/fr/marketing-materials/insurance/underwriting/manulife-insurance-healthstyles-advisor-guide-mk0954f.pdf',
  'quicklinks.handbookLink':
    'https://www.manulife.ca/content/dam/manulife-advisor-portal/documents/fr/marketing-materials/insurance/new-business-and-underwriting/manulife-insurance-life-insurance-underwriting-handbook-for-advisors-mk2061f.pdf',
  'quicklinks.requirementsLink':
    'https://www.manuvie.ca/conseillers/administration/assurance/affaires-nouvelles-et-tarification/tarification.html',
  'quicklinks.eApplicationLink': 'https://www.manuvie.ca/conseillers/prive/outils/propositions-electroniques.html',
  'quicklinks.sampleContractsLink': 'https://www.manuvie.ca/conseillers/mes-affaires/centre-de-ressources.html',

  'help.livechat': 'Clavardage en direct du CSTD',
  'help.livechat.link':
    'https://repsource.manulife.com/ds001ap/wps/myportal/Repsource/Repsource/ContactUsPrivate/general_private/dtsc_esupportlogmein/!ut/p/z1/pZBNDoIwFITP4gHMeyCg2wYVA2VDAmI3pNFam0DbQGXh6WVrjH9xdpN8M5MMMKiBaT4qyZ0ymreTP7CoSb082JEE6WqZLZDQKKuyIvc2cQj7RwC3FCeAFGGyjhErH9g_eQy-y-MLEfxx_xlg7-vTTwPTg36fx7kEZrm7zJU-G6il0KLnbWN7NXInoD654diI4Wqt6V1rZCeUBtuVZVnfKCGz2R1qBKqA/dz/d5/L0lDUmlTUSEhL3dHa0FKRnNBLzROV3FpQSEhL2Zy/',
  'help.email': 'Envoyez-nous un message: DTSC@Manulife.com',
  'help.email.link': 'mailto:DTSC@Manulife.com',
  'help.callus1': 'Téléphonez-nous: 1-800-667-4266',
  'help.callus2': '(À la suite du message, entrez l’option 3)',
  'help.contactwholesaler.link': 'https://repsourcepublic.manulife.com/wps/vanityurl/publicinscontactusf',
  'help.forproductsupport': 'Pour le support produit',
  'help.fortechnicalsupport': 'Pour le support technique',
  'help.contactwholesaler': 'Communiquez avec votre expert-conseil',
  'help.contactus': 'Pour nous joindre',

  'status.started': 'Proposition commencée',
  'status.locked': 'Proposition verrouillée et prête à être signée',
  'status.await-client-e-sign': 'En attente de la signature électronique du client',
  'status.await-advisor-e-sign': 'En attente de la signature électronique du représentant',
  'status.declined-by-client': 'Refus du client de signer électroniquement',
  'status.esign-link-expired': 'Hyperlien de signature électronique expiré',
  'status.expired': 'Fin de session en raison d’inactivité',
  'status.cleared': 'Suppression en raison d’inactivité ',
  'status.submitted': 'Soumission électronique',
  'status.await-submit': 'En attente de la soumission par le conseiller',

  'coverage.EffectiveDate.tooltip.text':
    'La date d’effet a une incidence sur ce qui suit : la version du produit, l’admissibilité, les résultats du calcul.',
  'change-product-effectivedate-backdated':
    'Ce produit ne pouvait pas faire l’objet de projets informatisés en ligne à la date que vous avez indiquée.',

  // Lifecheque
  'lc.rop.death': 'Remboursement des primes au décès (RPD)',
  'lc.rop.expiry': "Remboursement des primes a l'éxpiration (RPE)",
  'lc.rop.surrender': 'Remboursement des primes avec option de rachat anticipé (RPR)',

  'lc.rop.death.coverage': 'Garantie RPD',
  'lc.rop.expiry.coverage': 'Garantie RPE',
  'lc.rop.surrender.coverage': 'Garantie RPR',

  'lc.learn.more.coverage.options': 'Les options disponibles dépendent de l’âge du client :',
  'lc.coverage.option.LifechequeRenewable10': '10 ans renouvelable',
  'lc.coverage.option.lc10.abbr': 'lc10',
  'lc.coverage.option.LifechequeRenewable20': '20 ans renouvelable',
  'lc.coverage.option.lc20.abbr': 'lc20',
  'lc.coverage.option.lc65': 'De base (T65)',
  'lc.coverage.option.lc65.abbr': 'lc65',
  'lc.coverage.option.lc75': 'Uniforme (T75)',
  'lc.coverage.option.lc75.abbr': 'lc75',
  'lc.coverage.option.lcPermanent': 'Permanent',
  'lc.coverage.option.lcPermanent.abbr': 'lcPermanent',
  'lc.premium.duration.lcPayToAge100': "Paiement jusqu'à l'âge de 100 ans",
  'lc.premium.duration.lcPayFor15': 'Paiement pendant 15 ans',

  'lc.rider.clc.abbr': 'Chèque-vie des enfants',
  'lc.rider.clc': 'Chèque-vie des enfants',
  'lc.rider.wpd.abbr': 'Exonération des primes',
  'lc.rider.wpd': 'Exonération des primes',
  'lc.rider.prw.abbr': 'Exonération des primes',
  'lc.rider.prw': 'Exonération des primes',
  'lc.rider.tdw.payor': 'Exonération des primes',

  'lc.riders.label': 'GAR. COMPL.',
  'lc.riders.label.common': 'Gar. Compl.',
  // End Lifecheque
};

export default locale;
