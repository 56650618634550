import { createCase, retrieveCookieInfo, updateCase } from './core/udm-mapper/sideEffect';
import { eProduct, newIllustrationAutoSaved } from './core/configs';
import {
  AUTO_SAVE_CASE,
  LANGUAGE_CHANGE,
  MWI_ON_CHANGE,
  SAVE_CASE_ERROR,
  SAVE_CASE_FINISHED,
  SAVE_CASE_STARTED,
} from './core/actionTypes';
import { toggleOutOfDateAfterSave } from './custom-mode/scenario-tab-navs/actions';
import UDMGenerator from './core/udm-mapper/udmGenerator';
import * as actionTypes from './core/actionTypes';

const autoSaveCase = () => {
  return function (dispatch, getState) {
    const state = getState();
    const lastSavedModel = state.app.model;
    const autoSaveCaseLoaded = state.app.autoSaveCaseLoaded;
    const model = {
      landingPage: state.landingPage,
      scenarioTabNavs: state.scenarioTabNavs,
      coverageTabNavs: state.coverageTabNavs,
      conceptTabNavs: state.conceptTabNavs,
      clients: state.clients,
      riders: state.riders,
      termRiders: state.termRiders,
      termOtherRiders: state.termOtherRiders,
      spousalRiders: state.spousalRiders,
      vitalityStatus: state.vitalityStatus,
      report: state.report,
      spreadsheet: state.spreadsheet,
      advisorProfile: state.advisorProfile,
      myCases: state.myCases,
      withdrawals: state.withdrawals,
      deposits: state.deposits,
    };
    const productName = model.scenarioTabNavs.topBars['Scenario.1']?.product;

    if (!shouldAutoSave(lastSavedModel, model, getState, dispatch, productName)) {
      return Promise.resolve();
    }

    dispatch(saveCaseStarted);

    const curScenario = state.scenarioTabNavs.activeTabId;
    const modelCurrentScenario = {
      app: state.app,
      coverageTabNavs: state.coverageTabNavs,
      coverages: state.coverageTabNavs[curScenario],
      concepts: state.conceptTabNavs[curScenario],
      clients: state.clients[curScenario],
      riders: state.riders[curScenario],
      policyData: model.scenarioTabNavs.topBars[curScenario],
      termRiders: state.termRiders[curScenario],
      termOtherRiders: state.termOtherRiders[curScenario],
      spousalRiders: state.spousalRiders[curScenario],
      vitalityStatus: state.vitalityStatus[curScenario],
      report: state.report[curScenario],
      advisorProfile: state.advisorProfile,
      withdrawals: state.withdrawals[curScenario],
      deposits: state.deposits[curScenario],
    };

    const udmGenerator = new UDMGenerator();
    const udmBody = udmGenerator.setProps(modelCurrentScenario).getUDM();
    const name = state.app.openedCaseName;
    const autoSave = getAutoSaveBody(model, getState, name, udmBody, productName);

    try {
      if (autoSave.guid === '' || autoSave.guid === undefined) {
        createAutoSave(autoSave, model, getState, dispatch);
      } else {
        updateAutoSave(autoSaveCaseLoaded, autoSave, model, getState, dispatch);
      }

      // Used to update the cookieInfo call with CIAMS expire date for its issued token in Gateway
      retrieveCookieInfo().then((result) => {
        dispatch(saveCiamInfo(result['securityTokenValidTo']));
      });
    } catch (error) {
      dispatch(saveCaseError(error));
      dispatch(toggleOutOfDateAfterSave(true));
    }

    return Promise.resolve();
  };
};

const updateAutoSave = (autoSaveCaseLoaded, autoSave, model, getState, dispatch) => {
  autoSave.autoSave = autoSaveCaseLoaded ?? false;
  updateCase(autoSave, autoSave.guid).then((response) => {
    if (response === undefined || response.isError) {
      dispatch(saveCaseError(response));
      dispatch(toggleOutOfDateAfterSave(true));
      return;
    }

    dispatch(saveCaseFinished);
    dispatch(autoSaveSuccess(model, autoSave.autoSave));
    dispatch(toggleOutOfDateAfterSave(true));
  });
};

const createAutoSave = (autoSave, model, getState, dispatch) => {
  autoSave.autoSave = true;
  autoSave.createdForPartyId = getState().app.partyId;
  autoSave.createdForName = getState().app.userName;

  createCase(autoSave).then((response) => {
    if (response === undefined || response.items === undefined || response.isError) {
      dispatch(saveCaseError(response));
      dispatch(toggleOutOfDateAfterSave(true));
      return;
    }

    dispatch(saveCaseFinished);
    dispatch(autoSaveGuidChanged(response.items[0].guid));
    dispatch(autoSaveSuccess(model, true));
    dispatch(toggleOutOfDateAfterSave(true));
  });
};

const getAutoSaveBody = (model, getState, name, udmBody, productName) => {
  return {
    model,
    guid: getState().app.guid ?? '',
    name: name === '' || name == null ? `${newIllustrationAutoSaved} - ${productName}` : name,
    userId: getState().app.userID,
    udm: udmBody,
    product: productName,
  };
};

const shouldAutoSave = (lastSavedModel, model, getState, dispatch, productName) => {
  if (JSON.stringify(lastSavedModel) === JSON.stringify(model)) {
    return false;
  } else if (getState().app.initialProductOpen) {
    dispatch(autoSaveSuccess(model, false));
    return false;
  } else if (getState().app.isRequestingCalculation) {
    return false;
  } else if (getState().app.languageChange) {
    dispatch(languageChange(false));
    return false;
  } else if (getState().app.saveCaseStarted) {
    return false;
  } else if (getState().app.inforcePolicy) {
    return false;
  } else if (productName === eProduct.FTRC) {
    dispatch(toggleOutOfDateAfterSave(true));
    return false;
  } else {
    return true;
  }
};

const saveCaseError = (errResponse) => ({
  type: SAVE_CASE_ERROR,
  payload: {
    target: 'app',
    value: errResponse,
  },
});

const languageChange = (value) => ({
  type: LANGUAGE_CHANGE,
  payload: {
    target: 'app',
    value,
  },
});

const saveCiamInfo = (securityTokenValidTo) => ({
  type: actionTypes.AUTH_CIAM_INFO,
  payload: {
    securityTokenValidTo,
  },
});

const saveCaseStarted = {
  type: SAVE_CASE_STARTED,
  payload: {
    target: 'app',
  },
};

const saveCaseFinished = {
  type: SAVE_CASE_FINISHED,
  payload: {
    target: 'app',
  },
};

const autoSaveSuccess = (model, autoSaveCaseLoaded) => ({
  type: AUTO_SAVE_CASE,
  payload: {
    target: 'app',
    field: 'model',
    value: model,
    autoSaveCaseLoadedValue: autoSaveCaseLoaded,
  },
});

const autoSaveGuidChanged = (guid) => ({
  type: MWI_ON_CHANGE,
  payload: {
    target: 'app',
    field: 'guid',
    value: guid,
  },
});

export { autoSaveCase };
