import * as actionTypes from './core/actionTypes';
import { call, put } from 'redux-saga/effects';
import { authorize, retrieveCookieInfo, retrieveProfiles } from './core/udm-mapper/sideEffect';
import { eLocale } from './core/configs';
import { NUMBERS } from './core/constants';

const crypto = require('crypto');
const generateAdvisorList = (shareList, advisorCodes, advisorPartyMap) => {
  const merged = [];

  if (!shareList) {
    return merged;
  }
  if (!advisorPartyMap || advisorPartyMap.length === 0) {
    return merged;
  }

  for (const item of shareList) {
    const partyMapItem = advisorPartyMap?.find((m) => m.partyId === item);
    const advisorCodesItem = advisorCodes?.find((a) => a.code === partyMapItem.advisorCode);
    advisorCodesItem.fullName = `${advisorCodesItem.firstName} ${advisorCodesItem.lastName}`;

    delete advisorCodesItem.code;
    merged.push({
      ...partyMapItem,
      ...advisorCodesItem,
    });
  }
  return merged;
};

const dispatchLogin = (authorizeResponse) => {
  const {
    firstName,
    entitlementRole,
    branch,
    role,
    advisorPartyMap,
    partyId,
    lastName,
    email,
    apId,
    ciamId,
    langPref,
    advisorCodes,
    shareList,
    groups,
    surrogateBranches,
    allSellingCodes,
  } = authorizeResponse.samlAttributes;

  //Generate hashed CiamID
  let ciam_id = '';
  if (ciamId) {
    ciam_id = crypto.createHash('SHA256').update(ciamId).digest('hex');
  }

  //Remove user partyId from shareList
  const filteredShareList = shareList?.filter(function (item) {
    return item !== partyId;
  });

  const advisorList = generateAdvisorList(filteredShareList, advisorCodes, advisorPartyMap);
  return {
    type: actionTypes.AUTH_USER_DONE,
    payload: {
      language: langPref === null || langPref === 'English' ? eLocale.en : eLocale.fr,
      userID: apId,
      ciamId: ciam_id,
      channel: branch,
      type: groups, // userEntitlement
      firstname: firstName,
      lastname: lastName,
      partyId,
      role,
      email,
      entitlementRole,
      advisorList,
      surrogateBranches,
      allSellingCodes,
    },
  };
};

const dispatchAction = (userId, field, value, target = 'header', isError = false) => {
  const payload = {
    target,
    field,
    value,
    userId,
  };

  if (isError) {
    return {
      type: actionTypes.MWI_UPDATE_ERROR,
      payload,
    };
  }

  return {
    type: actionTypes.MWI_ON_CHANGE,
    payload,
  };
};

const dispatchCookieInfo = (securityTokenValidTo) => {
  return {
    type: actionTypes.AUTH_CIAM_INFO,
    payload: {
      securityTokenValidTo,
    },
  };
};

const login = async () => {
  let userId = null;

  try {
    userId = sessionStorage.getItem('userId');

    if (!userId) {
      // cleared on first login to prevent any caching issues
      sessionStorage.clear();
      localStorage.removeItem('currentProfile');
    }

    const authorizeResponse = await authorize();

    if (authorizeResponse != null) {
      userId = authorizeResponse.samlAttributes.apId;
      sessionStorage.setItem('userId', userId);
      const profileResponse = await retrieveProfiles();
      const loginEvent = dispatchLogin(authorizeResponse);

      const { securityTokenValidTo } = await retrieveCookieInfo();
      return [
        loginEvent,
        dispatchAction(userId, 'savedProfiles', profileResponse.items),
        dispatchCookieInfo(securityTokenValidTo),
      ];
    }
  } catch (errResponse) {
    return dispatchAction(userId, 'errorMessage', errResponse, 'advisorProfile', true);
  }
  return null;
};

const authenticateWithBackend = function* () {
  const actions = yield call(login);

  if (actions != null && actions.length > 1) {
    yield put(actions[NUMBERS.ZERO]);
    yield put(actions[NUMBERS.ONE]);
    yield put(actions[NUMBERS.TWO]);
  }
};

export { generateAdvisorList, dispatchAction, login, authenticateWithBackend };
