import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { eSpreadsheetFields, eLocale, eReportType, eProduct, ePremiumDuration, eColumnToHide } from '../core/configs';
import { injectIntl, FormattedMessage } from 'react-intl';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import DocumentTitle from 'react-document-title';
import MWIButton from '../core/components/mwi-button';

import { isIOS, getProduct } from '../core/utils';
import * as AppActions from '../core/actions';
import * as ScenarioActions from '../custom-mode/scenario-tab-navs/actions';
import * as SpreadsheetActions from './actions';
import _ from 'lodash';
import './spreadsheet.css';
import * as SupportFunctions from './spreadsheet-support-functions';

// columns in spreadsheetResponse are already sorted
// We map by the response and the index below because we now use the key field which is the type + index
// we need the key field because the type field is not unique in the table see
// https://cpcnissgwp01.americas.manulife.net:23800/browse/CDTWI-982
// This is a temporary fix till the calc service sends over unique fields
const handleSortedColumns = (spreadsheetResponse) => {
  return _.map(spreadsheetResponse, (response, index) => {
    return {
      type: response.type,
      key: response.type + index,
      basisType: response.basisType,
      coverageProduct: response.coverageProduct,
      header: response.title,
    };
  });
};

const getIsCustom = (props) =>
  (props.history.location && props.history.location.state && props.history.location.state.isCustom) || false;

const columnTypeCondition = (column, customField) =>
  column.type !== customField && column.type !== eSpreadsheetFields.year && column.type !== eSpreadsheetFields.age;

const getCustomField = (props) =>
  props.history.location && props.history.location.state && props.history.location.state.customField;

const getCustomSection = (state, accessible, intl, props, setState) => {
  return (
    <>
      <div aria-label={!state.calculating ? 'The spreadsheet has been updated' : ''} />
      {state.isChanged && (
        <MWIButton
          label={intl.formatMessage({ id: 'spreadsheet.recalculate' })}
          onClick={() => {
            SupportFunctions.recalculateSpreadSheet(props, state, setState);
          }}
          styleClass={`recalculate mwi-white-only item-splitter p-col-2 p-lg-2 p-md-3`}
          disabled={false}
          srOnlyLabel={isIOS() ? undefined : intl.formatMessage({ id: 'spreadsheet.recalculate.sr' })}
        />
      )}
      {accessible && state.isChanged && (
        <MWIButton
          label={intl.formatMessage({ id: 'common.filldown' })}
          onClick={() => {
            SupportFunctions.fillDown(state, setState);
          }}
          styleClass={`mwi-white-only item-splitter p-col-2 ${props.locale === eLocale.en ? '' : 'p-lg-2 p-md-3'} `}
          srOnlyLabel={isIOS() ? undefined : intl.formatMessage({ id: 'spreadsheet.filldown.sr' })}
          disabled={false}
        />
      )}
      {!accessible && (
        <MWIButton
          label={intl.formatMessage({ id: 'common.filldown' })}
          onClick={() => {
            SupportFunctions.fillDown(props, state, setState);
          }}
          styleClass={`mwi-white-only item-splitter p-col-2 ${props.locale === eLocale.en ? '' : 'p-lg-2 p-md-3'} `}
          disabled={!state.isChanged}
        />
      )}
    </>
  );
};

const setFrozenColumns = (accessible, column, product) => {
  if(product === eProduct.PG){
    return !accessible && column.type === eSpreadsheetFields.year;
  }
  else{
    return !accessible && (column.type === eSpreadsheetFields.year || column.type === eSpreadsheetFields.age);
  }
};

const getDataTableSection = (accessible, state, ref, sortedColumns, props, setState) => {
  const {product} = props;

  // for the fullscreen mode, we can scroll more rows
  sortedColumns = hidePay100(props, sortedColumns);

  const screenHeightPercentage = 0.9;
  const screenHeightNum = 120;
  const screenHeight = props.isSpreadsheetFullScreen
    ? `${state.screenHeight * screenHeightPercentage - screenHeightNum}px`
    : '60vh';
  return (
    <div>
      <DataTable
        scrollable={!accessible}
        scrollHeight={!accessible ? `${screenHeight}` : undefined}
        exportFilename="spreadsheet"
        value={state.formattedData}
        ref={ref}
        frozenWidth={accessible ? '0%' : '10%'}
        unfrozenWidth={accessible ? '100%' : '90%'}
        rowClassName={SupportFunctions.rowClassName}
      >
        {sortedColumns.map((column) => {
          return (
            <Column
              className={`sp-column ${column.type}${getIsCustom(props) ? ' custom' : ''}${
                state.isChanged && columnTypeCondition(column, getCustomField(props)) ? ' changed' : ''
              }`}
              key={column.type}
              field={column.key}
              header={column.header}
              frozen={
                setFrozenColumns(accessible, column, product)
              }
              body={
                column.type === getCustomField(props) && getIsCustom(props)
                  ? (rowData, columnData) => SupportFunctions.depositEditor(rowData, columnData, props, state, setState)
                  : ''
              }
            />
          );
        })}
      </DataTable>
    </div>
  );
};

function hidePay100(props, sortedColumns) {
  const isHidden =
    props.product === eProduct.PAR &&
    props.isVitalityPlusSelected === true &&
    props.premiumDuration === ePremiumDuration.Pay100;
  if (isHidden) {
    const columnObj = sortedColumns.find((x) => x.type === eColumnToHide.VitalityStatus);
    const columnIndex = sortedColumns.indexOf(columnObj);
    if (columnIndex > -1) {
      sortedColumns.splice(columnIndex, 1);
    }
  }
  return sortedColumns;
}

const renderProductName = (product, isVitalityPlusSelected, intl, locale) => {
  return (
    product !== eProduct.FTRC && (
      <div className={`spreadTitle ${locale === eLocale.en ? 'p-col-2 p-lg-2 p-md-3' : 'p-col-3 p-lg-3 p-md-4'}`}>
        <span>{intl.formatMessage({ id: `${getProduct(product)}` })}</span>
        {isVitalityPlusSelected && (
          <>
            <span className="mwi-padding-left4">
              <FormattedMessage id="summary.with" />
            </span>
            <span className="mwi-padding-left4 mwi-bold italic">
              <FormattedMessage id="coverage.vitalityPlus" />
            </span>
            <span className="mwi-padding-left4 mwi-tm">
              <FormattedMessage id="common.superscript" />
            </span>
          </>
        )}
      </div>
    )
  );
};

export const Spreadsheet = (props) => {
  const { intl, spreadsheetResponse, accessible, product, isVitalityPlusSelected, locale } = props;

  const [state, setState] = React.useState({
    isChanged: false,
    lastValue: '',
    rowIndex: 0,
    formattedData: [],
    yearColumn: '',
    customColumn: '',
    screenHeight: window.innerHeight,
    calculating: false,
    recalculate: false,
  });

  let ref = useRef(null);

  React.useEffect(() => {
    const onCopyFn = (e) => {
      SupportFunctions.onCopy(e, props);
    };
    document.addEventListener('copy', onCopyFn);

    return () => {
      ref = null;
      props.spreadsheetActions.closeSpreadsheet({ scenarioId: props.scenarioTabId });
      document.removeEventListener('copy', onCopyFn);
    };
  }, []);

  React.useEffect(() => {
    SupportFunctions.RequestSpreadSheet(props, ref, state, setState);
    setState({ ...state, calculating: false });

    props.spreadsheetActions.loadingSpreadsheet({
      scenarioId: props.scenarioTabId,
    });
  }, [props.scenarioTabId, props.locale]);

  React.useEffect(() => {
    if (state.recalculate) {
      SupportFunctions.RequestSpreadSheet(props, ref, state, setState);
      setState({ ...state, calculating: false, recalculate: false });
    }
  }, [props.policyData.depositOptionPaymentCache]);
  const isCustom = getIsCustom(props);
  const sortedColumns = handleSortedColumns(spreadsheetResponse);

  return (
    <DocumentTitle
      title={`${intl.formatMessage({ id: 'common.illustrations' })} - ${intl.formatMessage({
        id: 'topbar.spreadsheet',
      })}`}
    >
      <div className="spreadsheet">
        <div className="actions p-col-12">
          {renderProductName(product, isVitalityPlusSelected, intl, locale)}
          <div className="spreadControls p-col-10" aria-live="polite">
            {isCustom && getCustomSection(state, accessible, intl, props, setState)}
            <MWIButton
              label={props.intl.formatMessage({ id: 'spreadsheet.exportBtn' })}
              styleClass={`yellow-bg exportBtn item-splitter mwi-float-right ${locale}`}
              onClick={() => {
                SupportFunctions.exportSpreadsheet(ref, props);
              }}
            />
            <MWIButton
              label={intl.formatMessage({ id: 'report.back' })}
              onClick={() => {
                SupportFunctions.openCustomModeDesign(props);
              }}
              styleClass={`mwi-white-only item-splitter mwi-float-right ${
                locale === eLocale.en ? 'p-col-2 p-lg-2 p-md-3' : 'p-col-3 p-lg-3 p-md-4'
              }`}
            />
            {props.app.closeSpreadsheet && SupportFunctions.openCustomModeDesign(props)}
          </div>
        </div>
        {!props.spreadsheet.isLoading && spreadsheetResponse && (
          <span role="spreadsheet-dataTable">
            {getDataTableSection(accessible, state, ref, sortedColumns, props, setState)}
          </span>
        )}
      </div>
    </DocumentTitle>
  );
};

Spreadsheet.propTypes = {
  app: PropTypes.object,
  udmResponse: PropTypes.object,
  scenarioTabId: PropTypes.string,
  locale: PropTypes.string,
  policyData: PropTypes.object,
  clients: PropTypes.object,
  vitalityStatus: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  coverages: PropTypes.object,
  spreadsheetResponse: PropTypes.array,
  coverageOption: PropTypes.string,
  product: PropTypes.string,
  riders: PropTypes.object,
  termRiders: PropTypes.object,
  termOtherRiders: PropTypes.object,
  spousalRiders: PropTypes.object,
  withdrawal: PropTypes.object,
  modal: PropTypes.string,
  spreadsheet: PropTypes.object,
  intl: PropTypes.object,
  advisorProfile: PropTypes.object,
  history: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

  appActions: PropTypes.object,
  sceActions: PropTypes.object,
  spreadsheetActions: PropTypes.object,
  concepts: PropTypes.object,
  accessible: PropTypes.bool,
  deposits: PropTypes.object,

  idOfError: PropTypes.string,
  isVitalityPlusSelected: PropTypes.bool,
  premiumDuration: PropTypes.string,
};

export const mapStateToProps = ({
  app,
  udmResponse,
  scenarioTabNavs,
  coverageTabNavs,
  conceptTabNavs,
  clients,
  deposits,
  riders,
  termRiders,
  termOtherRiders,
  spousalRiders,
  vitalityStatus,
  withdrawals,
  spreadsheet,
  advisorProfile,
}) => {
  const scenarioTabId = scenarioTabNavs.activeTabId;
  const activeScenario = scenarioTabNavs.topBars[scenarioTabId];
  const coverageState = coverageTabNavs[scenarioTabId];
  const coverage = coverageState[coverageState.activeTabId];
  const concepts = conceptTabNavs[scenarioTabId];
  // For handling errors we need the value to be undefined if it doesnt exist
  const illustration = udmResponse.udmIllustration;
  const isVitalityPlusSelected = coverageState[coverageState.activeTabId].isVitalityPlusSelected;
  const premiumDuration = coverage.premiumDuration;

  return {
    app,
    udmResponse,
    advisorProfile,
    scenarioTabId,
    concepts,
    isVitalityPlusSelected,
    premiumDuration,
    policyData: activeScenario,
    locale: app.locale,
    clients: clients[scenarioTabId],
    coverages: coverageState,
    deposits: deposits[scenarioTabId],
    riders: riders[scenarioTabId],
    termRiders: termRiders[scenarioTabId],
    termOtherRiders: termOtherRiders[scenarioTabId],
    spousalRiders: spousalRiders[scenarioTabId],
    vitalityStatus: vitalityStatus[scenarioTabId],
    withdrawal: withdrawals[scenarioTabId],
    spreadsheetResponse: illustration && illustration.policy && illustration.policy.calculationVectors,
    coverageOption: coverage.coverageOption,
    modal: activeScenario.premiumFrequency,
    spreadsheet: spreadsheet[scenarioTabId],
    product: scenarioTabNavs.topBars[scenarioTabId].product,
    // spreadsheet needs only report type
    report: { reportType: eReportType.base },
    accessible: app.accessible,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  sceActions: bindActionCreators(ScenarioActions, dispatch),
  spreadsheetActions: bindActionCreators(SpreadsheetActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Spreadsheet)));
