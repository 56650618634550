import './dashboard-applications-tab.css';

import * as AppActions from '../../../core/actions';

import {
  DASHBOARD_TABLE_APPS_ID,
  DASH_ADVISOR_APPS_ID,
  DASH_ADVISOR_FILTER_APPS_ID,
  DASH_APPLICATION_ID,
  DASH_APPLICATION_INSURED_ID,
  DASH_APPLICATION_INS_NAME_SEARCH_ID,
  DASH_APPLICATION_SEARCH_ID,
  DASH_APPLICATION_TAB_ID,
  DASH_APPLICATION_TCA_TAB_ID,
  DASH_CREATEDBY_FILTER_APPS_ID,
  DASH_CREATED_BY_APPS_ID,
  DASH_DELETE_APPS_ID,
  DASH_LAST_UPDATED_ON_APPS_ID,
  DASH_PAGINATION_APPS_ID,
  DASH_ORIGIN_APPLICATION_ID,
  DASH_PRODUCT_APPS_ID,
  DASH_PRODUCT_FILTER_APPS_ID,
  DASH_RESET_FILTERS_APPS_ID,
  DASH_STATUS_APPS_ID,
  DASH_STATUS_FILTER_APPS_ID,
  PAGE_SIZE,
  DASH_DDA2_TOGGLE_APPS_ID,
  DASH_TCA2_TOGGLE_APPS_ID,
} from '../../../core/constants';
import React, { useEffect, useState } from 'react';
import { Table, Tooltip, Toggle, Button, BUTTON_VARIANT } from '@manulife/mux';
import { commonSort, getAppStatus, getProduct, isFeatureFlagSet, openPDFNewTab } from '../../../core/utils';
import { configs, eAppStatus, eDefault, eLocale, eProduct } from '../../../core/configs';
import {
  deleteSavedApps,
  deleteSavedAppsTca,
  retrieveSavedApps,
  retrieveSavedAppsTca,
  retrieveDocuSignDoc,
  retrieveDocuSignDocTca,
} from '../../../core/udm-mapper/sideEffect';
import { injectIntl, intlShape } from 'react-intl';

import { ActionButton, Checkbox, Dropdown, LANG, Pagination, TextInput } from '@manulife/mux';
import DeleteModal from '../../../core/components/delete-modal';
import * as Cds from '@manulife/mux-cds-icons';
import PaginationHelper from '../../../core/components/pagination-helper/pagination-helper';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { brandingColors } from '../../../assets/colors/index';
import { connect } from 'react-redux';
import { dropDownOptions } from '../../../core/dropdown-config';
import moment from 'moment';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Download } from '@manulife/mux-cds-icons';

export const FormContent = styled.div`
  margin: 0 5px;
  padding: 0 10px;
  @media screen and (min-width: 576px) {
    padding: 0 10px;
  }
`;

const frMargin = '5px 5px 5px 0';
const enMargin = '5px 5px 0 0';

export const commonPlaceholder = ({ intl }) => intl.formatMessage({ id: 'common.typeKeywords' });

export const search = (rows, query, canAssignCase, intl) => {
  const { originAppName, appName, createdBy, createdFor, product, insuredName, status } = query;
  const { appStatus } = dropDownOptions(intl);

  if (!_.isEmpty(originAppName)) {
    const trimmedAppName = originAppName.trim().toLowerCase();
    rows = rows.filter((row) => row.policy.originPolicyNumber.toLowerCase().includes(trimmedAppName));
  }
  if (!_.isEmpty(appName)) {
    const trimmedAppName = appName.trim().toLowerCase();
    rows = rows.filter((row) => row.policy.applicationId.toLowerCase().includes(trimmedAppName));
  }
  if (product !== eProduct.ALL) {
    const { productOptions } = dropDownOptions(intl);
    const prod = productOptions.find((p) => p.value === product).label;
    rows = rows.filter(
      (row) =>
        row.policy.product &&
        row.policy.product !== '' &&
        intl.formatMessage({ id: getProduct(row.policy.product) }) === prod
    );
  }

  if (!_.isEmpty(insuredName)) {
    const trimmedName = insuredName.trim().toLowerCase();
    rows = rows.filter((row) =>
      row.parties
        .map((elem) => elem.name)
        .join(' and ')
        .toLowerCase()
        .includes(trimmedName)
    );
  }

  if (canAssignCase) {
    if (createdFor !== eProduct.ALL) {
      rows = rows.filter((row) => `${row.createdFor.firstName} ${row.createdFor.lastName}` === createdFor);
    }
  } else {
    if (createdBy !== eProduct.ALL) {
      rows = rows.filter((row) => `${row.createdBy.firstName} ${row.createdBy.lastName}` === createdBy);
    }
  }
  if (status !== eAppStatus.ALL) {
    const statusLabel = appStatus.find((p) => p.value === status).label;
    rows = rows.filter(
      (row) => row.status !== null && intl.formatMessage({ id: getAppStatus(row.status) }) === statusLabel
    );
  }
  return rows;
};

export const deleteNotAllowedStatuses = [
  eAppStatus.AWAIT_CLIENT_ESIGN,
  eAppStatus.AWAIT_ADVISOR_ESIGN,
  eAppStatus.DECLINED_BY_CLIENT,
  eAppStatus.ESIGN_LINK_EXPIRED,
  eAppStatus.SUBMITTED,
  eAppStatus.AWAIT_SUBMIT,
];

export const exclamationStatuses = [
  eAppStatus.AWAIT_CLIENT_ESIGN,
  eAppStatus.AWAIT_ADVISOR_ESIGN,
  eAppStatus.ESIGN_LINK_EXPIRED,
  eAppStatus.AWAIT_SUBMIT,
  eAppStatus.EXPIRED,
];

export const getLabelCheckbox = (item, checkedApps, setCheckedApps, props) => {
  const { partyId, role } = props;
  const handleCheckboxChange = () => {
    if (!checkedApps.some((i) => i.caseId === item.caseId)) {
      setCheckedApps([...checkedApps, item]);
    } else {
      setCheckedApps(checkedApps.filter((i) => i.caseId !== item.caseId));
    }
  };

  return (
    <Checkbox
      id={`recselect-${item.caseId}`}
      disabled={
        partyId !== item.createdFor.partyId || role === 'MA' || deleteNotAllowedStatuses.indexOf(item.status) !== -1
      }
      checked={checkedApps.some((i) => i.caseId === item.caseId)}
      name={item.caseId}
      value={item.caseId}
      onChange={() => handleCheckboxChange()}
      customStyle={{
        margin: '2px 2px 2px 0',
        width: '90%',
      }}
    />
  );
};

export const getLabelApplicationName = (item, dda2Toggled, tca2Toggled, isTca) => {
  const ddaParam = dda2Toggled ? '2' : '1';
  const tcaParam = tca2Toggled ? '2' : '1';
  const appType = isTca ? 'tca' : 'dda';
  const appFlag = isTca ? tcaParam : ddaParam;

  return item.status === eAppStatus.SUBMITTED || item.status === eAppStatus.CLEARED ? (
    <span>{item.policy.applicationId}</span>
  ) : (
    <ActionButton
      onClick={() => open(`${configs.routes.ddaHome}/?${appType}=${appFlag}&caseId=${item.caseId}`, '_self')}
    >
      <span className="click">{item.policy.applicationId}</span>
    </ActionButton>
  );
};

export const getLabelOriginApplicationName = (item, tca2Toggled) => {
  const appFlag = tca2Toggled ? '2' : '1';
  return item.status === eAppStatus.SUBMITTED || item.status === eAppStatus.CLEARED ? (
    <span>{item.policy.originPolicyNumber}</span>
  ) : (
    <ActionButton onClick={() => open(`${configs.routes.ddaHome}/?tca=${appFlag}&caseId=${item.caseId}`, '_self')}>
      <span className="click">{item.policy.originPolicyNumber}</span>
    </ActionButton>
  );
};

const setIsRequesting = (appActions, value) =>
  appActions.dispatchMWIOnChange({
    target: 'app',
    field: 'isRequesting',
    value,
  });

const getLabelStatusApps = (item, intl, appActions, dispatchNotification, isTca) => {
  const awaitPreDesc = intl.formatMessage({ id: 'dashboard.apps.await.desc.pre' });
  const daysText = intl.formatMessage({ id: 'common.days' });
  const awaitPostDesc = intl.formatMessage({ id: 'dashboard.apps.await.desc.post' });
  const downloadDocuSignDoc = () => {
    return () => {
      setIsRequesting(appActions, true);
      const applicationId = item.policy?.applicationId;
      const envelopeId = item.envelopeId;
      const docuSignDocBody = {
        envelopeId,
      };
      (isTca ? retrieveDocuSignDocTca(docuSignDocBody) : retrieveDocuSignDoc(docuSignDocBody))
        .then((response) => {
          openPDFNewTab(response, `DocuSignDoc_${applicationId}.pdf`);
          setIsRequesting(appActions, false);
        })
        .catch((errResponse) => {
          setIsRequesting(appActions, false);
          dispatchNotification('errorMessage', errResponse, 'app');
        });
    };
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>
        <span className="highlight bold">{exclamationStatuses.indexOf(item.status) !== -1 ? '! ' : ''}</span>
        <span className={item.status === eAppStatus.AWAIT_SUBMIT ? 'highlight' : ''}>
          {intl.formatMessage({ id: getAppStatus(item.status) })}
        </span>
      </span>
      {item.status === eAppStatus.SUBMITTED && (
        <ActionButton onClick={downloadDocuSignDoc()}>
          <span className="descriptionText" style={{ color: brandingColors.m_green }}>
            <Download style={{ marginRight: '5px', fontSize: '20px' }} />
            {intl.formatMessage({ id: 'dashboard.apps.submitted.desc.new' })}
          </span>
        </ActionButton>
      )}
      {item.status === eAppStatus.AWAIT_SUBMIT && (
        <span className="descriptionText">
          {awaitPreDesc}&nbsp;
          <span style={{ textDecoration: 'underline' }}>
            {item.daysLeft} {daysText}
          </span>
          &nbsp;{awaitPostDesc}
        </span>
      )}
    </div>
  );
};

const getTableRows = (
  props,
  appsData,
  query,
  checkedApps,
  setCheckedApps,
  dda2Toggled,
  tca2Toggled,
  isTca,
  dispatchNotification
) => {
  const { intl, canAssignCase, appActions } = props;
  const tableRowsDda = (item) => ({
    checkbox: {
      label: <span>{getLabelCheckbox(item, checkedApps, setCheckedApps, props)}</span>,
    },
    applicationName: {
      label: <span>{getLabelApplicationName(item, dda2Toggled, tca2Toggled, isTca)}</span>,
      sortValue: item.policy.applicationId,
    },
    productApps: {
      label:
        item.policy.product &&
        item.policy.product !== 'None' &&
        intl.formatMessage({ id: getProduct(item.policy.product) }),
    },
    insuredName: {
      label: item.parties.map((elem) => elem.name).join(' and '),
    },
    createdByApps: {
      label: item.createdBy && `${item.createdBy?.firstName} ${item.createdBy?.lastName}`,
    },
    advisorApps: {
      label: item.createdFor && `${item.createdFor?.firstName} ${item.createdFor?.lastName}`,
    },
    statusApps: {
      label: item.status && getLabelStatusApps(item, intl, appActions, dispatchNotification, isTca),
    },
    lastUpdatedOnApps: {
      label: item.updatedAt && moment(item.updatedAt, 'DD/MM/YYYY h:mm a').format(eDefault.casesDate),
      sortValue: Date.parse(moment(item.updatedAt, 'DD/MM/YYYY h:mm a')),
    },
  });
  const tableRowsTca = (item) => ({
    ...tableRowsDda(item),
    originApplicationName: {
      label: <span>{getLabelOriginApplicationName(item, tca2Toggled)}</span>,
      sortValue: item.policy.originPolicyNumber,
    },
  });

  return (
    appsData &&
    search(appsData, query, canAssignCase, intl).map((item) => (isTca ? tableRowsTca(item) : tableRowsDda(item)))
  );
};

const deleteMyCases = (appsData, checkedApps, stateFuncs, dispatchNotification, intl, isTca) => {
  const deleteCaseIds = checkedApps.map((c) => c.caseId);
  const hideNotificationTime = 5000;
  const casesBody = {
    cases: deleteCaseIds,
  };
  const newData = [...appsData];
  const { setAppsData, setCheckedApps, setShowCaseDel } = stateFuncs;
  setAppsData(newData);
  (isTca ? deleteSavedAppsTca(casesBody, true) : deleteSavedApps(casesBody, true))
    .then((response) => {
      if (response.deletedCount > 0) {
        setAppsData(newData.filter((p) => !deleteCaseIds.includes(p.caseId)));
        setCheckedApps([]);
        dispatchNotification(
          'showNotification',
          'notificationMessage',
          intl.formatMessage({ id: 'mycases.deleteApplicationNotification' })
        );
        setTimeout(() => {
          dispatchNotification('hideNotification');
        }, hideNotificationTime);
      }
    })
    .catch((errResponse) => {
      dispatchNotification('deleteCaseError', errResponse, 'app');
    });
  setShowCaseDel(false);
};

export const dispatchAction = (props) => {
  return (type, field, value) => {
    props?.appActions[type]({
      field,
      value,
    });
  };
};

const fetch = (setAppsData, appActions, dispatchNotification, dda2Toggled, tca2Toggled, isTca) => {
  let appFlag = (a) => (dda2Toggled ? a.appFlag === '2' : a.appFlag !== '2');
  if (isTca) {
    appFlag = (a) => (tca2Toggled ? a.appFlag === '2' : a.appFlag !== '2');
  }
  return () => {
    setIsRequesting(appActions, true);
    (isTca ? retrieveSavedAppsTca() : retrieveSavedApps())
      .then((response) => {
        // Sort "await_submit" status first and rest will follow desc by last updated date
        const cases = response.cases
          .filter((a) => appFlag(a))
          .sort((a) => (a.status === eAppStatus.AWAIT_SUBMIT ? -1 : 0));
        setAppsData(cases);
      })
      .then(() => setIsRequesting(appActions, false))
      .catch((errResponse) => {
        setIsRequesting(appActions, false);
        dispatchNotification('errorMessage', errResponse, 'app');
      });
  };
};

export const createNameDropdown = (selectAllLabel) => {
  return (dataList, field) => {
    const dropdownList = [{ label: selectAllLabel, value: 'All' }];
    const tempDdList = [];
    dataList?.forEach((item, index) => {
      const name = item[field] ? `${item[field]?.firstName} ${item[field]?.lastName}` : '';
      if (name !== '' && !tempDdList.some((d) => d.label === name)) {
        tempDdList.push({
          id: index,
          label: name,
          value: name,
        });
      }
    });
    const sortedList = tempDdList ? [...tempDdList].sort(commonSort) : [...tempDdList];
    dropdownList.push(...sortedList);
    return dropdownList;
  };
};

export const createDropdown = (selectAllLabel, intl) => {
  return (dataList, field) => {
    const dropdownList = [{ label: selectAllLabel, value: 'All' }];
    const tempDdList = [];
    dataList?.forEach((item, index) => {
      const value = field === 'status' ? intl.formatMessage({ id: getAppStatus(item[field]) }) : item[field];
      if (item[field] !== '' && !tempDdList.some((d) => d.value === item[field])) {
        tempDdList.push({
          id: index,
          label: value,
          value: item[field],
        });
      }
    });
    const sortedList = tempDdList ? [...tempDdList].sort(commonSort) : [...tempDdList];
    dropdownList.push(...sortedList);
    return dropdownList;
  };
};

const getApplicationHeaderInfo = (intl, query, setQuery, isTabletOrMobile, isTca, isFr) => {
  const applicationNameLabel = intl.formatMessage({
    id: isTca ? 'dashboard.table.new.applicationName' : 'dashboard.table.applicationName',
  });

  return {
    id: DASH_APPLICATION_ID,
    label: (
      <span>
        <div>{applicationNameLabel}</div>
        <div>
          <TextInput
            id={DASH_APPLICATION_SEARCH_ID}
            label=""
            value={query.appName}
            icon={<Cds.Search />}
            lang={LANG.EN_CA}
            placeholder={commonPlaceholder({ intl })}
            customStyle={{
              rootStyle: {
                margin: isTabletOrMobile || isFr ? frMargin : enMargin,
                width: '90%',
              },
            }}
            onChange={(val) => {
              setQuery({ ...query, appName: val });
            }}
          />
        </div>
      </span>
    ),
    width: '25%',
  };
};

const getOriginApplicationHeaderInfo = (intl, query, setQuery, isTabletOrMobile, isFr) => {
  const originApplicationNameLabel = intl.formatMessage({ id: 'dashboard.table.origin.applicationName' });

  return {
    id: DASH_ORIGIN_APPLICATION_ID,
    label: (
      <span>
        <div style={{ marginBottom: '30px' }}>{originApplicationNameLabel}</div>
        <div>
          <TextInput
            id={DASH_APPLICATION_SEARCH_ID}
            label=""
            value={query.originAppName}
            icon={<Cds.Search />}
            lang={LANG.EN_CA}
            placeholder={commonPlaceholder({ intl })}
            customStyle={{
              rootStyle: {
                margin: isTabletOrMobile || isFr ? frMargin : enMargin,
                width: '90%',
              },
            }}
            onChange={(val) => {
              setQuery({ ...query, originAppName: val });
            }}
          />
        </div>
      </span>
    ),
    width: '25%',
  };
};

function getProductAppsHeaderInfo(props, isTabletOrMobile, query, setQuery) {
  const { intl, selectedSubTab } = props;
  const isTca = selectedSubTab === DASH_APPLICATION_TCA_TAB_ID;
  const isFr = intl.locale === eLocale.fr;
  const productLabel = intl.formatMessage({ id: isTca ? 'dashboard.table.new.product' : 'dashboard.table.product' });
  const appProductLabelDefault = {
    label: intl.formatMessage({ id: 'dashboard.table.select.all' }),
    value: eProduct.ALL,
  };
  const appProductLabelFamilyTerm = { label: intl.formatMessage({ id: 'product.ft' }), value: eProduct.FT };
  const appProductLabelLifeCheque = { label: intl.formatMessage({ id: 'product.lc' }), value: eProduct.LC };
  const appProductLabelPar = { label: intl.formatMessage({ id: 'product.par' }), value: eProduct.PAR };
  const appProductLabelMul = { label: intl.formatMessage({ id: 'product.mul' }), value: eProduct.MUL };
  const appProductLabelGI = { label: intl.formatMessage({ id: 'product.gi' }), value: eProduct.GI };

  //isFeatureFlagSet added to prevent changes get published to prod ahead of GI launch date,
  //remove the isFeatureFlagSet condition at promoting GI to UAT
  const appProductLabelEN = [
    appProductLabelDefault,
    appProductLabelFamilyTerm,
    appProductLabelLifeCheque,
    appProductLabelPar,
    appProductLabelMul,
    ...(!isFeatureFlagSet() ? [appProductLabelGI] : []),
  ];

  const appProductLabelFR = [
    appProductLabelDefault,
    appProductLabelLifeCheque,
    appProductLabelFamilyTerm,
    appProductLabelPar,
    appProductLabelMul,
    ...(!isFeatureFlagSet() ? [appProductLabelGI] : []),
  ];

  const appProductLabel = isFr ? appProductLabelFR : appProductLabelEN;

  return {
    id: DASH_PRODUCT_APPS_ID,
    label: (
      <span>
        <div className={isTca && 'label-tca'}>{productLabel}</div>
        <div>
          <Dropdown
            customStyle={{
              rootStyle: {
                margin: isTabletOrMobile || isFr ? frMargin : enMargin,
                width: '90%',
              },
            }}
            dropdownItems={appProductLabel}
            id={DASH_PRODUCT_FILTER_APPS_ID}
            role="DASH_PRODUCT_FILTER_APPS_ID"
            label=""
            value={query.product}
            onChange={(val) => setQuery({ ...query, product: val })}
          />
        </div>
      </span>
    ),
    width: '17%',
  };
}

export const getApplicationInsuredHeaderInfo = (props, query, setQuery, isTabletOrMobile) => {
  const { intl, selectedSubTab } = props;
  const isTca = selectedSubTab === DASH_APPLICATION_TCA_TAB_ID;
  const isFr = intl.locale === eLocale.fr;
  const insuredNameLabel = intl.formatMessage({ id: 'dashboard.table.insuredName' });

  return {
    id: DASH_APPLICATION_INSURED_ID,
    label: (
      <span>
        <div className={isTca && (isFr ? 'label-insured-tca-fr' : 'label-insured-tca')}>{insuredNameLabel}</div>
        <div>
          <TextInput
            id={DASH_APPLICATION_INS_NAME_SEARCH_ID}
            label=""
            value={query.insuredName}
            icon={<Cds.Search />}
            lang={LANG.EN_CA}
            placeholder={commonPlaceholder({ intl })}
            customStyle={{
              rootStyle: {
                margin: isTabletOrMobile || isFr ? frMargin : enMargin,
                width: '90%',
              },
            }}
            onChange={(val) => {
              setQuery({ ...query, insuredName: val });
            }}
          />
        </div>
      </span>
    ),
    width: '25%',
  };
};

export const getStatusAppsHeaderInfo = (props, query, setQuery, { appsData, selectAllLabel }, isTabletOrMobile) => {
  const { intl, selectedSubTab } = props;
  const isTca = selectedSubTab === DASH_APPLICATION_TCA_TAB_ID;
  const isFr = intl.locale === eLocale.fr;
  const statusLabel = intl.formatMessage({ id: 'common.status' });
  const createDropdownList = createDropdown(selectAllLabel, intl);
  return {
    id: DASH_STATUS_APPS_ID,
    label: (
      <span>
        <div className={isTca && (isFr ? 'label-tca-fr' : 'label-tca')}>{statusLabel}</div>
        <div>
          <Dropdown
            customStyle={{
              rootStyle: {
                margin: isTabletOrMobile || isFr ? frMargin : enMargin,
                width: '90%',
              },
            }}
            dropdownItems={createDropdownList(appsData, 'status')}
            id={DASH_STATUS_FILTER_APPS_ID}
            role="DASH_STATUS_FILTER_APPS_ID"
            value={query.status}
            onChange={(val) => setQuery({ ...query, status: val })}
          />
        </div>
      </span>
    ),
    width: '25%',
  };
};

const createCreatedByDropdown = (props, selectAllLabel, canAssignCase, isTabletOrMobile, appsData, query, setQuery) => {
  const { intl, selectedSubTab } = props;
  const isTca = selectedSubTab === DASH_APPLICATION_TCA_TAB_ID;
  const isFr = intl.locale === eLocale.fr;
  const createNameDropdownList = createNameDropdown(selectAllLabel);
  const createdByLabel = intl.formatMessage({ id: 'dashboard.table.createdBy' });
  const advisorLabel = intl.formatMessage({ id: 'common.advisor' });
  let createdByForId = DASH_CREATED_BY_APPS_ID;
  let createdByForLabel = createdByLabel;
  let createdByForFilterId = DASH_CREATEDBY_FILTER_APPS_ID;
  let createdByForValue = query.createdBy;
  if (canAssignCase) {
    createdByForId = DASH_ADVISOR_APPS_ID;
    createdByForLabel = advisorLabel;
    createdByForFilterId = DASH_ADVISOR_FILTER_APPS_ID;
    createdByForValue = query.createdFor;
  }
  return {
    id: createdByForId,
    label: (
      <span>
        <div className={isTca && (isFr ? 'label-tca-fr' : 'label-tca')}>{createdByForLabel}</div>
        <div>
          <Dropdown
            customStyle={{
              rootStyle: {
                margin: isTabletOrMobile || isFr ? frMargin : enMargin,
                width: '90%',
              },
              dropdownInputStyle: {
                fontWeight: '300',
              },
            }}
            dropdownItems={createNameDropdownList(appsData, canAssignCase ? 'createdFor' : 'createdBy')}
            id={createdByForFilterId}
            role="createdByForFilterId"
            value={createdByForValue}
            onChange={(val) =>
              canAssignCase ? setQuery({ ...query, createdFor: val }) : setQuery({ ...query, createdBy: val })
            }
          />
        </div>
      </span>
    ),
    width: '17%',
    tdStyler: () => ({
      color: 'green',
    }),
  };
};

const getHeader = (props, appsData, query, setQuery, isTca, isFr, dda2Toggled) => {
  const { intl, canAssignCase } = props;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 770px)' });
  const lastUpdatedOnLabel = intl.formatMessage({ id: 'dashboard.table.lastUpdatedOn' });
  const selectAllLabel = intl.formatMessage({ id: 'dashboard.table.select.all' });

  const commonHeader = () => [
    getApplicationHeaderInfo(intl, query, setQuery, isTabletOrMobile, isTca, isFr),
    getProductAppsHeaderInfo(props, isTabletOrMobile, query, setQuery, dda2Toggled),
    getApplicationInsuredHeaderInfo(props, query, setQuery, isTabletOrMobile),
    getStatusAppsHeaderInfo(props, query, setQuery, { appsData, selectAllLabel }, isTabletOrMobile),
    createCreatedByDropdown(props, selectAllLabel, canAssignCase, isTabletOrMobile, appsData, query, setQuery),
    {
      id: DASH_LAST_UPDATED_ON_APPS_ID,
      label: <div className={isTca && 'label-updated-tca'}>{lastUpdatedOnLabel}</div>,
      title: lastUpdatedOnLabel,
      isSortable: true,
      width: '25%',
    },
  ];

  return isTca
    ? [
        {
          id: 'checkbox',
          label: '',
          width: isTabletOrMobile ? '9%' : '5%',
        },
        getOriginApplicationHeaderInfo(intl, query, setQuery, isTabletOrMobile, isFr),
        ...commonHeader(),
      ]
    : [
        {
          id: 'checkbox',
          label: '',
          width: isTabletOrMobile ? '9%' : '5%',
        },
        ...commonHeader(),
      ];
};

const renderButtons = (intl, isResetDisabled, isDeleteDisabled, setShowCaseDel, eventHandlers, isTca) => {
  const toolTipWidth = 190;
  const resetFiltersLabel = intl.formatMessage({ id: 'dashboard.table.resetFilters' });
  const deleteLabel = intl.formatMessage({ id: 'common.delete' });
  const helpMessage = intl.formatMessage({ id: 'dashboard.delete.app.tooltip' });
  const toggleId = isTca ? DASH_TCA2_TOGGLE_APPS_ID : DASH_DDA2_TOGGLE_APPS_ID;
  const toggleLabel = isTca ? 'TCA2 Flag' : 'DDA2 Flag';
  const { handleOnResetClick, handleOnDda2Toggle, handleOnTca2Toggle } = eventHandlers;
  return (
    <div style={{ display: 'flex', marginTop: '5px', justifyContent: 'flex-end', alignItems: 'center' }}>
      {/* don't remove */}
      {!isFeatureFlagSet() && (
        <div>
          <Toggle
            id={toggleId}
            label={toggleLabel}
            activeColor={brandingColors.m_green}
            size={23}
            defaultChecked={false}
            onChange={isTca ? handleOnTca2Toggle : handleOnDda2Toggle}
          />
        </div>
      )}
      <div>
        <Button
          className="list-button"
          id={DASH_RESET_FILTERS_APPS_ID}
          variant={BUTTON_VARIANT.TERTIARY}
          icon={
            <Cds.Reload color={isResetDisabled ? brandingColors.light_grey : brandingColors.m_green} />
          }
          disabled={isResetDisabled}
          onClick={handleOnResetClick}
        >
          {resetFiltersLabel}
        </Button>
      </div>
      <div>
        <Button
          id={DASH_DELETE_APPS_ID}
          variant={BUTTON_VARIANT.TERTIARY}
          icon={
            <Cds.Delete color={isDeleteDisabled ? brandingColors.light_grey : brandingColors.m_green} />
          }
          disabled={isDeleteDisabled}
          onClick={() => setShowCaseDel(true)}
        >
          {deleteLabel}
        </Button>
      </div>
      <div>
        <Tooltip
          bubbleWidth={toolTipWidth}
          content={helpMessage}
          id="tooltip-applications-delete"
          side="bottom-left"
          variant="secondary"
        />
      </div>
    </div>
  );
};

export const renderPagination = (intl, currentPage, tableRows, handlePageChange, isFr) => {
  return (
    <>
      <PaginationHelper
        rangeStart={PAGE_SIZE * (currentPage - 1) + 1}
        rangeEnd={Math.min(PAGE_SIZE * currentPage, tableRows.length)}
        maxRecords={tableRows.length}
        intl={intl}
      />
      <Pagination
        id={DASH_PAGINATION_APPS_ID}
        lang={isFr ? LANG.FR_CA : LANG.EN_CA}
        totalItems={Math.ceil(tableRows.length / PAGE_SIZE)}
        navigationAriaLabel="Page control"
        currentItemAriaLabel="Current Item"
        currentItem={currentPage}
        onChange={handlePageChange}
      />
    </>
  );
};

export const DashboardApplicationsTab = (props) => {
  const { intl, appActions, dda2Toggled, handleOnDda2Toggle, tca2Toggled, handleOnTca2Toggle, selectedSubTab } = props;
  const isTca = selectedSubTab === DASH_APPLICATION_TCA_TAB_ID;
  const isFr = intl.locale === eLocale.fr;
  const [isResetDisabled, setIsResetDisabled] = useState(true);
  const [appsData, setAppsData] = useState([]);
  const [checkedApps, setCheckedApps] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);
  const [showCaseDel, setShowCaseDel] = useState(false);
  let defaultQuery = {
    appName: '',
    insuredName: '',
    product: eProduct.ALL,
    createdBy: eProduct.ALL,
    status: eAppStatus.ALL,
    createdFor: eProduct.ALL,
  };
  if (isTca) {
    defaultQuery = { ...defaultQuery, originAppName: '' };
  }
  const [query, setQuery] = useState(defaultQuery);
  const emptyTableMessage = query.appName
    ? intl.formatMessage({ id: 'dashboard.table.nomatch.illustration' })
    : intl.formatMessage({ id: 'dashboard.table.no.application' });
  useEffect(() => {
    setIsResetDisabled(true);
  }, []);
  useEffect(() => {
    fetchData();
  }, [dda2Toggled, tca2Toggled]);
  useEffect(() => {
    setIsDeleteDisabled(checkedApps.length === 0);
  }, [checkedApps]);
  useEffect(() => {
    const isAllProducts =
      query.createdBy === eProduct.ALL && query.createdFor === eProduct.ALL && query.status === eAppStatus.ALL;
    if (isTca) {
      setIsResetDisabled(
        query.appName === '' && query.originAppName === '' && query.insuredName === '' && isAllProducts
      );
    } else {
      setIsResetDisabled(query.appName === '' && query.insuredName === '' && isAllProducts);
    }
  }, [query]);
  const dispatchNotification = dispatchAction(props);
  const fetchData = fetch(setAppsData, appActions, dispatchNotification, dda2Toggled, tca2Toggled, isTca);
  const handleOnResetClick = () => {
    if (isTca) {
      setQuery({ ...defaultQuery, originAppName: '' });
    } else {
      setQuery(defaultQuery);
    }
  };
  const handlePageChange = (selectedPageNum) => {
    setCurrentPage(selectedPageNum);
  };
  const tableRows = getTableRows(
    props,
    appsData,
    query,
    checkedApps,
    setCheckedApps,
    dda2Toggled,
    tca2Toggled,
    isTca,
    dispatchNotification
  );
  const chunkedRows = [];
  for (let i = 0; i < tableRows?.length; i += PAGE_SIZE) {
    chunkedRows.push(tableRows.slice(i, i + PAGE_SIZE));
  }
  const header = getHeader(props, appsData, query, setQuery, isTca, isFr, dda2Toggled);
  const formId = () => (isTca ? DASH_APPLICATION_TCA_TAB_ID : DASH_APPLICATION_TAB_ID);
  return (
    <>
      <FormContent>
        <div id={formId()} role="container-dashapplicationtab">
          {renderButtons(
            intl,
            isResetDisabled,
            isDeleteDisabled,
            setShowCaseDel,
            { handleOnResetClick, handleOnDda2Toggle, handleOnTca2Toggle },
            isTca
          )}
          <Table
            id={DASHBOARD_TABLE_APPS_ID}
            header={header}
            rows={chunkedRows[currentPage - 1]}
            emptyTableMessage={emptyTableMessage}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            marginTop: '15px',
          }}
        >
          {tableRows?.length > 0 && renderPagination(intl, currentPage, tableRows, handlePageChange, isFr)}
        </div>
        <DeleteModal
          title={intl.formatMessage({ id: 'mycases.deleteApplication' })}
          message={intl.formatMessage({ id: 'mycases.dialog.delete.applications' })}
          isModalOpen={showCaseDel}
          deleteMyCases={() =>
            deleteMyCases(
              appsData,
              checkedApps,
              { setAppsData, setCheckedApps, setShowCaseDel },
              dispatchNotification,
              intl,
              isTca
            )
          }
          modalClose={() => setShowCaseDel(false)}
          intl={intl}
        />
      </FormContent>
    </>
  );
};

DashboardApplicationsTab.propTypes = {
  intl: intlShape.isRequired,
  canAssignCase: PropTypes.bool,
  partyId: PropTypes.string,
  role: PropTypes.string,
  selectedSubTab: PropTypes.string,
  appActions: PropTypes.object,
  dda2Toggled: PropTypes.bool,
  handleOnDda2Toggle: PropTypes.func,
  tca2Toggled: PropTypes.bool,
  handleOnTca2Toggle: PropTypes.func,
};

export const mapStateToProps = ({ app }) => {
  return {
    locale: app.locale,
    currentGuid: app.guid,
    userID: app.userID,
    canAssignCase: app.canAssignCase,
    partyId: app.partyId,
    role: app.role,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DashboardApplicationsTab));
